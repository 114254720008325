import axios from 'config/axiosConfig';
import { toast } from 'react-toastify';
import {
  IBatchOrder,
  IBatchOrderResponse,
  IGetServiceTypesResponse,
  IOrder,
  IOrderResponse,
} from 'models/IOrder';
import { IOrderService } from 'models/IOrderService';

export class OrdersServiceClass implements IOrderService {
  async getById(id: string): Promise<IOrder | undefined> {
    const findOrder = (await axios.get(`/dashboard/orders/${id}/`)).data;

    if (findOrder) {
      return Promise.resolve(findOrder);
    }

    toast.error("There's no order with this id");
    return Promise.reject();
  }

  async getAll(
    status?: string,
    service_type__id?: string,
    page?: string,
  ): Promise<IOrderResponse> {
    const orders = (
      await axios.get(`/dashboard/orders/`, {
        params: {
          page,
          service_type__id,
          status__in: status,
        },
      })
    ).data;

    if (orders) {
      return Promise.resolve(orders);
    }

    toast.error('No orders found');
    return Promise.resolve(orders);
  }

  async getBatchById(id: string): Promise<IBatchOrder | undefined> {
    const findOrder = (await axios.get(`/dashboard/orders/batch/${id}/`)).data;

    if (findOrder) {
      return Promise.resolve(findOrder);
    }

    toast.error("There's no batch order with this id");
    return Promise.reject();
  }

  async getAllBatches(page?: string): Promise<IBatchOrderResponse> {
    const orders = (
      await axios.get(`/dashboard/orders/batch/`, {
        params: {
          page,
        },
      })
    ).data;

    if (orders) {
      return Promise.resolve(orders);
    }

    toast.error('No batch orders found');
    return Promise.resolve(orders);
  }

  async assignBounderToOrder(
    bounderId: string,
    orderId: string,
  ): Promise<void> {
    axios.post(`/dashboard/orders/${orderId}/assign_driver/`, {
      driver_id: bounderId,
    });

    return Promise.resolve();
  }

  async unassignBounderToOrder(
    bounderId: string,
    orderId: string,
  ): Promise<void> {
    axios.post(`/dashboard/orders/${orderId}/unassign_from_driver/`, {
      driver_id: bounderId,
    });

    return Promise.resolve();
  }

  async getServiceTypes(): Promise<IGetServiceTypesResponse> {
    const { data } = await axios.get<IGetServiceTypesResponse>(
      '/dashboard/settings/service_types/',
    );

    if (data?.results?.length > 0) {
      return data;
    }

    toast.error("There's no service type");
    return Promise.reject();
  }
}

export const factoryOrdersService = (): OrdersServiceClass =>
  new OrdersServiceClass();

export default factoryOrdersService();
