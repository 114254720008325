export const CustomOnClick = (() => {
  let clickTime = 0;
  const pos = { x: 0, y: 0 };

  return (onClick: () => void) => ({
    onMouseDown: ({
      nativeEvent: e,
    }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      clickTime = Date.now();
      pos.x = e.x;
      pos.y = e.y;
    },
    onMouseUp: ({
      nativeEvent: e,
    }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (
        Date.now() - clickTime < 200 &&
        pos.x === e.x &&
        pos.y === e.y &&
        e.button !== 2
      ) {
        onClick();
      }
    },
  });
})();
