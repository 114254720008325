import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const BounderProfileImage = styled.img<{ clickable: boolean }>`
  object-fit: cover;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`;

export const BounderDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;

  div.divisor {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.boundGreen};
    margin: 40px 0;
    opacity: 0.4;
  }

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 3rem;
    margin-bottom: 58px;

    div {
      display: flex;
      align-items: center;
    }

    a {
      color: ${({ theme }) => theme.grayMed};
      cursor: pointer;
      font-size: 40px;
      font-weight: 400;
    }

    svg {
      margin: 0 1.4rem;
      width: 32px;
      height: 32px;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 560px) {
    div.title {
      gap: 20px;
    }

    div.title .user-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;

      > svg {
        display: none;
      }
    }

    div.title a {
      font-size: 32px;
      align-self: flex-start;
    }

    div.title h1 {
      align-self: flex-start;
    }

    div.title .user-profile {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const BoundersCardDetails = styled.div`
  animation: slideToLeft 0.8s forwards;

  .title {
    font-size: 24px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.ITCFontMd};
    color: ${({ theme }) => theme.boundBlue};
    margin-bottom: 32px;
  }

  .status {
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.boundGreen};
    width: fit-content;
    color: ${({ theme }) => theme.boundWhite};
    border-radius: 15px;
    padding: 5px 18px;
    margin-bottom: 40px;
    margin-top: -15px;
    gap: 10px;

    & > p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25px;
      text-align: center;
      padding-top: ${isMacOs ? 4 : 0}px;
      font-family: ${({ theme }) => theme.ITCFontMd};
    }
  }

  .denied {
    background-color: ${({ theme }) => theme.grayDark};
  }

  .verify {
    background-color: ${({ theme }) => theme.boundRed};
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
  }

  .social-security {
    margin-bottom: 40px;
  }

  .img-container {
    display: flex;
    gap: 24px;
    margin-bottom: 10px;
  }

  .img-1 {
    display: block;
    width: auto;
    height: auto;
    max-width: 500px;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 32px;
    cursor: pointer;
  }

  @media screen and (max-width: 560px) {
    .grid {
      grid-template-columns: 1fr;
    }

    .img-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .img-1 {
      max-width: 250px;
    }
  }
`;

export const BoundersButtons = styled.div`
  display: flex;
  margin-bottom: 60px;
  gap: 40px;

  div {
    width: fit-content;
  }

  @media screen and (max-width: 880px) {
    flex-direction: column;
  }
`;

export const ModalImage = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.58);
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeOut 0.55s;

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: center;
    width: 85vw;
    height: 85vh;
    animation: fadeIn 0.55s;

    svg {
      color: ${({ theme }) => theme.boundWhite};
    }

    .close {
      cursor: pointer;
    }

    .download-close-btn {
      display: flex;
      position: absolute;
      top: -5.5%;
      right: 0;
      gap: 10px;
    }

    #img-container {
      display: inline-block;
      overflow: auto;
      cursor: grab;

      ::-webkit-scrollbar {
        background: #fff;
        width: 14px;
        height: 14px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        border-radius: 20px;
      }

      img {
        display: block;
        transition: transform 0.4s ease-in;
        transform: translate(50% 50%);
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .slider-zoom {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      gap: 25px;

      div {
        cursor: pointer;

        :hover {
          transition: all 0.4s ease-in-out;
          opacity: 0.7;
        }
      }

      svg {
        margin: 2px 20px;
      }
    }
  }
`;
