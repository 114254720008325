import styled, { css } from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const StyledSidebar = styled.aside<{ isSidebarClose?: boolean }>`
  width: 240px;
  min-width: 240px;
  height: 100vh;
  background-color: ${({ theme }) => theme.boundBlack};
  color: ${({ theme }) => theme.boundWhite};
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: ${({ isSidebarClose }) =>
    isSidebarClose ? 'center' : 'flex-start'};

  ${({ isSidebarClose }) =>
    isSidebarClose !== undefined &&
    css`
      animation: ${isSidebarClose
          ? 'handleCloseContainer'
          : 'handleOpenContainer'}
        0.5s forwards;
    `};

  header {
    display: flex;
    align-items: flex-end;
    align-self: center;
    margin-left: ${({ isSidebarClose }) => (isSidebarClose ? 0 : '-15px')};

    .hamburger {
      margin-top: ${({ isSidebarClose }) => (isSidebarClose ? `34px` : 0)};
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 4px;

      svg {
        color: ${({ theme }) => theme.boundWhite};
        font-size: 2.25rem;
        cursor: pointer;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .logo {
      width: ${({ isSidebarClose }) => (isSidebarClose ? 0 : 134)}px;
      max-height: 50px;
      height: 30px;
      margin-top: 30px;
      object-fit: contain;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
    width: 100%;
    padding: 0 12px;

    .open-submenu {
      animation: openSubMenu 0.6s forwards;
    }

    .subicons {
      padding: 0rem 4px;
      padding-left: 20px;
    }

    li {
      width: 100%;
      padding: 8px 0;

      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        transition: color 0.2s;
        padding: 0.9rem 4px;
        position: relative;

        .arrow {
          position: absolute;
          width: 18px;
          right: 10%;
          top: 25%;
          transition: all 0.3s ease-in-out;
        }

        .open {
          transform: rotate(180deg);
        }

        &:hover {
          color: ${({ theme }) => theme.boundGreen};

          svg * {
            stroke: ${({ theme }) => theme.boundGreen};
          }
        }

        span {
          padding-top: ${isMacOs ? 4 : 0}px;
          font-size: 2rem;
          font-weight: 300;
          margin-left: 8%;
          width: 140px;
          font-size: 2rem;
          ${({ isSidebarClose }) =>
            isSidebarClose !== undefined &&
            css`
              animation: ${isSidebarClose
                  ? 'handleCloseSpan'
                  : 'handleOpenSpan'}
                0.5s forwards;
            `};
        }

        svg {
          width: 26px;
          height: 26px;
          object-fit: contain;
          color: inherit;
        }
      }

      a.actived {
        color: ${({ theme }) => theme.boundGreen};
        background-color: ${({ theme }) => theme.grayDarkest};
        border-radius: 6px;

        svg * {
          stroke: ${({ theme }) => theme.boundGreen};
        }
      }
    }
  }

  @media (max-width: 880px) {
    width: 80px;
    min-width: 80px;
    animation: unset;

    ${({ isSidebarClose }) =>
      isSidebarClose
        ? css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            overflow-x: auto;
            z-index: 999;
          `
        : css`
            width: 80px;
            overflow-x: auto;
          `};

    header {
      margin-left: ${({ isSidebarClose }) => (isSidebarClose ? '-50px' : 0)};

      .logo {
        width: ${({ isSidebarClose }) => (isSidebarClose ? 134 : 0)}px;
      }
    }

    ul {
      .subicons {
        padding-left: 0;
      }

      li {
        .arrow {
          display: none;
        }

        a {
          span {
            animation: unset;
            font-size: ${({ isSidebarClose }) => (isSidebarClose ? 2 : 0)}rem;
            width: ${({ isSidebarClose }) => (isSidebarClose ? 140 : 0)}px;
          }
        }
      }
    }
  }

  /* -----ANIMATIONS----- */

  @keyframes handleOpenContainer {
    from {
      width: 80px;
      overflow-x: auto;
      min-width: 80px;
    }

    to {
      width: 240px;
      min-width: 240px;
      overflow-x: auto;
    }
  }

  @keyframes handleCloseContainer {
    from {
      width: 240px;
      overflow-x: auto;
      min-width: 80px;
    }

    to {
      width: 80px;
      min-width: 80px;
      overflow-x: auto;
    }
  }

  @keyframes handleOpenSpan {
    from {
      width: 0;
      font-size: 0;
    }

    to {
      width: 140px;
      font-size: 2rem;
    }
  }

  @keyframes handleCloseSpan {
    from {
      width: 140px;
      font-size: 2rem;
    }

    to {
      width: 0;
      font-size: 0;
    }
  }

  @keyframes openSubMenu {
    from {
      opacity: 0;
      top: -300px;
    }

    to {
      opacity: 1;
      top: auto;
    }
  }
`;
