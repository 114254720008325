import React from 'react';

import { ButtonContainer } from 'styles/components/button';

interface ButtonInterface
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  typeButton: 'primary' | 'secondary' | 'outline' | 'danger';
  text: string;
  buttonSize?: 'sm' | 'md' | 'bg';
  fontSize?: number;
}

export const Button: React.FC<ButtonInterface> = ({
  text,
  typeButton,
  buttonSize,
  fontSize,
  children,
  ...rest
}) => {
  return (
    <ButtonContainer
      typeButton={typeButton}
      buttonSize={buttonSize}
      fontSize={fontSize}
    >
      <button {...rest} type={typeButton === 'primary' ? 'submit' : 'button'}>
        {text}
        {children}
      </button>
    </ButtonContainer>
  );
};
