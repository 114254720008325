import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const CustomerContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.boundWhite};
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgb(0, 0, 0, 0.04),
    0px 0px 2px 0px rgb(0, 0, 0, 0.06);
  animation: slideIn 0.55s;
  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 2px 5px 10px #ccc;
  }

  @media screen and (max-width: 560px) {
    max-width: 350px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  div.title-customer-container {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 18px;

    > h1 {
      color: ${({ theme }) => theme.boundBlack};
      font-size: 24px;
      font-weight: 500;
      padding-top: ${isMacOs ? 5 : 0}px;
    }
  }

  div.buttons-container {
    display: flex;
    gap: 16px;

    button {
      height: 40px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 880px) {
    div.buttons-container {
      button {
        width: 100px;
      }
    }
  }

  @media screen and (max-width: 1160px) {
    div.title-customer-container {
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 18px;
      margin-bottom: 20px;

      > h1 {
        color: ${({ theme }) => theme.boundBlack};
        font-weight: 500;
        padding-top: ${isMacOs ? 5 : 0}px;
      }
    }
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;

    div.title-customer-container {
      > h1 {
        font-size: 20px;
      }
    }
  }
`;

export const InfoCustomers = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1060px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const StatusBar = styled.span<{
  type: 'pending' | 'denied' | 'approved';
}>`
  padding: ${isMacOs ? '4px 16px 4px' : '8px 16px'};
  border-radius: 125px;
  color: ${({ theme }) => theme.boundWhite};
  background-color: ${({ type, theme }) => {
    switch (type) {
      case 'denied':
        return theme.boundRed;

      case 'approved':
        return theme.boundBlue;

      case 'pending':
        return theme.boundGreen;

      default:
        return '';
    }
  }};

  & > p {
    font-weight: 400;
    font-size: 14px;
    padding-top: ${isMacOs ? 4 : 0}px;
  }
`;
