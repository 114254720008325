import React, { useState, useCallback } from 'react';
import { Button, Input } from 'components';
import { checkIfValidEmail } from 'helpers';

import { LoginWrapper, TitleBound } from 'styles/pages/login';
import Logo from 'assets/Logo.svg';
import { observer } from 'mobx-react';
import { AuthService } from 'services';
import { AuthStorage } from 'storage';

export const Login: React.FC = observer(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [errorEmail, setErrorEmail] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');

  const handleLoginService = useCallback(() => {
    if (!checkIfValidEmail(email)) {
      setErrorEmail('Invalid Email');
    }

    if (!password.length || password.length < 6) {
      setErrorPassword('Password must have at least 6 digits');
      return;
    }

    setLoading(true);

    AuthService.signIn(email, password)
      .then(({ user, tokens }) => {
        setLoading(false);
        AuthStorage.setUser(user);
        AuthStorage.setTokens(tokens);
      })
      .catch(() => {
        setLoading(false);
        setErrorEmail('Email/Password not matched');
        setErrorPassword('Email/Password not matched');
      });
  }, [email, password]);

  return (
    <LoginWrapper>
      <TitleBound>
        <img src={Logo} alt="bound" />
      </TitleBound>

      <div className="main-inputs">
        <form
          onSubmit={event => {
            event.preventDefault();
            handleLoginService();
          }}
        >
          <Input
            type="email"
            errorText={errorEmail}
            onClick={() => setErrorEmail('')}
            placeholder="Email address"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />

          <Input
            type="password"
            errorText={errorPassword}
            onClick={() => setErrorPassword('')}
            placeholder="Password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />

          <Button
            disabled={loading}
            buttonSize="bg"
            text={loading ? '...' : 'Log in'}
            typeButton="primary"
          />
        </form>
      </div>
    </LoginWrapper>
  );
});
