import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
// });

const api = axios.create({
  // baseURL: 'https://bound-dev.herokuapp.com',
  // baseURL: 'https://api.stg.bounddelivery.com', // Staging
  // baseURL: 'https://api.bounddelivery.com/', // Prod
  baseURL: process.env.REACT_APP_API_URL, // Prod
  // adapter: cache.adapter,
});

api.interceptors.request.use(async config => {
  const tokensFromStorage = JSON.parse(
    localStorage.getItem('@Bound-Tokens') as string,
  );
  const token = `Bearer ${tokensFromStorage.tokens.access}`;

  if (token && config.headers) {
    config.headers.Authorization = token; // eslint-disable-line no-param-reassign
  }

  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('@Bound-Tokens');
      localStorage.removeItem('@Bound-User');
      window.location.href = '/';
    }

    return error;
  },
);

export default api;
