import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const FiltersContainer = styled.div<{
  isOpen: boolean;
}>`
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  margin-bottom: 18px;
  z-index: 2;
  padding-right: 20px;

  button {
    height: 32px;
    font-size: 16px;
    letter-spacing: 0.5px;
    float: right;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, isOpen }) =>
      isOpen ? theme.boundGreen : theme.boundWhite};
    color: ${({ theme, isOpen }) =>
      isOpen ? theme.boundWhite : theme.boundGreen};
    transition: all 0.4s ease;
  }

  .filter-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    animation: openModal 0.6s forwards;
    gap: 15px;
    background-color: ${({ theme }) => theme.boundWhite};
    border-radius: 4px;
    width: 156px;
    border: 1px solid ${({ theme }) => theme.grayMed};
    right: 0;
    top: 120%;
    padding: 20px 16px;

    .checkbox {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      cursor: pointer;
      gap: 8px;

      div {
        svg {
          width: 15px;
          height: 15px;
        }
      }

      span {
        font-size: 1.4rem;
        font-weight: 400;
        padding-top: ${isMacOs ? 4 : 0}px;
        letter-spacing: 0.25px;
      }
    }

    @keyframes openModal {
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`;
