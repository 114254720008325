import { IOrderStatus, IOrderAndBatchOrderStatusColors } from 'models/IOrder';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');
const borderRaidius = 5;

export const StyledOrder = styled.div<{
  status: IOrderStatus;
  isOpen: boolean;
  isHover: boolean;
  index: number;
}>`
  margin: 1rem 0 4rem;
  opacity: 1;
  cursor: pointer;
  transform: ${({ isHover }) => (isHover ? `scale(1.02)` : 'none')};
  z-index: 3;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.05);

  .status span {
    padding-top: ${isMacOs ? 2 : 0}px;
  }

  header {
    background-color: ${({ theme, status }) =>
      theme[IOrderAndBatchOrderStatusColors[status]]};
    padding: 1% 2%;
    color: ${({ theme }) => theme.boundWhite};
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: ${borderRaidius}px;
    border-top-right-radius: ${borderRaidius}px;

    span.id,
    span.names,
    span.date,
    span.price,
    span.batchId,
    div.status {
      padding-top: ${isMacOs ? 6 : 0}px;
    }

    span.id {
      font-size: 2.2rem;
    }

    span.names {
      font-size: 1.7rem;
      font-weight: 900;
      margin-right: 2.4%;
    }

    span.date {
      font-size: 1.55rem;
      font-weight: 400;
      margin: 0 2.4%;
    }

    span.price {
      font-size: 1.55rem;
      font-weight: 400;
    }

    span.batchId {
      margin: 0 2.4%;
      font-size: 1.55rem;
      font-weight: 400;
    }

    div.status {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      svg {
        width: 18px;
        height: 18px;
      }

      span {
        margin-left: 0.7rem;
        font-size: 1.55rem;
        font-weight: 300;
      }
    }
  }

  .content {
    padding: 2%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    width: 100%;
    border: 0;
    outline: 0;
    background: ${({ theme }) => theme.boundWhite};
    border-bottom-left-radius: ${borderRaidius}px;
    border-bottom-right-radius: ${borderRaidius}px;

    animation: ${({ isOpen }) => (isOpen ? 'openContent' : 'closeContent')} 0.5s
      forwards;

    > div {
      margin-bottom: 4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @keyframes openContent {
      from {
        opacity: 0;
        height: 0;
        padding: 0 2%;
        overflow-y: hidden;
      }
      to {
        height: 220px;
        overflow-y: hidden;
        padding: 2%;
      }
    }

    @keyframes closeContent {
      from {
        height: 220px;
        padding: 2%;
        overflow-y: hidden;
      }
      to {
        opacity: 0;
        height: 0;
        overflow-y: hidden;
        padding: 0 2%;
      }
    }
  }

  &,
  * {
    transition: 0.25s;
  }

  @media (max-width: 880px) {
    header {
      span.date,
      span.price {
        display: none;
      }
    }
  }

  @media (max-width: 680px) {
    .content {
      padding: 2%;
      flex-direction: column;
      animation: none;
      display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};

      > * {
        width: 100%;
      }
    }
  }
`;

export const StyledChevron = styled(FiChevronDown)<{ open: boolean }>`
  font-size: 2rem;
  margin-left: 1rem;

  animation: ${({ open }) => `rotate${open ? 'Down' : 'Up'}`} 0.25s forwards;

  @keyframes rotateUp {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(0);
    }
  }

  @keyframes rotateDown {
    from {
      transform: rotateZ(0);
    }
    to {
      transform: rotateZ(-180deg);
    }
  }
`;
