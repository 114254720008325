import styled from 'styled-components';

export const StyledInfo = styled.div`
  h2 {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.grayDark};
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.grayDarkest};
  }

  @media screen and (max-width: 560px) {
    span {
      font-size: 14px;
    }
  }
`;

export const StyledDeliveryInfo = styled.div`
  margin-bottom: 4.4rem;

  h2 {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.grayDark};
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.grayDarkest};
  }

  @media screen and (max-width: 560px) {
    span {
      font-size: 14px;
    }
  }
`;
