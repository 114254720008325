import React, { useState, useCallback, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { MagnifyingGlassIMG } from 'assets';
import { AiOutlineClose } from 'react-icons/ai';

import { SearchInputContainer } from 'styles/components/searchInput';

interface ISearch {
  onFilterAction: (queryString: string) => void;
  onClearFilterAction: () => void;
}

export const SearchInput: React.FC<ISearch> = ({
  onFilterAction,
  onClearFilterAction,
}) => {
  const [textValue, setTextValue] = useState<string>('');
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);

  const handleFilterData = useCallback(() => {
    onFilterAction(textValue);
  }, [textValue, onFilterAction]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <SearchInputContainer isOnFocus={isOnFocus} data-testid="search-input">
      <MagnifyingGlassIMG
        onClick={handleFilterData}
        data-tip="Search"
        data-place="bottom"
      />

      <input
        type="text"
        placeholder="Search"
        value={textValue}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            handleFilterData();
          }
        }}
        onChange={event => setTextValue(event.target.value)}
        onFocus={() => setIsOnFocus(true)}
        onBlur={() => setIsOnFocus(false)}
      />

      {!!textValue.length && (
        <AiOutlineClose
          size={25}
          onClick={() => {
            setTextValue('');
            onClearFilterAction();
          }}
        />
      )}
    </SearchInputContainer>
  );
};
