import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'components';

import { ResetPasswordContainer } from 'styles/pages/ResetPassword';
import { DefaultWrapper, SubTitle, Title } from 'styles/components/defaut';

export const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [formSend, setFormSend] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');

  const [passwordError, setPasswordError] = useState<string>('');
  const [repeatNewPasswordError, setRepeatNewPasswordError] =
    useState<string>('');

  const handleChangePassword = useCallback(() => {
    if (!password.length || password.length < 6) {
      setPasswordError('Password must have at least 6 digits');
      return;
    }

    if (password !== repeatNewPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    setFormSend(true);
  }, [password, repeatNewPassword]);

  const returnToLoginPage = useCallback(() => {
    history.push('/login');
  }, [history]);

  return (
    <DefaultWrapper>
      {!formSend ? (
        <ResetPasswordContainer>
          <Title className="title-update">Update your password</Title>

          <Input
            errorText={passwordError}
            onClick={() => setPasswordError('')}
            type="password"
            placeholder="New password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />

          <Input
            errorText={repeatNewPasswordError}
            onClick={() => setRepeatNewPasswordError('')}
            type="password"
            placeholder="Confirm new password"
            value={repeatNewPassword}
            onChange={event => setRepeatNewPassword(event.target.value)}
          />

          <Button
            className="button-update"
            buttonSize="bg"
            text="Update password"
            typeButton="primary"
            onClick={handleChangePassword}
          />
        </ResetPasswordContainer>
      ) : (
        <ResetPasswordContainer>
          <Title className="title-confirm">All done!</Title>
          <SubTitle className="subtitle-confirm">
            Your password has been reset!
          </SubTitle>

          <Button
            className="button"
            buttonSize="bg"
            text="Return to log in"
            typeButton="primary"
            onClick={returnToLoginPage}
          />
        </ResetPasswordContainer>
      )}
    </DefaultWrapper>
  );
};
