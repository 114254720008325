import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Layout, Loading, Modal } from 'components';
import { Promocode } from 'components/Promocode';
import { scrollToTop } from 'helpers';
import { PromocodeService } from 'services';
import {
  IPromocode,
  IPromocodeResponse,
  IPromocodeStatusEnum,
} from 'models/IPromocode';
import { PageTitle } from 'styles/components/pageTitle';
import {
  StyledPromocode,
  FiltersContainer,
  EmptyMessage,
} from 'styles/pages/promocodes';
import { PromocodeModal } from 'components/PromocodeModal';
import { checkIsPromocodevalid } from 'helpers/checkIsPromocodevalid';

export const Promocodes: React.FC = observer(() => {
  const [promocodes, setPromocodes] = useState<IPromocode[]>();
  const [filteredPromocodes, setFilteredPromocodes] = useState<IPromocode[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page] = useState('1');
  const [editPromocodeId, setEditPromocodeId] = useState(-1);
  const [editPromocode, setEditPromocode] = useState<IPromocode>();
  const [showModal, setShowModal] = useState(false);
  const [showPromocodeModal, setShowPromocodeModal] = useState(false);
  const [promocodeModalTitle, setPromocodeModalTitle] = useState('');
  const [filterType, setFilterType] = useState<IPromocodeStatusEnum>(
    IPromocodeStatusEnum.ALL,
  );
  const [promocodeStatus, setPromocodeStatus] = useState<{
    valid: number;
    expired: number;
  }>({ valid: 0, expired: 0 });

  const filterTabs = Object.values(IPromocodeStatusEnum);

  function getCounterFilter(filterValue: string) {
    let counter;
    console.log(filterValue);

    switch (filterValue) {
      case 'ALL':
        counter = promocodeStatus.valid + promocodeStatus.expired;
        break;

      case 'VALID':
        counter = promocodeStatus.valid;
        break;

      default:
        counter = promocodeStatus.expired;
    }

    return counter;
  }

  function FilterOption({
    title,
    filterValue,
  }: {
    title: string;
    filterValue: IPromocodeStatusEnum;
  }) {
    const counter = getCounterFilter(filterValue);

    return (
      <span
        className={filterType === filterValue ? 'selected' : ''}
        onClick={() => {
          setFilterType(filterValue);
          scrollToTop('scroll-element');
        }}
      >
        {title} ({counter})
      </span>
    );
  }

  const getPromocodesStatus = (promocodes: IPromocodeResponse) => {
    const status = {
      valid: 0,
      expired: 0,
    };
    promocodes.results.forEach(promocode => {
      const valid = checkIsPromocodevalid(promocode.expiration_datetime);
      if (valid) {
        status.valid += 1;
      } else {
        status.expired += 1;
      }
    });
    setPromocodeStatus(prevStatus => ({
      ...prevStatus,
      valid: status.valid,
      expired: status.expired,
    }));
  };

  useEffect(() => {
    if (filterType === IPromocodeStatusEnum.ALL) {
      setFilteredPromocodes(promocodes);
    } else if (filterType === IPromocodeStatusEnum.VALID) {
      setFilteredPromocodes(
        promocodes?.filter(promo =>
          checkIsPromocodevalid(promo.expiration_datetime),
        ),
      );
    } else {
      setFilteredPromocodes(
        promocodes?.filter(
          promo => !checkIsPromocodevalid(promo.expiration_datetime),
        ),
      );
    }
  }, [filterType, promocodes]);

  const getPromocodesService = useCallback(
    (_page = page) => {
      setLoading(true);
      console.log(_page);
      PromocodeService.getAll(_page)
        .then((promocodes: IPromocodeResponse) => {
          getPromocodesStatus(promocodes);
          setPromocodes(promocodes.results);
          setFilteredPromocodes(promocodes.results);
        })
        .catch(() => {
          setPromocodes(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page],
  );

  useEffect(() => {
    getPromocodesService(undefined);
  }, [getPromocodesService]);

  const handleCreatePromocode = () => {
    setEditPromocodeId(-1);
    setEditPromocode(undefined);
    setPromocodeModalTitle('Create Promocode');
    setShowPromocodeModal(true);
  };

  const handleEdit = (id: number) => {
    if (promocodes && promocodes.length) {
      const promo = promocodes.filter(promocode => {
        return promocode.id === id;
      });

      setEditPromocode(promo[0]);
      setShowPromocodeModal(true);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    PromocodeService.deletePromocode(editPromocodeId)
      .then()
      .catch(() => {
        console.log('error on deleting');
      })
      .finally(() => {
        getPromocodesService(undefined);
        setLoading(false);
        setShowModal(false);
      });
  };

  return (
    <>
      {showModal && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          modalTitle="Are you sure?"
          size="sm"
          modalBody="This promocode will be deleted."
          onCancel={() => setShowModal(false)}
          onConfirm={handleDelete}
        />
      )}
      {showPromocodeModal && (
        <PromocodeModal
          confirmButtonText="Save"
          cancelButtonText="Cancel"
          modalTitle={promocodeModalTitle}
          size="sm"
          editPromocode={editPromocode}
          onCancel={() => setShowPromocodeModal(false)}
          onGetPromocodes={() => getPromocodesService(undefined)}
        />
      )}

      <Layout>
        <StyledPromocode>
          <header>
            <PageTitle>Promocodes</PageTitle>
          </header>

          <FiltersContainer>
            <div className="tabs">
              {filterTabs.map(tab => (
                <FilterOption title={tab} filterValue={tab} key={tab} />
              ))}
            </div>
            <div className="button">
              <Button
                buttonSize="sm"
                text="Create new"
                typeButton="outline"
                onClick={handleCreatePromocode}
              />
            </div>
          </FiltersContainer>

          <div className="promocode-grid ">
            {loading && !promocodes ? <Loading size="85" /> : null}

            {filteredPromocodes && filteredPromocodes.length
              ? filteredPromocodes.map(promocode => (
                  <Promocode
                    data-testid="promocode"
                    key={promocode.id}
                    {...promocode}
                    onDeleteButton={() => {
                      setEditPromocodeId(promocode.id);
                      setShowModal(true);
                    }}
                    onEditButton={() => {
                      setEditPromocodeId(promocode.id);
                      handleEdit(promocode.id);
                    }}
                  />
                ))
              : null}

            {!filteredPromocodes?.length && !loading && (
              <EmptyMessage>No promocodes found</EmptyMessage>
            )}
          </div>

          {/* {applicants && applicants?.results.length ? (
          <Pagination
            totalElements={applicants.count}
            elementsPerPage={20}
            page={Number(page) || 1}
            onChangePage={p => {
              setPage(p);
              getApplicantsService(search, p);
            }}
          />
        ) : null} */}
        </StyledPromocode>
      </Layout>
    </>
  );
});
