import axios from 'config/axiosConfig';
import {
  IBounder,
  IBounderService,
  IBounderResponse,
  IGetAllBoundersReq,
} from 'models';

export class BoundersService implements IBounderService {
  async getAll(params?: IGetAllBoundersReq): Promise<IBounderResponse> {
    try {
      const bounders = (
        await axios.get(`/dashboard/drivers/`, {
          params,
        })
      ).data;

      return bounders || Promise.reject(new Error('Bounders not found'));
    } catch (err) {
      console.error(err);
      return Promise.reject();
    }
  }

  async getAllOfflineBounders(): Promise<IBounderResponse> {
    const bounder: IBounderResponse = (
      await axios.get('/dashboard/drivers/online')
    ).data;

    return bounder
      ? Promise.resolve(bounder)
      : Promise.reject(new Error('Bounders not found'));
  }

  async getBounderById(id: number): Promise<IBounder> {
    const bounder: IBounder | undefined = (
      await axios.get(`/dashboard/drivers/${id}/`)
    ).data;

    return bounder
      ? Promise.resolve(bounder)
      : Promise.reject(new Error('Bounder not found'));
  }
}

export default new BoundersService();
