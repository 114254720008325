import { IoFlowerOutline } from 'react-icons/io5';
import { AiOutlineGift } from 'react-icons/ai';
import { FiBox, FiHelpCircle } from 'react-icons/fi';

import {
  CheckIconIMG,
  CircleIconIMG,
  HourglassIconIMG,
  PathIconIMG,
  SpinnerIconIMG,
} from 'assets';

export interface IGetServiceTypesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IServiceType[];
}

export enum IServiceTypeEnum {
  REGULAR = 'Regular',
  RUSH = 'Rush',
  NEED = 'Need it Now',
}

export type IServiceTypeValues = `${IServiceTypeEnum}`;

export type IOrderServiceType = keyof typeof IServiceTypeEnum;

export type IServiceType = {
  id: string;
  name: IOrderServiceType;
};

export enum IOrderStatusEnum {
  READY_FOR_PICKUP = 'Ready',
  SCHEDULED = 'Scheduled',
  ACCEPTED_BY_DRIVER = 'Accepted by driver',
  ASSIGNED_BY_ADMIN = 'Assigned by admin',
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound',
  CANCELLED = 'Canceled',
  COMPLETED = 'Completed',
  UNASSIGNED = 'Unassigned',
}

export type IOrderStatus = keyof typeof IOrderStatusEnum;

export enum IBatchOrderStatusEnum {
  ESTIMATING_PRICE = 'Estimating price',
  PENDING_CONFIRMATION = 'Pending confirmation',
  PARSING_FAILED = 'Parsing failed',
  PRICE_ESTIMATION_FAILED = 'Price estimation failed',
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
}

export type IBatchOrderStatus = keyof typeof IBatchOrderStatusEnum;

export interface IOrder {
  id: number;
  reference: string;
  num_items: number;
  created_at: string;
  pickup_time: string;
  driver_id: number | null;
  driver: {
    id: number | null;
    first_name: string;
    last_name: string;
  };
  service_type: IServiceTypeValues;
  display_type: IDisplayTypeText;
  price: string;
  recipient: {
    name: string;
    email: string;
    phone_number: string;
  };
  drop_off: {
    address: string;
    address_2: string;
    address_type: string;
  };
  dropoff_method: string;
  dropoff_person_name: string;
  pick_up: {
    address: string;
    address_2: string;
    address_type: string;
  };
  status: IOrderStatus;
  sender: {
    name: string;
    email: string;
    phone_number: string;
  };
  item_types: Array<string>;
  customer: {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    phone_number: string;
  };
  notes: string;
  pickup_photos: string[];
  dropoff_photos: string[];
  batch_id?: string;
}

export interface IBatchOrder {
  id: number;
  created_at: string;
  name: string;
  status: IBatchOrderStatus;
  price: string;
  customer: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    company_name: string;
  };
  orders_count: number;
  orders: Array<IOrder>;
}

export interface IOrderResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IOrder[];
}

export interface IBatchOrderResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IBatchOrder[];
}

export enum IOrderStatusText {
  CANCELLED = 'Canceled',
  COMPLETED = 'Completed',
  OUTBOUND = 'Outbound',
  INBOUND = 'Inbound',
  READY_FOR_PICKUP = 'Ready',
  SCHEDULED = 'Scheduled',
  ACCEPTED_BY_DRIVER = 'Accepted by driver',
  ASSIGNED_BY_ADMIN = 'Assigned by admin',
  UNASSIGNED = 'Unassigned',
}

export enum IBatchOrderStatusText {
  ESTIMATING_PRICE = 'Estimating price',
  PENDING_CONFIRMATION = 'Pending confirmation',
  PARSING_FAILED = 'Parsing failed',
  PRICE_ESTIMATION_FAILED = 'Price estimation failed',
  CONFIRMED = 'Confirmed',
}

export const IOrderAndBatchOrderStatusText = {
  ...IOrderStatusText,
  ...IBatchOrderStatusText,
};

export type IOrderAndBatchOrderStatus =
  keyof typeof IOrderAndBatchOrderStatusText;

export enum IOrderServiceTypeText {
  Regular = 'Regular - Same-day',
  Rush = 'Rush - Nonstop',
  'Need it Now' = 'Need it Now',
}

export enum IOrderStatusColors {
  CANCELLED = 'boundBlue',
  READY_FOR_PICKUP = 'boundGreen',
  OUTBOUND = 'boundBlue',
  INBOUND = 'boundBlue',
  SCHEDULED = 'boundBlue',
  COMPLETED = 'boundBlue',
  ACCEPTED_BY_DRIVER = 'boundBlue',
  ASSIGNED_BY_ADMIN = 'boundBlue',
  UNASSIGNED = 'boundBlue',
}

export enum IBatchOrderStatusColors {
  ESTIMATING_PRICE = 'boundBlue',
  PENDING_CONFIRMATION = 'boundBlue',
  PARSING_FAILED = 'boundBlue',
  PRICE_ESTIMATION_FAILED = 'boundBlue',
  CONFIRMED = 'boundBlue',
  CANCELLED = 'boundBlue',
}

export const IOrderAndBatchOrderStatusColors = {
  ...IOrderStatusColors,
  ...IBatchOrderStatusColors,
};

export enum IDisplayTypeText {
  flowers = 'flowers',
  gifts = 'gifts',
  parcel = 'parcel',
  other = 'other',
}

export const IDisplayTypeIcons = {
  flowers: <IoFlowerOutline />,
  gifts: <AiOutlineGift />,
  parcel: <FiBox />,
  other: <FiHelpCircle />,
};

export const IOrderStatusIcons = {
  READY_FOR_PICKUP: <HourglassIconIMG />,
  OUTBOUND: <SpinnerIconIMG />,
  INBOUND: <SpinnerIconIMG />,
  COMPLETED: <CheckIconIMG />,
  CANCELLED: <CircleIconIMG />,
  SCHEDULED: <PathIconIMG />,
  ACCEPTED_BY_DRIVER: <SpinnerIconIMG />,
  ASSIGNED_BY_ADMIN: <SpinnerIconIMG />,
  UNASSIGNED: <SpinnerIconIMG />,
};

export const IBatchOrderStatusIcons = {
  ESTIMATING_PRICE: <SpinnerIconIMG />,
  PENDING_CONFIRMATION: <SpinnerIconIMG />,
  PARSING_FAILED: <CircleIconIMG />,
  PRICE_ESTIMATION_FAILED: <CircleIconIMG />,
  CONFIRMED: <CheckIconIMG />,
};
