import styled from 'styled-components';

interface ILoading {
  size: string;
}

export const LoadingContainer = styled.div<ILoading>`
  align-self: center;
  border: 5px solid ${({ theme }) => theme.grayLight};
  border-top: 5px solid ${({ theme }) => theme.boundGreen};
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
