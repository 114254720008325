import React from 'react';
import { format } from 'date-fns';
import { ICustomers } from 'models';
import { Button } from 'components';

import { StyledInfo } from 'styles/components/info';
import {
  CustomerContainer,
  InfoCustomers,
  TitleContainer,
  StatusBar,
} from 'styles/components/clients';
import { getDateWithoutTzOffset } from 'helpers/convertDateWithoutTimezoneOffset';

interface ICustomersComponent extends ICustomers {
  onDenyButton: () => void;
  onApproveButton: () => void;
}

export const Clients: React.FC<ICustomersComponent> = ({
  date_of_birth,
  email,
  first_name,
  status,
  last_name,
  phone_number,
  usage,
  company_name,
  onApproveButton,
  onDenyButton,
}) => {
  const returnStatusField = () => {
    switch (status) {
      case 'PENDING_APPROVAL':
        return (
          <StatusBar type="pending">
            <p>Pending</p>
          </StatusBar>
        );

      case 'DECLINED':
        return (
          <StatusBar type="denied">
            <p>Denied</p>
          </StatusBar>
        );

      default:
        return (
          <StatusBar type="approved">
            <p>Approved</p>
          </StatusBar>
        );
    }
  };

  return (
    <CustomerContainer>
      <TitleContainer>
        <div className="title-customer-container">
          <h1>
            {first_name} {last_name}
          </h1>

          {returnStatusField()}
        </div>

        <div className="buttons-container">
          {status === 'PENDING_APPROVAL' && (
            <>
              <Button
                buttonSize="sm"
                text="Approve"
                typeButton="primary"
                onClick={onApproveButton}
              />

              <Button
                buttonSize="sm"
                text="Deny"
                typeButton="secondary"
                onClick={onDenyButton}
              />
            </>
          )}

          {status === 'DECLINED' && (
            <>
              <Button
                buttonSize="sm"
                text="Review"
                typeButton="secondary"
                onClick={onApproveButton}
              />
            </>
          )}
        </div>
      </TitleContainer>

      <InfoCustomers>
        <StyledInfo>
          <h2>email</h2>
          <span>{email}</span>
        </StyledInfo>

        <StyledInfo>
          <h2>phone number</h2>
          <span>{phone_number}</span>
        </StyledInfo>

        <StyledInfo>
          <h2>birthday</h2>
          <span>
            {date_of_birth &&
              format(getDateWithoutTzOffset(date_of_birth), 'MM/dd/yyyy')}
          </span>
        </StyledInfo>

        <StyledInfo>
          <h2>company name</h2>
          <span>{company_name}</span>
        </StyledInfo>

        <StyledInfo>
          <h2>usage</h2>
          <span>{usage}</span>
        </StyledInfo>
      </InfoCustomers>
    </CustomerContainer>
  );
};
