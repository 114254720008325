import { makeAutoObservable } from 'mobx';
import { INotification } from 'models/INotification';

export class NotificationsStorageClass {
  notifications: INotification[] = [];

  next = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateNotifications = (data: INotification[]): void => {
    this.notifications = data;
  };

  refreshNotifications = (notification: INotification): void => {
    const notifications = this.notifications.map(notif => ({
      ...notif,
      read_at:
        notif.id === notification.id ? Date.now().toString() : notif.read_at,
    }));

    this.setNotifications(notifications);
  };

  setNotifications = (notifications: INotification[]): void => {
    this.notifications = notifications;
  };
}

export const factoryNotificationsStorage = (): NotificationsStorageClass =>
  new NotificationsStorageClass();

export default factoryNotificationsStorage();
