import { getDateWithoutTzOffset } from './convertDateWithoutTimezoneOffset';

function checkIfValidEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function phoneMask(phone: string): string {
  const str = `${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(
    5,
    8,
  )} ${phone.slice(8)}`;
  return str;
}

function scrollToTop(elementName: string): void {
  document.getElementById(elementName)?.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function checkIfHasInformationOnText(
  property: string | number | undefined,
): string | number {
  if (property) {
    return property;
  }

  return '-';
}

export {
  checkIfValidEmail,
  escapeRegExp,
  phoneMask,
  scrollToTop,
  checkIfHasInformationOnText,
  getDateWithoutTzOffset,
};
export { CustomOnClick } from './customOnClick';
