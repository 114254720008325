import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const CustomersWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;

  .title-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    h1 {
      padding-top: ${isMacOs ? 4 : 0}px;
    }
  }

  .customers-grid {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 50px;
  }

  @media (max-width: 880px) {
    .title-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const EmptyMessage = styled.h1`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  align-items: center;
  justify-content: center;
  top: -65px;
  padding: 20px 0 7px 0;
  z-index: 3;
  margin-bottom: 32px;
  background-color: ${({ theme }) => theme.grayBackground};

  .tabs {
    display: flex;
    width: 100%;
    gap: 24px;
    align-items: center;

    .selected {
      transition: color 0.4s ease-in, border-bottom 0.4s ease-in;
      color: ${({ theme }) => theme.boundGreen};
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.boundGreen};
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.grayMed};
      cursor: pointer;

      &:hover {
        transition: all 0.2s ease-in;
        transform: translateY(-5px);
      }
    }
  }

  .filters {
    margin: 0;
  }
`;
