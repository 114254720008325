import styled from 'styled-components';

export const StyledNotificationsPage = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.buttons {
      button {
        cursor: pointer;
        border: 0;
        background: 0;
        font-size: 1.2rem;
        outline: 0;
        margin-left: 4vw;
      }
    }
  }

  div.container {
    ul.list {
      li {
        animation: slideIn 0.4s;
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 2% 0;
        display: flex;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
        }

        .content {
          flex: 1;

          h2 {
            font-size: 1.6rem;
            color: ${({ theme }) => theme.boundBlack};
            transition: 0.2s;
          }

          &.new p,
          &.new p svg {
            color: ${({ theme }) => theme.boundGreen};
          }

          p {
            font-size: 1.24rem;
            color: ${({ theme }) => theme.grayDark};
          }
        }

        .tools {
          display: flex;

          svg {
            margin-left: 4px;
            font-size: 24px;
            color: ${({ theme }) => theme.boundBlack};
          }
        }
      }
    }
  }
`;
