import { Layout } from 'components';
import { observer } from 'mobx-react';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AuthStorage } from 'storage';
import { StyledInfo } from 'styles/components/info';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledAccount } from 'styles/pages/account';
import { StyledOrderDetailsInfo } from 'styles/pages/orderDetails';

export const Account: React.FC = observer(() => {
  return (
    <Layout>
      <StyledAccount>
        <header>
          <PageTitle>Account</PageTitle>
          <Link to="/edit-account">
            <FiEdit />
          </Link>
        </header>

        <div className="container">
          <StyledOrderDetailsInfo index={0}>
            <div className="grid">
              <StyledInfo>
                <h2>Name</h2>
                <span>{AuthStorage.user.name}</span>
              </StyledInfo>
              <StyledInfo>
                <h2>Last Name</h2>
                <span>{AuthStorage.user.last_name}</span>
              </StyledInfo>
            </div>
          </StyledOrderDetailsInfo>

          <StyledOrderDetailsInfo index={1}>
            <div className="grid">
              <StyledInfo>
                <h2>Email</h2>
                <span>{AuthStorage.user.email}</span>
              </StyledInfo>
              <StyledInfo>
                <h2>Phone</h2>
                <span>{AuthStorage.user.phone_number}</span>
              </StyledInfo>
            </div>
          </StyledOrderDetailsInfo>
        </div>
      </StyledAccount>
    </Layout>
  );
});
