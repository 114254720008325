import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  animation: fadeIn 0.5s;

  .main-inputs {
    div:first-of-type {
      margin-bottom: 18px;
    }

    input {
      height: 48px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      float: right;
      margin-bottom: 54px;
      cursor: pointer;
    }

    button {
      margin-top: 54px;
      height: 56px;
    }
  }
`;

export const TitleBound = styled.div`
  font-size: 96px;
  color: ${({ theme }) => theme.boundGreen};
  font-weight: 500;
  letter-spacing: -0.5%;
  margin-top: -50px;

  img {
    width: 300px;
  }
`;
