import { getEnumKey } from 'helpers/getEnumKey';
import { IApplicantStatusBarType, IApplicantStatusEnum } from 'models';
import styled from 'styled-components';

function getApplicantStatusBarKey(value: string) {
  return getEnumKey<IApplicantStatusBarType>(IApplicantStatusEnum, value);
}

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const ApplicantContainer = styled.div`
  width: 100%;
  min-height: 210px;
  background-color: ${({ theme }) => theme.boundWhite};
  border-radius: 4px;
  padding: 24px;
  box-shadow: 0px 0px 1px 0px rgb(0, 0, 0, 0.04),
    0px 0px 2px 0px rgb(0, 0, 0, 0.06);
  animation: slideIn 0.55s;
  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 2px 5px 10px #ccc;
  }

  @media screen and (max-width: 560px) {
    max-width: 350px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  div.title-applicant-container {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 18px;

    > h1 {
      color: ${({ theme }) => theme.boundBlack};
      font-size: 24px;
      font-weight: 500;
      padding-top: ${isMacOs ? 5 : 0}px;
    }
  }

  div.buttons-container {
    display: flex;
    gap: 16px;

    button {
      height: 40px;
      font-size: 14px;
    }
  }

  .status {
    text-transform: uppercase;
    font-size: 15px;
    color: ${({ theme }) => theme.boundGreen};
    animation: pulse 2s infinite;
  }

  @media (max-width: 880px) {
    button {
      width: 100px;
    }
  }

  @media screen and (max-width: 1160px) {
    div.title-applicant-container {
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 18px;
      margin-bottom: 20px;

      > h1 {
        color: ${({ theme }) => theme.boundBlack};
        font-weight: 500;
        padding-top: ${isMacOs ? 5 : 0}px;
      }
    }
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;

    div.title-applicant-container {
      > h1 {
        font-size: 20px;
      }
    }
  }
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  grid-row-gap: 35px;

  @media (max-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const VehicleInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 35px;

  @media (max-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const VehicleInfoCard = styled.div`
  width: 100%;
  margin-top: 30px;

  h1 {
    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.boundBlack};
    text-transform: uppercase;
  }
`;

export const StatusBar = styled.span<{
  type: IApplicantStatusBarType;
}>`
  font-weight: 400;
  padding: ${isMacOs ? '4px 16px 0' : '8px 16px'};
  line-height: 25px;
  border-radius: 125px;
  font-size: 14px;
  border: ${({ type, theme }) =>
    type === getApplicantStatusBarKey(IApplicantStatusEnum.PENDING_COMPLETION)
      ? `1px solid ${theme.boundBlue}`
      : 'none'};
  color: ${({ type, theme }) =>
    type === getApplicantStatusBarKey(IApplicantStatusEnum.PENDING_COMPLETION)
      ? theme.boundBlue
      : theme.boundWhite};
  background-color: ${({ type, theme }) => {
    switch (type) {
      case getApplicantStatusBarKey(IApplicantStatusEnum.DECLINED):
        return theme.grayDark;

      case getApplicantStatusBarKey(IApplicantStatusEnum.DECLINED_VERIFICATION):
        return theme.boundRed;

      case getApplicantStatusBarKey(IApplicantStatusEnum.PENDING_APPROVAL):
        return theme.boundBlue;

      case getApplicantStatusBarKey(IApplicantStatusEnum.PENDING_COMPLETION):
        return theme.boundWhite;

      default:
        return theme.boundBlack;
    }
  }};
`;
