import React, { useState, useCallback } from 'react';
import {
  IOrder,
  IOrderServiceTypeText,
  IOrderStatusIcons,
  IOrderAndBatchOrderStatusText,
} from 'models/IOrder';
import { StyledChevron, StyledOrder } from 'styles/components/order';
import { useHistory } from 'react-router-dom';
import { StyledInfo } from 'styles/components/info';
import { CustomOnClick } from 'helpers';
import { format } from 'date-fns';

const getFormattedDriverName = (
  firstName: string | null,
  lastName: string | null,
) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName && !lastName) {
    return `${firstName}`;
  }
  if (!firstName && lastName) {
    return `${lastName}`;
  }
  return 'No driver';
};

export const OrderInBatch: React.FC<{ order: IOrder; index?: number }> = ({
  order,
  index = 0,
}) => {
  const history = useHistory();
  const formattedDriverName = getFormattedDriverName(
    order?.driver?.first_name,
    order?.driver?.last_name,
  );

  const [isOpen, setIsOpen] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const [driverName] = useState(formattedDriverName);

  const handleToggle = useCallback(() => {
    setIsOpen(_isOpen => !_isOpen);
  }, []);

  const createdAt = format(
    new Date(order.created_at),
    'MM/dd/yyyy hh:mm:ss aaa',
  );

  return (
    <StyledOrder
      status={order.status}
      isHover={isHover}
      isOpen={isOpen}
      data-testid="order"
      index={index}
    >
      <header onClick={handleToggle}>
        <span className="id">#{order.id}</span>
        <span className="date">{createdAt}</span>
        <span className="names">{driverName}</span>
        <span className="price">${order.price}</span>
        <div className="status">
          {IOrderStatusIcons[order.status]}
          <span>{IOrderAndBatchOrderStatusText[order.status]}</span>
        </div>
        <StyledChevron open={isOpen} />
      </header>
      <div
        className="content"
        {...CustomOnClick(() =>
          history.push(`${history.location.pathname}/order/${order.id}`),
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <StyledInfo>
          <h2>SERVICE TYPE</h2>
          <span>{IOrderServiceTypeText[order.service_type]}</span>
        </StyledInfo>
        <StyledInfo>
          <h2>RECIPIENT</h2>
          <span>{order.recipient}</span>
        </StyledInfo>
        <StyledInfo>
          <h2>PICK UP</h2>
          <span>{order.pick_up}</span>
        </StyledInfo>
        <StyledInfo>
          <h2>DROP OFF</h2>
          <span>{order.drop_off}</span>
        </StyledInfo>
      </div>
    </StyledOrder>
  );
};
