import { ChevronRightIconIMG } from 'assets';
import { Layout } from 'components';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { AuthStorage } from 'storage';
import { StyledInfo } from 'styles/components/info';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledAccount } from 'styles/pages/account';
import { StyledOrderDetailsInfo } from 'styles/pages/orderDetails';

export const EditAccount: React.FC = observer(() => {
  const [hasChanges, setHasChanges] = useState(false);
  const [formValues, setFormValues] = useState(AuthStorage.user);
  const history = useHistory();

  useEffect(() => {
    setHasChanges(
      JSON.stringify(formValues) !== JSON.stringify(AuthStorage.user),
    );
  }, [formValues]);

  const handleSave = useCallback(() => {
    AuthStorage.setUser({
      ...AuthStorage.user,
      ...formValues,
    });
    history.goBack();
  }, [formValues, history]);

  return (
    <Layout>
      <StyledAccount>
        <header>
          <div className="title">
            <Link to="/account">Account</Link>
            <ChevronRightIconIMG />
            <PageTitle>Edit</PageTitle>
          </div>
        </header>

        <div className="container">
          <StyledOrderDetailsInfo index={0}>
            <div className="grid">
              <StyledInfo>
                <h2>Name</h2>
                <input
                  type="text"
                  onChange={e =>
                    setFormValues({ ...formValues, name: e.target.value })
                  }
                  defaultValue={AuthStorage.user.name}
                />
              </StyledInfo>
              <StyledInfo>
                <h2>Last Name</h2>
                <input
                  type="text"
                  onChange={e =>
                    setFormValues({ ...formValues, last_name: e.target.value })
                  }
                  defaultValue={AuthStorage.user.last_name}
                />
              </StyledInfo>
            </div>
          </StyledOrderDetailsInfo>

          <StyledOrderDetailsInfo index={1}>
            <div className="grid">
              <StyledInfo>
                <h2>Email</h2>
                <input
                  type="text"
                  onChange={e =>
                    setFormValues({ ...formValues, email: e.target.value })
                  }
                  defaultValue={AuthStorage.user.email}
                />
              </StyledInfo>
              <StyledInfo>
                <h2>Phone</h2>
                <input
                  type="text"
                  onChange={e =>
                    setFormValues({
                      ...formValues,
                      phone_number: e.target.value,
                    })
                  }
                  defaultValue={AuthStorage.user.phone_number}
                />
              </StyledInfo>
            </div>
          </StyledOrderDetailsInfo>
        </div>

        <footer>
          {hasChanges && (
            <button type="button" onClick={handleSave} className="save">
              SAVE
            </button>
          )}
        </footer>
      </StyledAccount>
    </Layout>
  );
});
