import {
  BatchOrderIMG,
  LogoIMG,
  OrdersIconIMG,
  PromocodesIMG,
  SingleOrderIMG,
  SingleUserIMG,
  UserIconIMG,
  UsersIconIMG,
} from 'assets';
import ReactTooltip from 'react-tooltip';
import React, { useState, useEffect, useCallback } from 'react';

import { FiMenu } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import { NavLink, useLocation } from 'react-router-dom';
import { StyledSidebar } from 'styles/components/sidebar';

const PromocodesIcon: React.FC = () => {
  return (
    <li>
      <NavLink to="/promocodes" activeClassName="actived" data-tip="Promocodes">
        <PromocodesIMG />
        <span>Promocodes</span>
      </NavLink>
    </li>
  );
};
const DriverClientIcons: React.FC = () => {
  return (
    <>
      <li>
        <NavLink to="/applicants" activeClassName="actived" data-tip="Drivers">
          <SingleUserIMG />
          <span>Drivers</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/clients" activeClassName="actived" data-tip="Clients">
          <UsersIconIMG />
          <span>Clients</span>
        </NavLink>
      </li>
    </>
  );
};

const BatchSingleOrderIcons: React.FC = () => {
  return (
    <>
      <li>
        <NavLink
          to="/orders"
          activeClassName="actived"
          data-tip="Single orders"
        >
          <SingleOrderIMG />
          <span>Single</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/batch-orders"
          activeClassName="actived"
          data-tip="Batch orders"
        >
          <BatchOrderIMG />
          <span>Batches</span>
        </NavLink>
      </li>
    </>
  );
};

const ExpandedOrdersSubIcons: React.FC = () => {
  return (
    <div className="open-submenu">
      <li className="subicons">
        <NavLink to="/orders" activeClassName="actived">
          <span>Single</span>
        </NavLink>
      </li>
      <li className="subicons">
        <NavLink to="/batch-orders" activeClassName="actived">
          <span>Batches</span>
        </NavLink>
      </li>
    </div>
  );
};

const ExpandedSubIcons: React.FC = () => {
  return (
    <div className="open-submenu">
      <li className="subicons">
        <NavLink to="/applicants" activeClassName="actived">
          <span>Drivers</span>
        </NavLink>
      </li>
      <li className="subicons">
        <NavLink to="/clients" activeClassName="actived">
          <span>Clients</span>
        </NavLink>
      </li>
    </div>
  );
};

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const [isSidebarClose, setIsSidebarClose] = useState<boolean | undefined>(
    undefined,
  );
  const [collapseSubtitle, setCollapseSubtitle] = useState<boolean>(false);
  const [collapseOrdersSubtitle, setCollapseOrdersSubtitle] =
    useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [collapseSubtitle, collapseOrdersSubtitle, isSidebarClose]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    return () => window.removeEventListener('resize', () => {});
  }, []);

  const checkPathname = useCallback(() => {
    if (
      location.pathname.includes('/clients') ||
      location.pathname.includes('/applicants')
    ) {
      setCollapseSubtitle(true);
      // if (width > 880) {
      //   setCollapseOrdersSubtitle(false);
      // }
    } else if (
      location.pathname.includes('/orders') ||
      location.pathname.includes('/batch-orders')
    ) {
      setCollapseOrdersSubtitle(true);
      // if (width > 880) {
      //   setCollapseSubtitle(false);
      // }
    } else if (width > 880) {
      console.log('what');
      // setCollapseSubtitle(false);
      // setCollapseOrdersSubtitle(false);
    }
  }, [location, width]);

  useEffect(() => {
    if (width <= 880) {
      setCollapseSubtitle(true);
      setCollapseOrdersSubtitle(true);
      return;
    }

    if (isSidebarClose) {
      setCollapseSubtitle(true);
      setCollapseOrdersSubtitle(true);
    } else {
      checkPathname();
    }
  }, [width, isSidebarClose, checkPathname]);

  useEffect(() => {
    checkPathname();
  }, [location, checkPathname]);

  const handleWindowResize = (
    isSidebarClose: boolean,
    width: number,
    text: string,
  ) => {
    if (width <= 880) {
      if (!isSidebarClose) {
        return text;
      }

      return '';
    }

    if (isSidebarClose) {
      return text;
    }

    return '';
  };

  return (
    <StyledSidebar isSidebarClose={isSidebarClose}>
      <header>
        <div
          className="hamburger"
          data-tip={handleWindowResize(!!isSidebarClose, width, 'Expand')}
          data-place="right"
        >
          <FiMenu
            onClick={() => {
              setIsSidebarClose(
                isSidebarClose === undefined ? true : !isSidebarClose,
              );
            }}
          />
        </div>

        <img src={LogoIMG} alt="Bound Logo" className="logo" />
      </header>

      <ul>
        {!isSidebarClose && width >= 880 && (
          <li>
            <NavLink
              to="#"
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
              ) => {
                event.preventDefault();
                setCollapseOrdersSubtitle(value => !value);
              }}
            >
              <OrdersIconIMG />
              <span>Orders</span>
              {!isSidebarClose && (
                <IoIosArrowDown
                  className={collapseOrdersSubtitle ? 'arrow open' : 'arrow'}
                />
              )}
            </NavLink>
          </li>
        )}

        {collapseOrdersSubtitle && width >= 880 && !isSidebarClose && (
          <ExpandedOrdersSubIcons />
        )}

        {collapseOrdersSubtitle && width >= 880 && !!isSidebarClose && (
          <BatchSingleOrderIcons />
        )}

        {collapseOrdersSubtitle && width < 880 && !isSidebarClose && (
          <BatchSingleOrderIcons />
        )}

        {collapseOrdersSubtitle && width < 880 && !!isSidebarClose && (
          <BatchSingleOrderIcons />
        )}

        {/* -------------------------------------------------------------------------------------------- */}
        <li>
          <NavLink
            to="/bounders"
            activeClassName="actived"
            data-tip={handleWindowResize(!!isSidebarClose, width, 'Bounders')}
          >
            <UserIconIMG />
            <span>Bounders</span>
          </NavLink>
        </li>
        {/* -------------------------------------------------------------------------------------------- */}

        {!isSidebarClose && width >= 880 && (
          <li>
            <NavLink
              to="#"
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
              ) => {
                event.preventDefault();
                setCollapseSubtitle(value => !value);
              }}
            >
              <UsersIconIMG />
              <span>Requests</span>
              {!isSidebarClose && (
                <IoIosArrowDown
                  className={collapseSubtitle ? 'arrow open' : 'arrow'}
                />
              )}
            </NavLink>
          </li>
        )}

        {collapseSubtitle && width >= 880 && !isSidebarClose && (
          <ExpandedSubIcons />
        )}

        {collapseSubtitle && width >= 880 && !!isSidebarClose && (
          <DriverClientIcons />
        )}

        {collapseSubtitle && width < 880 && !isSidebarClose && (
          <DriverClientIcons />
        )}

        {collapseSubtitle && width < 880 && !!isSidebarClose && (
          <DriverClientIcons />
        )}

        <PromocodesIcon />
      </ul>
    </StyledSidebar>
  );
};
