export interface IPromocode {
  id: number;
  code: string;
  discount_value: string;
  discount_type: string;
  expiration_datetime: string;
  description: string;
}

export interface IPromocodeResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IPromocode[];
}

export enum IDiscountTypeText {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export enum IPromocodeStatusEnum {
  ALL = 'ALL',
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
}
