import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

import {
  BackgroundBlurWrapper,
  ModalWrapper,
  ModalBody,
  ModalButtonsComponent,
  ModalTitle,
} from 'styles/components/modal';
import { Button } from 'components';

interface IModal {
  size: 'sm' | 'bg';
  modalTitle: string;
  modalBody?: string;
  cancelButtonText?: string;
  confirmButtonText: string;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
}

export const Modal: React.FC<IModal> = ({
  size,
  confirmButtonText,
  cancelButtonText,
  modalTitle,
  onConfirm,
  modalBody,
  loading,
  onCancel,
}) => {
  return (
    <BackgroundBlurWrapper>
      <ModalWrapper size={size}>
        <ModalTitle>{modalTitle}</ModalTitle>

        <ModalBody>{modalBody}</ModalBody>

        <ModalButtonsComponent>
          {!loading ? (
            <>
              <Button
                buttonSize="sm"
                text={confirmButtonText}
                typeButton="primary"
                onClick={onConfirm}
              />

              {cancelButtonText && (
                <Button
                  buttonSize="sm"
                  text={cancelButtonText}
                  typeButton="secondary"
                  onClick={onCancel}
                />
              )}
            </>
          ) : (
            <AiOutlineLoading size={30} />
          )}
        </ModalButtonsComponent>
      </ModalWrapper>
    </BackgroundBlurWrapper>
  );
};
