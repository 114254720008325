import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const StyledHeader = styled.header<{
  avatarIsOpen: boolean;
  bellIsOpen: boolean;
}>`
  position: sticky;
  background-color: ${({ theme }) => theme.boundWhite};
  box-shadow: 0px 4px 8px ${({ theme }) => theme.grayMed};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75vh 5% 0.75vh 2%;
  height: 60px;
  z-index: 9;

  .left-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;

    .wrapper {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > span {
        font-size: 20px;
        margin-left: 10px;
        padding-top: ${isMacOs ? 5 : 0}px;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .right-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .bell {
    position: relative;
    z-index: 9;

    .length {
      background-color: ${({ theme }) => theme.boundGreen};
      color: ${({ theme }) => theme.boundWhite};
      padding: 4px 6px;
      border-radius: 50%;
      position: relative;
      top: -18px;
      left: -10px;
      cursor: pointer;
      z-index: 2;
      font-size: 0.9rem;
    }
    svg {
      color: ${({ theme }) => theme.boundBlack};
      font-size: 2rem;
      cursor: pointer;
      transition: opacity 0.2s;
      z-index: 1;
      outline: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .avatarContainer {
    z-index: 9;

    p {
      font-size: 24px;
      color: ${({ theme }) => theme.boundGreen};
      padding-top: ${isMacOs ? 3 : 0}px;
      text-align: center;
    }

    .avatar {
      cursor: pointer;
      transition: opacity 0.2s;
      background-color: ${({ theme }) => theme.grayLight};
      color: ${({ theme }) => theme.boundBlack};
      border-radius: 50%;
      height: 30px;
      text-align: center;
      width: 30px;
      margin-left: 2rem;

      span {
        font-size: calc(30px / 2.1); /* 50% of parent */
        line-height: 1;
        position: relative;
        top: calc(30px / 4); /* 25% of parent */
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .modal {
    position: absolute;
    transform: translateX(-200px);
    z-index: 9999;
    min-width: 240px;
    min-height: 120px;
    background-color: ${({ theme }) => theme.boundWhite};
    border: 1px solid #eee;
    box-shadow: 2px 2px 8px #aaa;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;

    span.hello {
      font-size: 1.42rem;
    }

    a {
      :hover {
        transition: all 0.5s ease-in-out;
        text-decoration: underline;
        color: ${({ theme }) => theme.boundGreen};
      }
    }

    div.logout {
      cursor: pointer;
      align-self: flex-end;
      padding: 0 2%;

      :hover {
        span {
          transition: all 0.5s ease-in-out;
          text-decoration: underline;
          color: ${({ theme }) => theme.boundGreen};
        }
      }

      span {
        font-size: 1.2rem;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: ${({ avatarIsOpen, bellIsOpen }) =>
      avatarIsOpen || bellIsOpen ? 'flex' : 'none'};
    z-index: 2;
  }

  .avatar-modal {
    padding: 12px 1.5% 8px;
    display: ${({ avatarIsOpen }) => (avatarIsOpen ? 'flex' : 'none')};
    animation: openAvatar 0.6s forwards;

    @keyframes openAvatar {
      from {
        opacity: 0;
        transform: translateY(-20px) translateX(-200px);
      }
      to {
        opacity: 1;
        transform: translateY(0) translateX(-200px);
      }
    }
  }

  .bell-modal {
    display: ${({ bellIsOpen }) => (bellIsOpen ? 'flex' : 'none')};
    padding: 12px 12px;
    transform: translateX(-230px);
    animation: openBell 0.6s forwards;
    min-width: 380px;
    min-height: 100px;
    max-height: 300px;
    overflow-y: scroll;
    padding-bottom: 10px;

    header {
      display: flex;
      justify-content: space-between;

      font-size: 16px;
    }

    .list {
      flex: 1;

      display: flex;
      flex-direction: column;
      padding: 1% 0;
      margin-top: 6px;

      .notification {
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 2% 0;
        display: flex;
        flex-direction: column;

        &:last-child {
          border-bottom: none;
        }

        .title {
          font-size: 1.4rem;
          color: ${({ theme }) => theme.boundBlack};

          svg {
            font-size: 14px;
            margin-right: 2px;
          }
        }

        .description {
          &.new,
          &.new svg {
            color: ${({ theme }) => theme.boundGreen};
          }

          &.loading,
          &.loading svg {
            color: ${({ theme }) => theme.grayLight};
          }

          font-size: 1.1rem;
          color: ${({ theme }) => theme.grayDark};
        }
      }
    }

    @keyframes openBell {
      from {
        opacity: 0;
        transform: translateY(-20px) translateX(-370px);
      }
      to {
        opacity: 1;
        transform: translateY(0) translateX(-370px);
      }
    }

    @media screen and (max-width: 560px) {
      left: 70px;
    }
  }
`;
