import styled from 'styled-components';

interface ILabel {
  hasError: boolean;
}
interface IButton {
  onClick: React.MouseEventHandler<HTMLInputElement>;
}

export const PromocodeButton = styled.button<IButton>`
  height: 50px;
  width: 60px;
  border: 1px solid ${({ theme }) => theme.grayMed};
  font-size: 2rem;
  background-color: white;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.boundGreen};
    color: ${({ theme }) => theme.boundWhite};
  }

  &:hover {
    background-color: ${({ theme }) => theme.boundGreen};
    color: ${({ theme }) => theme.boundWhite};
  }

  &.active:hover {
    filter: brightness(1.1);
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.boundRed};
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding-left: 16px;
  margin-top: 8px;
`;

export const LabelText = styled.label<ILabel>`
  background: white;
  color: ${({ theme, hasError }) =>
    hasError ? theme.boundRed : theme.boundGreen};
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  margin-top: -7px;
  margin-left: 16px;
  padding: 0 4px;
  position: absolute;
`;

export const PasswordIconEye = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 2;

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.grayMed};
  }
`;
