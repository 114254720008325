import styled, { css } from 'styled-components';

interface IContainer {
  hasError: boolean;
  hasValue: boolean;
}

interface ILabel {
  hasError: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;

  input {
    font-size: 16px;
    border: 1px solid
      ${({ theme, hasError }) => (hasError ? theme.boundRed : theme.grayMed)};
    border-radius: 4px;
    padding: 0 16px;
    padding-top: 2px;
    outline: none;
    color: black;
    transition: border 0.3s;
    z-index: 1;

    ${({ hasValue }) =>
      hasValue &&
      css`
        border: 2px solid ${({ theme }) => theme.boundGreen};
      `}

    &:focus {
      border: 2px solid ${({ theme }) => theme.boundGreen};
    }

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.grayDark};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.grayDark};
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.boundRed};
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding-left: 16px;
  margin-top: 8px;
`;

export const LabelText = styled.label<ILabel>`
  background: white;
  color: ${({ theme, hasError }) =>
    hasError ? theme.boundRed : theme.boundGreen};
  z-index: 2;
  font-size: 12px;
  font-weight: 400;
  margin-top: -7px;
  margin-left: 16px;
  padding: 0 4px;
  position: absolute;
`;

export const PasswordIconEye = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 2;

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.grayMed};
  }
`;
