import styled from 'styled-components';

export const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-update {
    margin-bottom: 112px;
  }

  .title-confirm {
    margin-bottom: 24px;
  }

  .subtitle-confirm {
    margin-bottom: 72px;
  }

  input {
    height: 48px;
  }

  input:first-of-type {
    margin-bottom: 24px;
  }

  .button-update {
    margin-top: 78px;
    width: 100%;
  }
`;
