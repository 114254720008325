import { makeAutoObservable } from 'mobx';
import { IApplicant } from 'models/IApplicant';

export class ApplicantsStorageClass {
  applicants: IApplicant[] = [];

  constructor() {
    makeAutoObservable(this);
  }
}

export const factoryApplicantsStorage = (): ApplicantsStorageClass =>
  new ApplicantsStorageClass();

export default factoryApplicantsStorage();
