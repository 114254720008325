import React, { useEffect, useCallback, useState } from 'react';
import {
  Layout,
  Applicant,
  Loading,
  SearchInput,
  Modal,
  Pagination,
} from 'components';
import { scrollToTop } from 'helpers';
import { PageTitle } from 'styles/components/pageTitle';
import { ApplicantsService } from 'services';
import { IApplicantResponse } from 'models';
import {
  ApplicantsWrapper,
  EmptyMessage,
  FiltersContainer,
} from 'styles/pages/applicants';
import { getEnumKey } from 'helpers/getEnumKey';

enum ITypeModal {
  DENY = 'deny',
  APPROVE = 'approve',
}

enum IStatusEnum {
  PENDING_APPROVAL = 'Pending approval',
  PENDING_COMPLETION = 'Pending information',
  PENDING_VERIFICATION = 'Pending verification',
  DECLINED = 'Denied application',
  DECLINED_VERIFICATION = 'Denied verification',
}

type IStatusType = keyof typeof IStatusEnum;

const filterTabs = Object.values(IStatusEnum);

function getStatusKey(value: string) {
  return getEnumKey<IStatusType>(IStatusEnum, value);
}

export const DriverApplicants: React.FC = () => {
  const [applicants, setApplicants] = useState<IApplicantResponse>();
  const [selectedApplicant, setSelectedApplicant] =
    useState<{ id: number; phone: string }>();
  const [filterType, setFilterType] = useState<IStatusEnum>(
    IStatusEnum.PENDING_APPROVAL,
  );
  const [page, setPage] = useState('1');
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<ITypeModal>(ITypeModal.APPROVE);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPage('1');
  }, [filterType, search]);

  const getApplicantsService = useCallback(
    (searchString = search, _page = page, status = filterType) => {
      setLoading(true);

      ApplicantsService.getAll(searchString, _page, getStatusKey(status))
        .then(applicants => {
          setApplicants(applicants);
        })
        .catch(() => {
          setApplicants(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search, page, filterType],
  );

  useEffect(() => {
    getApplicantsService(search, undefined, filterType);
  }, [getApplicantsService, search, filterType]);

  const handleApproveApplicant = useCallback(
    (id: number, phone: string) => {
      ApplicantsService.approveApplicant(id, phone).finally(() => {
        setShowModal(false);
        getApplicantsService(search, page, IStatusEnum.PENDING_APPROVAL);
        scrollToTop('scroll-element');
      });
    },
    [getApplicantsService, page, search],
  );

  const handleRejectApplicant = useCallback(
    (id: number, phone: string) => {
      ApplicantsService.rejectApplicant(id, phone).finally(() => {
        setShowModal(false);
        getApplicantsService(search, page, IStatusEnum.PENDING_APPROVAL);
        scrollToTop('scroll-element');
      });
    },
    [getApplicantsService, page, search],
  );

  function FilterOption({
    title,
    filterValue,
  }: {
    title: string;
    filterValue: IStatusEnum;
  }) {
    return (
      <span
        className={filterType === filterValue ? 'selected' : ''}
        onClick={() => {
          setFilterType(filterValue);
          scrollToTop('scroll-element');
        }}
      >
        {title}
      </span>
    );
  }

  return (
    <>
      {showModal && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          modalTitle="Are you sure?"
          size="sm"
          modalBody={
            typeModal === ITypeModal.APPROVE
              ? 'This driver will become a Bounder.'
              : 'This driver will be declined.'
          }
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            if (selectedApplicant) {
              if (typeModal === ITypeModal.APPROVE) {
                handleApproveApplicant(
                  selectedApplicant.id,
                  selectedApplicant.phone,
                );
              } else {
                handleRejectApplicant(
                  selectedApplicant.id,
                  selectedApplicant.phone,
                );
              }
            }
          }}
        />
      )}

      <Layout>
        <ApplicantsWrapper id="scroll-element">
          <div className="title-container">
            <PageTitle>Drivers</PageTitle>

            <SearchInput
              onFilterAction={setSearch}
              onClearFilterAction={() => setSearch('')}
            />
          </div>

          <FiltersContainer>
            <div className="tabs">
              {filterTabs.map(tab => (
                <FilterOption title={tab} filterValue={tab} key={tab} />
              ))}
            </div>
          </FiltersContainer>

          <div className="applicant-grid">
            {loading && !applicants ? <Loading size="85" /> : null}

            {applicants && applicants.results && applicants.results.length
              ? applicants.results.map(applicant => (
                  <Applicant
                    data-testid="applicant"
                    key={applicant.id}
                    {...applicant}
                    onDenyButton={() => {
                      setShowModal(true);
                      setTypeModal(ITypeModal.DENY);
                      setSelectedApplicant({
                        id: applicant.id,
                        phone: applicant.phone_number,
                      });
                    }}
                    onApproveButton={() => {
                      setShowModal(true);
                      setTypeModal(ITypeModal.APPROVE);
                      setSelectedApplicant({
                        id: applicant.id,
                        phone: applicant.phone_number,
                      });
                    }}
                  />
                ))
              : null}

            {!applicants?.results.length && !loading && (
              <EmptyMessage>No applicants found</EmptyMessage>
            )}
          </div>

          {applicants && applicants?.results.length ? (
            <Pagination
              totalElements={applicants.count}
              elementsPerPage={20}
              page={Number(page) || 1}
              onChangePage={p => {
                setPage(p);
                getApplicantsService(search, p);
              }}
            />
          ) : null}
        </ApplicantsWrapper>
      </Layout>
    </>
  );
};
