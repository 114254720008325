import React from 'react';
import { format } from 'date-fns';
import { checkIfHasInformationOnText, getDateWithoutTzOffset } from 'helpers';
import { IPromocode } from 'models/IPromocode';
import { StyledInfo } from 'styles/components/info';
import {
  PromocodeContainer,
  TitleContainer,
  InfoGrid,
  StatusBar,
} from 'styles/components/promocode';
import { checkIsPromocodevalid } from 'helpers/checkIsPromocodevalid';
import { Button } from './Button';

const formatDiscountTypeAndValue = (type: string, value: string) => {
  if (type === 'PERCENTAGE') {
    return `${value}%`;
  }
  return `-$${value}`;
};

interface IPromocodeProps extends IPromocode {
  onDeleteButton: () => void;
  onEditButton: () => void;
}

export const Promocode: React.FC<IPromocodeProps> = ({
  code,
  discount_value,
  discount_type,
  expiration_datetime,
  description,
  onDeleteButton,
  onEditButton,
}) => {
  const returnStatusField = (datetime: string) => {
    const valid = checkIsPromocodevalid(datetime);
    return <StatusBar valid={valid}>{valid ? 'Valid' : 'Expired'}</StatusBar>;
  };

  return (
    <PromocodeContainer>
      <TitleContainer>
        <div className="title-applicant-container">
          <h1>{`${code}`}</h1>
          {returnStatusField(expiration_datetime)}
        </div>
        <div className="buttons-container">
          <Button
            buttonSize="sm"
            text="Delete"
            typeButton="danger"
            onClick={onDeleteButton}
          />
          <Button
            buttonSize="sm"
            text="Edit"
            typeButton="primary"
            onClick={onEditButton}
          />
        </div>
      </TitleContainer>

      <InfoGrid>
        <StyledInfo>
          <h2>Number/Type</h2>
          <span>
            {formatDiscountTypeAndValue(discount_type, discount_value)}
          </span>
        </StyledInfo>

        <StyledInfo>
          <h2>Description</h2>
          <span>{checkIfHasInformationOnText(description)}</span>
        </StyledInfo>

        <StyledInfo>
          <h2>Expiration datetime</h2>
          <span>
            {expiration_datetime
              ? format(
                  getDateWithoutTzOffset(expiration_datetime),
                  'MM/dd/yyyy',
                )
              : '-'}
          </span>
        </StyledInfo>
      </InfoGrid>
    </PromocodeContainer>
  );
};
