import styled from 'styled-components';

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-reset {
    margin-bottom: 24px;
  }

  .subtitle-reset {
    width: 70%;
  }

  .wrapper {
    margin-top: 88px;
  }

  input {
    width: 100%;
    height: 48px;
    align-self: center;
  }

  .btn-send {
    margin-top: 78px;
  }

  .title-confirm {
    margin-bottom: 24px;
  }

  .subtitle-confirm {
    width: 70%;
    margin-bottom: 70px;

    span {
      font-size: 16px;
      font-weight: 800;
    }
  }
`;
