import React, { useState, useCallback } from 'react';
import { IBatchOrder } from 'models/IOrder';
import { useHistory } from 'react-router-dom';
import { StyledInfo } from 'styles/components/info';
import { checkIfHasInformationOnText, CustomOnClick } from 'helpers';
import { format } from 'date-fns';
import { StyledBatchOrder, StyledChevron } from 'styles/components/BatchOrder';

export const BatchOrder: React.FC<{ order: IBatchOrder; index?: number }> = ({
  order,
  index = 0,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [isHover, setIsHover] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(_isOpen => !_isOpen);
  }, []);

  const createdAt = format(
    new Date(order?.created_at),
    'MM/dd/yyyy hh:mm:ss aaa',
  );

  return (
    <StyledBatchOrder
      status={order.status}
      isHover={isHover}
      isOpen={isOpen}
      data-testid="order"
      index={index}
    >
      <header onClick={handleToggle}>
        <span className="id">{order?.name}</span>
        <span className="date">{createdAt}</span>
        <span className="date">
          {order?.orders_count}
          {order?.orders_count > 1 ? ' orders' : ' order'}
        </span>
        <div className="status">
          <StyledChevron open={isOpen} />
        </div>
      </header>
      <div
        className="content"
        {...CustomOnClick(() => history.push(`/batch-orders/${order?.id}`))}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <StyledInfo>
          <h2>PRICE</h2>
          <span>
            {order?.price && '$'}
            {checkIfHasInformationOnText(order?.price)}
          </span>
        </StyledInfo>

        <StyledInfo>
          <h2>COMPANY</h2>
          <span>
            {checkIfHasInformationOnText(order?.customer?.company_name)}
          </span>
        </StyledInfo>

        <StyledInfo>
          <h2>CREATED BY</h2>
          <span>
            {checkIfHasInformationOnText(order?.customer?.first_name)}{' '}
            {checkIfHasInformationOnText(order?.customer?.last_name)}
          </span>
        </StyledInfo>
      </div>
    </StyledBatchOrder>
  );
};
