import styled from 'styled-components';

interface IStatus {
  status: string;
}

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const BounderContainer = styled.div<{ index: number }>`
  opacity: 0;
  animation: popUp 0.55s ${({ index }) => 0.15 * index}s forwards;
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: auto;
  background-color: ${({ theme }) => theme.boundWhite};
  border-radius: 4px;
  padding: 24px;
  box-shadow: 0px 0px 1px 0px rgb(0, 0, 0, 0.04),
    0px 0px 2px 0px rgb(0, 0, 0, 0.06), 0px 4px 8px 0px rgb(0, 0, 0, 0.04);
  transition: 0.25s;

  &:hover {
    box-shadow: 2px 8px 8px #ccc;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  .direction-span {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 10px 0 25px 0;
    font-size: 14px;

    svg {
      height: 14px;
      align-self: center;
    }

    span {
      margin: 0 5px;
      padding-top: ${isMacOs ? 5 : 0}px;
      color: ${({ theme }) => theme.boundBlue};
      font-family: ${({ theme }) => theme.ITCFontMd};
      font-size: 14px;
    }
  }

  h2 {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 400;
    font-family: ${({ theme }) => theme.ITCFontMd};
  }

  span {
    font-size: 16px;
    font-family: ${({ theme }) => theme.ITCFontMd};
    font-weight: 400;
  }

  .status {
    max-width: 90%;
    align-self: center;
    font-size: 12px;
    border-radius: 20px;
    line-height: 24px;
    background-color: #f58552;
    color: white;
    font-weight: 500;
    text-align: center;
    padding: 2px 4%;

    &.check {
      background-color: #bd1919;
    }
  }

  @media (min-width: 1500px) {
    width: 320px;
  }

  @media (max-width: 1300px) {
    width: 250px;
  }

  @media screen and (max-width: 560px) {
    margin: auto;
  }
`;

export const StyledAvatar = styled.img`
  min-width: 90px;
  min-height: 90px;
  max-width: 90px;
  max-height: 90px;
  border-radius: 50%;
  object-fit: cover;
  align-self: center;
`;

export const StatusBounder = styled.div<IStatus>`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  width: fit-content;
  margin: 0 auto;
  padding: 4px 20px;
  border-radius: 15px;
  color: ${({ theme }) => theme.boundWhite};
  background-color: ${({ theme, status }) =>
    status === 'APPLICANT' || status === 'AWAITING_INFO'
      ? theme.boundOrange
      : theme.boundRed};
`;
