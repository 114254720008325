import { makeAutoObservable } from 'mobx';
import { IUser } from 'models';
import { IToken } from 'models/IToken';

export class AuthStorageClass {
  user: IUser = {} as IUser;

  ensureAuthenticated = false;

  tokens: IToken = {} as IToken;

  constructor() {
    makeAutoObservable(this);
    this.firstLoad();
  }

  firstLoad(): void {
    const { user }: { user: IUser } = JSON.parse(
      localStorage.getItem('@Bound-User') || '{}',
    );

    if (user?.id) {
      this.setUser(user);
      this.ensureAuthenticated = true;
    }

    const tokens: IToken = JSON.parse(
      localStorage.getItem('@Bound-Tokens') || '{}',
    );
    if (tokens?.access) {
      this.setTokens(tokens);
    }
  }

  setUser(_user: IUser): void {
    localStorage.setItem('@Bound-User', JSON.stringify({ user: _user }));
    this.ensureAuthenticated = true;
    this.user = _user;
  }

  setTokens(_tokens: IToken): void {
    localStorage.setItem('@Bound-Tokens', JSON.stringify({ tokens: _tokens }));
    this.tokens = _tokens;
  }
}
export const factoryAuthStorage = (): AuthStorageClass => {
  return new AuthStorageClass();
};

export default factoryAuthStorage();
