import React from 'react';

import { LoadingContainer } from 'styles/components/loading';

interface ILoading {
  size: string;
}

export const Loading: React.FC<ILoading> = ({ size }) => {
  return <LoadingContainer size={size} data-testid="loading" />;
};
