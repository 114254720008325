import { makeAutoObservable } from 'mobx';
import { IOrder } from 'models/IOrder';

export class OrdersStorageClass {
  orders: IOrder[] = [];

  constructor() {
    makeAutoObservable(this);
  }
}

export const factoryOrdersStorage = (): OrdersStorageClass =>
  new OrdersStorageClass();

export default factoryOrdersStorage();
