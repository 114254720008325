import styled from 'styled-components';

interface ISearch {
  isOnFocus: boolean;
}

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const SearchInputContainer = styled.div<ISearch>`
  width: 343px;
  height: 48px;
  padding: 12px;
  border: ${({ theme, isOnFocus }) =>
    isOnFocus ? `2px solid ${theme.boundGreen}` : `1px solid ${theme.grayMed}`};
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px;
  border-radius: 4px;

  svg {
    color: ${({ theme }) => theme.grayDark};
    outline: none;
    cursor: pointer;
  }

  input[type='text'] {
    padding-top: ${isMacOs ? 4 : 0}px;
    border: none;
    height: 100%;
    width: 100%;
    background: transparent;
    outline: none;
    font-size: 16px;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.grayDark};
      font-size: 16px;
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.grayDark};
      font-size: 16px;
    }
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    margin-top: 20px;
  }
`;
