// import { IApplicantStatusBarType, IApplicantStatusEnum } from 'models';
import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const PromocodeContainer = styled.div`
  width: 100%;
  min-height: 210px;
  background-color: ${({ theme }) => theme.boundWhite};
  border-radius: 4px;
  padding: 24px;
  box-shadow: 0px 0px 1px 0px rgb(0, 0, 0, 0.04),
    0px 0px 2px 0px rgb(0, 0, 0, 0.06);
  animation: slideIn 0.55s;
  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 2px 5px 10px #ccc;
  }

  @media screen and (max-width: 560px) {
    max-width: 350px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  div.title-applicant-container {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 18px;

    > h1 {
      color: ${({ theme }) => theme.boundBlack};
      font-size: 24px;
      font-weight: 500;
      padding-top: ${isMacOs ? 5 : 0}px;
    }
  }

  div.buttons-container {
    display: flex;
    gap: 16px;

    button {
      height: 40px;
      font-size: 14px;
    }
  }

  .status {
    text-transform: uppercase;
    font-size: 15px;
    color: ${({ theme }) => theme.boundGreen};
    animation: pulse 2s infinite;
  }

  @media (max-width: 880px) {
    button {
      width: 100px;
    }
  }

  @media screen and (max-width: 1160px) {
    div.title-applicant-container {
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 18px;
      margin-bottom: 20px;

      > h1 {
        color: ${({ theme }) => theme.boundBlack};
        font-weight: 500;
        padding-top: ${isMacOs ? 5 : 0}px;
      }
    }
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;

    div.title-applicant-container {
      > h1 {
        font-size: 20px;
      }
    }
  }
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 35px;

  @media (max-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

// export const StatusBar = styled.span<{
//   type: IApplicantStatusBarType;
// }>`
//   font-weight: 400;
//   padding: ${isMacOs ? '4px 16px 0' : '8px 16px'};
//   line-height: 25px;
//   border-radius: 125px;
//   font-size: 14px;
// `;

export const StatusBar = styled.span<{
  valid: boolean;
}>`
  font-weight: 400;
  padding: ${isMacOs ? '7px 16px 4px' : '8px 16px'};
  line-height: 25px;
  border-radius: 125px;
  font-size: 14px;
  color: ${({ theme }) => theme.boundWhite};
  background-color: ${({ valid, theme }) =>
    valid ? theme.boundBlue : '#6B6B6B'};

  & > p {
    font-weight: 400;
    font-size: 14px;
    padding-top: ${isMacOs ? 4 : 0}px;
  }
`;
