export const Theme = {
  boundBlack: '#040707',
  grayDarkest: '#1C1E1E',
  grayDarker: '#363636',
  grayDark: '#6B6B6B',
  grayMed: '#DBDBDB',
  grayLight: '#F2F2F2',
  grayBackground: '#FAFAFA',
  boundWhite: '#FFFFFF',
  boundGreen: '#82C3B2',
  boundBlue: '#50808A',
  boundOrange: '#F58552',
  boundRed: '#AD1919',
  ITCFontMd: 'ITC Avant Garde Gothic Std',
  ITCFontXl: 'ITC Avant Garde Gothic Std XLt',
  ITCFontBk: 'ITC Avant Garde Gothic Std Bk',
};
