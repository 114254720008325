import styled from 'styled-components';

export const BoundersWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;

  .title-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 58px;

    .online-filters {
      display: flex;

      span {
        font-size: 16px;
        color: ${({ theme }) => theme.grayMed};
        font-weight: 500;
        cursor: pointer;
        margin-inline: 8px;

        &:hover,
        &.actived {
          text-decoration: underline;
          color: ${({ theme }) => theme.boundGreen};
        }

        &:hover {
          transition: all 0.2s ease-in;
          transform: translateY(-5px);
        }
      }
    }
  }

  .bounder-grid {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2vh;
    column-gap: 2%;
    margin-bottom: 50px;
  }

  .loading {
    margin: 0 auto;
  }

  @media (max-width: 880px) {
    .title-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const EmptyMessage = styled.h1`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
`;
