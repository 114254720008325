import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Navigation } from 'navigation';
import { Global, Theme } from 'styles';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/fonts.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <Global />
      <Navigation />
    </ThemeProvider>
    <ToastContainer hideProgressBar autoClose={3000} />
    <ReactTooltip place="right" type="dark" effect="float" delayShow={200} />
  </React.StrictMode>,
  document.getElementById('root'),
);
