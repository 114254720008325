import { IOrderStatus, IOrderStatusColors } from 'models/IOrder';
import styled from 'styled-components';

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const StyledOrderDetails = styled.section<{ status: IOrderStatus }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;
  animation: fadeIn 0.5s;

  header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s;
    animation: slideIn 0.35s forwards;
    flex-wrap: wrap;

    div.title {
      display: flex;
      align-items: center;
      font-size: 40px;
      min-width: 340px;
      margin-bottom: 0.5rem;

      a {
        color: ${({ theme }) => theme.grayMed};
        cursor: pointer;
        font-size: 40px;
      }

      svg {
        margin: 0 1.4rem;
        width: 32px;
        height: 32px;
      }
    }

    .state {
      width: fit-content;
      background-color: ${({ theme, status }) =>
        theme[IOrderStatusColors[status]]};
      color: ${({ theme }) => theme.boundWhite};
      padding: 0.6rem 2rem;
      border-radius: 22px;
      display: flex;
      align-items: center;
      opacity: 1;

      svg {
        width: 22px;
        height: 22px;
      }

      span {
        margin-left: 0.7rem;
        font-size: 1.55rem;
        font-weight: 300;
        padding-top: ${isMacOs ? 4 : 0}px;
      }
    }
  }

  h3.subtitle {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 24px;
  }

  .content {
    margin-top: 8vh;

    .info_bounders {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }
  }

  @media screen and (max-width: 560px) {
    header {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;

      div.title {
        font-size: 26px;
        min-width: 0;

        a {
          font-size: 26px;
        }
      }
    }
  }
`;

export const StyledOrderDetailsInfo = styled.div<{ index: number }>`
  margin-bottom: 4vh;
  padding-bottom: 4vh;
  border-bottom: 1px solid #ddd;
  animation: slideIn 0.35s;

  > h2 {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.boundBlue};
    font-weight: 400;
    margin-bottom: 3rem;
  }

  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 30px;

    > div {
      margin-bottom: 4.4rem;
    }
  }

  > p {
    font-size: 18px;
    font-weight: 300;
    color: ${({ theme }) => theme.grayDarkest};
    margin-bottom: 4.4rem;
  }

  @media (max-width: 880px) {
    .grid {
      display: flex;
      flex-direction: column;
    }
  }
`;
export const StyledOrderDetailsPhotoInfo = styled.div<{ index: number }>`
  margin-bottom: 4vh;
  padding-bottom: 4vh;
  border-bottom: 1px solid #ddd;
  animation: slideIn 0.35s;
  display: flex;

  .photo,
  .delivery-info {
    flex: 1;
  }

  .delivery-info > div:first-child {
    margin-bottom: 4.4rem !important;
  }

  .photo > h2 {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.boundBlue};
    font-weight: 400;
    margin-bottom: 3rem;
  }

  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 30px;

    > div {
      margin-bottom: 4.4rem;
    }
  }

  .photo > p {
    font-size: 18px;
    font-weight: 300;
    color: ${({ theme }) => theme.grayDarkest};
    margin-bottom: 4.4rem;
  }

  .delivery-info > h2 {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.boundBlue};
    font-weight: 400;
    margin-bottom: 3rem;
  }

  .delivery-info > p {
    font-size: 18px;
    font-weight: 300;
    color: ${({ theme }) => theme.grayDarkest};
    margin-bottom: 4.4rem;
  }

  @media (max-width: 880px) {
    .grid {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ImageStyled = styled.img`
  display: block;
  width: auto;
  height: auto;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 32px;
  cursor: pointer;
`;

export const ModalImage = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.58);
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeOut 0.55s;

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: center;
    width: 85vw;
    height: 85vh;
    animation: fadeIn 0.55s;

    svg {
      color: ${({ theme }) => theme.boundWhite};
    }

    .close {
      cursor: pointer;
    }

    .download-close-btn {
      display: flex;
      position: absolute;
      top: -5.5%;
      right: 0;
      gap: 10px;
    }

    #img-container {
      display: inline-block;
      overflow: auto;
      cursor: grab;

      ::-webkit-scrollbar {
        background: #fff;
        width: 14px;
        height: 14px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        border-radius: 20px;
      }

      img {
        display: block;
        transition: transform 0.4s ease-in;
        transform: translate(50% 50%);
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .slider-zoom {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      gap: 25px;

      div {
        cursor: pointer;

        :hover {
          transition: all 0.4s ease-in-out;
          opacity: 0.7;
        }
      }

      svg {
        margin: 2px 20px;
      }
    }
  }
`;

export const ImageGridStyled = styled.div`
  display: grid;
  justify-content: start;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 4.4rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const NoImageStyled = styled.div`
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
