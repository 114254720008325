import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Layout, Pagination, Loading } from 'components';
import { observer } from 'mobx-react';
import {
  IOrderResponse,
  IServiceTypeEnum,
  IOrder,
  IOrderStatusEnum,
  IOrderStatus,
  IBatchOrderStatusText,
} from 'models';
import { OrdersService } from 'services';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledOrders } from 'styles/pages/orders';
import { scrollToTop } from 'helpers';
import { getEnumKey } from 'helpers/getEnumKey';
import { useParams } from 'react-router-dom';
import { OrderInBatch } from 'components/OrderInBatch';

function getOrderStatusKey(value: string) {
  return getEnumKey<IOrderStatus>(IOrderStatusEnum, value);
}

interface IParamsBatchOrder {
  id: string;
}

export const BatchOrdersList: React.FC = observer(() => {
  const { id } = useParams() as IParamsBatchOrder;
  const [currentType, setCurrentType] = useState<IOrderStatus | undefined>(
    undefined,
  );
  const [page, setPage] = useState('1');
  const [pageTitle, setPageTitle] = useState<string | undefined>('');
  const [orders, setOrders] = useState<IOrder[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const serviceTypeValues = useMemo(
    () => [
      'All',
      ...Object.values(IOrderStatusEnum).filter(item => {
        return (
          // item !== IOrderStatusEnum.ASSIGNED_BY_ADMIN &&
          item !== IOrderStatusEnum.UNASSIGNED
        );
      }),
    ],
    [],
  );

  const serviceTypeFilters: {
    text: string;
    value?: IOrderStatus;
  }[] = useMemo(
    () =>
      serviceTypeValues.map(item => {
        return {
          value: getOrderStatusKey(item),
          text: item,
        };
      }),
    [serviceTypeValues],
  );

  const handleCorrectServiceType = useCallback(
    (orders: IOrder[]): IOrderResponse => {
      const response: IOrderResponse = {
        results: orders.filter(
          order => order.service_type !== IServiceTypeEnum.NEED,
        ),
        count: orders?.length,
        next: null,
        previous: null,
      };

      return response;
    },
    [],
  );

  // Filter by All, Cancelled, Completed, etc...
  const filterOrdersByType = useCallback(
    (orders: IOrder[]) => {
      if (currentType) {
        const filtered = orders.filter(order => order.status === currentType);

        return filtered;
      }

      return orders;
    },
    [currentType],
  );

  const getOrdersService = useCallback(() => {
    setLoading(true);

    OrdersService.getBatchById(id)
      .then(orders => {
        setPageTitle(orders?.name);
        if (orders?.orders) {
          const resp = handleCorrectServiceType(orders.orders);

          let filtered = resp.results.filter(order => {
            const keys = Object.keys(IBatchOrderStatusText);

            return !keys.includes(order.status);
          });
          filtered = filterOrdersByType(filtered);

          setOrders(filtered);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, handleCorrectServiceType, filterOrdersByType]);

  useEffect(() => {
    getOrdersService();
    setPage('1');
  }, [getOrdersService, currentType]);

  return (
    <Layout showBackArrow="/batch-orders">
      <StyledOrders id="scroll-element">
        <PageTitle>{pageTitle || 'Batch orders'}</PageTitle>

        <header>
          <div className="main-filters">
            <div className="filters">
              {serviceTypeFilters.map(item => {
                return (
                  <button
                    type="button"
                    className={currentType === item.value ? 'actived' : ''}
                    onClick={() => {
                      setCurrentType(item.value);
                      scrollToTop('scroll-element');
                    }}
                    data-testid="filter"
                    key={item.text}
                  >
                    {item.text}
                  </button>
                );
              })}
            </div>
          </div>

          {/* removed the filters */}
        </header>

        <div className="orders">
          {loading && !orders ? <Loading size="85" /> : null}

          {orders && orders?.length ? (
            <>
              {orders?.map((order, index) => (
                <OrderInBatch key={order.id} order={order} index={index} />
              ))}

              <Pagination
                elementsPerPage={20}
                onChangePage={page => {
                  getOrdersService();
                  setPage(page);
                }}
                page={Number(page) || 1}
                totalElements={orders?.length}
              />
            </>
          ) : null}

          {(!orders || orders?.length === 0) && !loading && (
            <div className="empty">No orders found</div>
          )}
        </div>
      </StyledOrders>
    </Layout>
  );
});
