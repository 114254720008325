import React, { useEffect, useCallback, useState } from 'react';
import {
  Layout,
  Loading,
  SearchInput,
  Modal,
  Pagination,
  Clients,
} from 'components';
import { scrollToTop } from 'helpers';
import { PageTitle } from 'styles/components/pageTitle';
import { CustomersService } from 'services';
import { ICustomersResponse, StatusTypeCustomers } from 'models';

import {
  EmptyMessage,
  CustomersWrapper,
  FiltersContainer,
} from 'styles/pages/customers';

enum ITypeModal {
  DENY = 'deny',
  APPROVE = 'approve',
}

enum ISelectedTabsType {
  PENDING_APPLICATION = 'PENDING_APPROVAL',
  APPROVED = 'ACTIVE',
  DENIED = 'DECLINED',
}

export const ClientsApplicants: React.FC = () => {
  const [filterTab, setFilterTab] = useState<ISelectedTabsType>(
    ISelectedTabsType.PENDING_APPLICATION,
  );
  const [customers, setCustomers] = useState<ICustomersResponse>();
  const [selectedCustomer, setSelectedCustomer] =
    useState<{ id: number; phone: string }>();
  const [page, setPage] = useState('1');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<ITypeModal>(ITypeModal.APPROVE);

  const getActiveCustomerService = useCallback(
    (searchString = search, _page = page) => {
      setLoading(true);

      CustomersService.getAllActive(searchString, _page)
        .then(customers => {
          setCustomers(customers);
        })
        .catch(() => {
          setCustomers(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search, page],
  );

  const getCustomersService = useCallback(
    (searchString = search, _page = page, status: StatusTypeCustomers) => {
      setLoading(true);

      CustomersService.getAll(searchString, _page, status)
        .then(customers => {
          setCustomers(customers);
        })
        .catch(() => {
          setCustomers(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search, page],
  );

  const handleApproveApplicant = useCallback((id: number, phone: string) => {
    CustomersService.approveCustomer(id, phone).finally(() => {
      setShowModal(false);
      scrollToTop('scroll-element');
      setFilterTab(ISelectedTabsType.APPROVED);
    });
  }, []);

  const handleRejectApplicant = useCallback((id: number, phone: string) => {
    CustomersService.rejectCustomer(id, phone).finally(() => {
      setShowModal(false);
      scrollToTop('scroll-element');
      setFilterTab(ISelectedTabsType.DENIED);
    });
  }, []);

  useEffect(() => {
    if (
      filterTab === ISelectedTabsType.PENDING_APPLICATION ||
      filterTab === ISelectedTabsType.DENIED
    ) {
      getCustomersService(search.length ? search : undefined, page, filterTab);
      return;
    }

    getActiveCustomerService();
  }, [getActiveCustomerService, getCustomersService, search, page, filterTab]);

  const handleChangeTabs = useCallback((status: ISelectedTabsType) => {
    setFilterTab(status);
    scrollToTop('scroll-element');
    setPage('1');
  }, []);

  return (
    <>
      {showModal && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          modalTitle="Are you sure?"
          size="sm"
          modalBody={
            typeModal === ITypeModal.APPROVE
              ? 'This client will be approved.'
              : 'This client will be removed from this list.'
          }
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            if (selectedCustomer) {
              if (typeModal === ITypeModal.APPROVE) {
                handleApproveApplicant(
                  selectedCustomer.id,
                  selectedCustomer.phone,
                );
              } else {
                handleRejectApplicant(
                  selectedCustomer.id,
                  selectedCustomer.phone,
                );
              }
            }
          }}
        />
      )}

      <Layout>
        <CustomersWrapper id="scroll-element">
          <div className="title-container">
            <PageTitle>Clients</PageTitle>

            <SearchInput
              onFilterAction={setSearch}
              onClearFilterAction={() => setSearch('')}
            />
          </div>

          <FiltersContainer>
            <div className="tabs">
              <span
                className={
                  filterTab === ISelectedTabsType.PENDING_APPLICATION
                    ? 'selected'
                    : ''
                }
                onClick={() =>
                  handleChangeTabs(ISelectedTabsType.PENDING_APPLICATION)
                }
              >
                Pending
              </span>

              <span
                className={
                  filterTab === ISelectedTabsType.APPROVED ? 'selected' : ''
                }
                onClick={() => handleChangeTabs(ISelectedTabsType.APPROVED)}
              >
                Approved
              </span>

              <span
                className={
                  filterTab === ISelectedTabsType.DENIED ? 'selected' : ''
                }
                onClick={() => handleChangeTabs(ISelectedTabsType.DENIED)}
              >
                Denied
              </span>
            </div>
          </FiltersContainer>

          <div className="customers-grid">
            {loading && !customers ? <Loading size="85" /> : null}

            {customers && customers.results && customers.results.length
              ? customers.results.map(customer => (
                  <Clients
                    key={customer.id}
                    data-testid="applicant"
                    {...customer}
                    onDenyButton={() => {
                      setShowModal(true);
                      setTypeModal(ITypeModal.DENY);
                      setSelectedCustomer({
                        id: customer.id,
                        phone: customer.phone_number,
                      });
                    }}
                    onApproveButton={() => {
                      setShowModal(true);
                      setTypeModal(ITypeModal.APPROVE);
                      setSelectedCustomer({
                        id: customer.id,
                        phone: customer.phone_number,
                      });
                    }}
                  />
                ))
              : null}

            {!customers?.results.length && !loading && (
              <EmptyMessage>No clients found</EmptyMessage>
            )}
          </div>

          {customers && customers?.results.length ? (
            <Pagination
              totalElements={customers.count}
              elementsPerPage={20}
              page={Number(page) || 1}
              onChangePage={page => setPage(page)}
            />
          ) : null}
        </CustomersWrapper>
      </Layout>
    </>
  );
};
