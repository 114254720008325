import styled from 'styled-components';

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;

  .index-wrapper {
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    font-size: 17px;
    color: ${({ theme }) => theme.boundBlack};
    padding: 3px 4px;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.boundRed};
      font-weight: 700;
      transition: all 0.5s;
    }
  }

  .pages-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 7px;
  }

  & svg {
    outline: none;
    cursor: pointer;
    margin: 0 15px;
  }

  span {
    font-size: 17px;
    color: ${({ theme }) => theme.boundBlack};
    padding: 3px 4px;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.boundRed};
      font-weight: 700;
      transition: all 0.5s;
    }
  }

  span.dots {
    cursor: arrow;
    pointer-events: none;
  }

  .selected-page {
    border: 1px solid ${({ theme }) => theme.boundGreen};
    background-color: ${({ theme }) => theme.boundGreen};
    color: ${({ theme }) => theme.boundWhite};

    &:hover {
      color: ${({ theme }) => theme.boundWhite};
      font-weight: inherit;
    }
  }

  @media screen and (max-width: 560px) {
    .index-wrapper {
      min-width: 0;
    }

    .pages-container {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
`;

export const PaginationIndexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;
