import { Switch, Route, Redirect } from 'react-router-dom';
import { Login, ForgotPassword, ResetPassword } from 'pages';

export const UnauthenticatedRoutes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/reset-password" exact component={ResetPassword} />
    <Redirect to="/login" />
  </Switch>
);
