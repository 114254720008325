import { toast } from 'react-toastify';
import { Bounder, Layout, Modal } from 'components';
import { PageTitle } from 'styles/components/pageTitle';
import {
  ModalImage,
  StyledOrderDetails,
  StyledOrderDetailsInfo,
  StyledOrderDetailsPhotoInfo,
} from 'styles/pages/orderDetails';
import {
  IBounder,
  IOrder,
  IOrderStatusIcons,
  IOrderStatusText,
  IDisplayTypeIcons,
  IOrderStatus,
} from 'models';
import { Link, useParams } from 'react-router-dom';
import { StyledDeliveryInfo, StyledInfo } from 'styles/components/info';
import { format } from 'date-fns';
import { useState, useEffect, useCallback, useRef } from 'react';
import { BoundersService, OrdersService } from 'services';
import { observer } from 'mobx-react';
import { ChevronRightIconIMG } from 'assets';
import { checkIfHasInformationOnText } from 'helpers';
import { getEnumKey } from 'helpers/getEnumKey';
import { HiDownload } from 'react-icons/hi';
import {
  AiOutlineClose,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from 'react-icons/ai';
import { OrderDetailsImageGrid } from 'components/OrderDetailsImageGrid';

function getOrderStatusKey(value: string) {
  return getEnumKey<IOrderStatus>(IOrderStatusText, value);
}

export const BatchOrderDetails: React.FC = observer(() => {
  const { id, batchOrderId }: { id: string; batchOrderId: string } =
    useParams();

  console.log({ batchOrderId });

  const [boundersTitle, setBoundersTitle] = useState<'Bounders' | 'Bounder'>(
    'Bounders',
  );
  const [currentOrder, setCurrentOrder] = useState<IOrder | undefined>(
    {} as IOrder,
  );
  const [selectedBounder, setSelectedBounder] = useState<string>('');
  const [bounders, setBounders] = useState<IBounder[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalReassign, setShowModalReassign] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [zoomProportion, setZoomProportion] = useState<number>(1);

  const imgRef = useRef<HTMLDivElement>(null);
  const stateRef = useRef(0);
  stateRef.current = zoomProportion;

  const getCurrentOrder = useCallback(() => {
    OrdersService.getById(id).then(setCurrentOrder).catch(console.error);
  }, [id]);

  const getOnlineBounders = useCallback(async () => {
    try {
      const boundersOnline = await BoundersService.getAll({ online: true });
      setBounders(boundersOnline.results);
    } catch (error) {
      setBounders([]);
    }
  }, []);

  const getOrderBounder = useCallback(
    async (id: number, alreadyExisting: boolean) => {
      try {
        const bounder = await BoundersService.getBounderById(id);
        if (alreadyExisting) {
          const onlineBounders = await BoundersService.getAll({ online: true });
          const alreadyOnlineBounder = onlineBounders.results.find(
            onlineBounder => onlineBounder.id === bounder.id,
          );

          if (!alreadyOnlineBounder) {
            setBounders(prevState => prevState.concat([bounder]));
          }

          return;
        }

        setBounders([bounder]);
      } catch (error) {
        setBounders([]);
      }
    },
    [],
  );

  const handleUnassignBounderToOrder = useCallback(
    (orderId: string, bounderId: string) => {
      setLoading(true);

      OrdersService.unassignBounderToOrder(bounderId, orderId).then(() => {
        toast.success('Bounder Reassign to this delivery');

        setTimeout(() => {
          setShowModalReassign(false);
          setLoading(false);
          window.location.reload();
        }, 2000);
      });
    },
    [],
  );

  const handleAssignBounderToOrder = useCallback(
    (orderId: string, bounderId: string) => {
      setLoading(true);

      if (currentOrder && !!currentOrder.driver_id) {
        OrdersService.unassignBounderToOrder(
          currentOrder.driver_id.toString(),
          orderId,
        ).then(() => {
          setTimeout(() => {
            OrdersService.assignBounderToOrder(bounderId, orderId).then(() => {
              toast.success('Bounder Assign to this delivery');

              setTimeout(() => {
                setShowModal(false);
                setLoading(false);
                window.location.reload();
              }, 2000);
            });
          }, 2000);
        });
      } else {
        OrdersService.assignBounderToOrder(bounderId, orderId).then(() => {
          toast.success('Bounder Assign to this delivery');

          setTimeout(() => {
            setShowModal(false);
            setLoading(false);
            window.location.reload();
          }, 2000);
        });
      }
    },
    [currentOrder],
  );

  useEffect(() => {
    getCurrentOrder();
  }, [getCurrentOrder]);

  const handleSetImage = (imageURL: string) => {
    setShowImageModal(true);
    setSelectedImage(imageURL);
  };

  const onWheelHandler = (event: WheelEvent) => {
    if (event.deltaY < 0 && stateRef.current < 2) {
      setZoomProportion(value => value * 2);
    }

    if (event.deltaY > 0 && stateRef.current !== 0.5) {
      setZoomProportion(value => value / 2);
    }
  };

  const eventHandlerListener = useCallback(() => {
    const node = imgRef.current;
    node?.addEventListener('wheel', onWheelHandler, {
      passive: false,
    });

    return () => {
      node?.removeEventListener('wheel', onWheelHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showImageModal]);

  useEffect(() => {
    eventHandlerListener();
  }, [eventHandlerListener]);

  useEffect(() => {
    if (currentOrder?.status) {
      switch (currentOrder?.status) {
        case getOrderStatusKey(IOrderStatusText.SCHEDULED):
        case getOrderStatusKey(IOrderStatusText.READY_FOR_PICKUP):
        case getOrderStatusKey(IOrderStatusText.ACCEPTED_BY_DRIVER):
        case getOrderStatusKey(IOrderStatusText.ASSIGNED_BY_ADMIN):
        case getOrderStatusKey(IOrderStatusText.INBOUND):
          setBoundersTitle('Bounders');
          getOnlineBounders();

          if (currentOrder.driver_id) {
            getOrderBounder(currentOrder.driver_id, true);
          }
          break;
        default:
          setBoundersTitle('Bounder');

          if (currentOrder.driver_id) {
            getOrderBounder(currentOrder.driver_id, false);
          }
          break;
      }
    }
  }, [
    currentOrder?.status,
    currentOrder?.driver_id,
    currentOrder?.id,
    getOnlineBounders,
    getOrderBounder,
  ]);

  if (currentOrder && !currentOrder.id) {
    return null;
  }

  return (
    <>
      {showModal && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          loading={loading}
          modalTitle="Are you sure?"
          size="sm"
          modalBody="This bounder will be assigned to this delivery."
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            if (currentOrder) {
              handleAssignBounderToOrder(
                currentOrder?.id.toString(),
                selectedBounder,
              );
            }
          }}
        />
      )}

      {showModalReassign && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          loading={loading}
          modalTitle="Are you sure?"
          size="sm"
          modalBody="This bounder will be unassigned from this delivery."
          onCancel={() => setShowModalReassign(false)}
          onConfirm={() => {
            if (currentOrder) {
              handleUnassignBounderToOrder(
                currentOrder.id.toString(),
                selectedBounder,
              );
            }
          }}
        />
      )}

      {showImageModal && (
        <ModalImage>
          <div className="modal">
            <div id="img-container" ref={imgRef}>
              <img
                src={selectedImage}
                alt="zoom"
                style={{
                  transform: `scale(${zoomProportion}) ${
                    zoomProportion > 1 ? `translate(15.25vw, 15.25vh)` : ``
                  }`,
                  margin:
                    zoomProportion > 1 ? `${zoomProportion * 50}px 0` : '0',
                }}
              />
            </div>

            <div className="download-close-btn">
              <a
                href={selectedImage}
                download
                className="download"
                data-tip="Donwload"
                data-place="bottom"
                target="_blank"
                rel="noreferrer"
              >
                <HiDownload size={30} />
              </a>

              <AiOutlineClose
                size={30}
                onClick={() => {
                  setShowImageModal(false);
                  setSelectedImage('');
                  setZoomProportion(1);
                }}
                className="close"
                data-tip="Close"
                data-place="bottom"
              />
            </div>

            <div className="slider-zoom">
              <div
                onClick={() => {
                  if (zoomProportion !== 0.5) {
                    setZoomProportion(value => value / 2);
                  }
                }}
                data-tip="Zoom Out"
                data-place="bottom"
              >
                <AiOutlineZoomOut size={30} />
              </div>

              <div
                onClick={() => {
                  if (zoomProportion < 2) {
                    setZoomProportion(value => value * 2);
                  }
                }}
                data-tip="Zoom In"
                data-place="bottom"
              >
                <AiOutlineZoomIn size={30} />
              </div>
            </div>
          </div>
        </ModalImage>
      )}

      <Layout showBackArrow={`/batch-orders/${batchOrderId}`}>
        {!currentOrder ? (
          <PageTitle>Order not found</PageTitle>
        ) : (
          <StyledOrderDetails status={currentOrder?.status}>
            <header>
              <div className="title">
                <Link to={`/batch-orders/${batchOrderId}`}>Orders</Link>
                <ChevronRightIconIMG />
                <PageTitle>Details</PageTitle>
              </div>
              <h2 className="state">
                {IOrderStatusIcons[currentOrder.status]}
                <span>{IOrderStatusText[currentOrder.status]}</span>
              </h2>
            </header>

            <div className="content">
              <StyledOrderDetailsInfo index={0}>
                <h2>Service</h2>
                <div className="grid">
                  <StyledInfo>
                    <h2>Timestamp</h2>
                    <span>
                      {checkIfHasInformationOnText(
                        format(
                          new Date(currentOrder.created_at),
                          'MM/dd/yyyy hh:mm:ss aaa',
                        ),
                      )}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Ready At</h2>
                    <span>
                      {checkIfHasInformationOnText(
                        format(
                          new Date(currentOrder.pickup_time),
                          'MM/dd/yyyy hh:mm:ss aaa',
                        ),
                      )}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Service Type</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.service_type)}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Reference Number</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.reference)}
                    </span>
                  </StyledInfo>
                </div>
              </StyledOrderDetailsInfo>

              <StyledOrderDetailsInfo index={1}>
                <h2>Delivery</h2>
                <div className="grid">
                  <StyledInfo>
                    <h2>
                      Pick up (
                      {checkIfHasInformationOnText(
                        currentOrder.pick_up.address_type,
                      )}
                      )
                    </h2>
                    <span>
                      {checkIfHasInformationOnText(
                        currentOrder.pick_up.address,
                      )}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>
                      Drop off (
                      {checkIfHasInformationOnText(
                        currentOrder.drop_off.address_type,
                      )}
                      )
                    </h2>
                    <span>
                      {checkIfHasInformationOnText(
                        currentOrder.drop_off.address,
                      )}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Destination Type</h2>
                    <span>
                      {checkIfHasInformationOnText(
                        currentOrder.drop_off.address_type,
                      )}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Number of Items</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.num_items)}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>
                      {currentOrder.item_types &&
                      currentOrder.item_types.length > 1
                        ? 'Item Types'
                        : 'Item Type'}
                    </h2>
                    <span>
                      {checkIfHasInformationOnText(
                        currentOrder.item_types &&
                          currentOrder.item_types.length &&
                          currentOrder.item_types.join(', '),
                      )}
                      {IDisplayTypeIcons[currentOrder.display_type]}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Price</h2>
                    <span>
                      {currentOrder.price && '$'}
                      {checkIfHasInformationOnText(currentOrder.price)}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Sender</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.sender.name)}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Recipient</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.recipient.name)}
                    </span>
                  </StyledInfo>
                </div>
              </StyledOrderDetailsInfo>

              <StyledOrderDetailsPhotoInfo index={2}>
                <div className="photo">
                  <h2>Photo confirmation</h2>

                  <OrderDetailsImageGrid
                    currentOrder={currentOrder}
                    handleSetImage={handleSetImage}
                  />
                </div>
                <div className="delivery-info">
                  <h2>How it was delivered</h2>

                  <StyledDeliveryInfo>
                    <h2>Drop off method</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.dropoff_method)}
                    </span>
                  </StyledDeliveryInfo>

                  {currentOrder.dropoff_person_name && (
                    <StyledDeliveryInfo>
                      <h2>Delivered to</h2>
                      <span>
                        {checkIfHasInformationOnText(
                          currentOrder.dropoff_person_name,
                        )}
                      </span>
                    </StyledDeliveryInfo>
                  )}
                </div>
              </StyledOrderDetailsPhotoInfo>

              <StyledOrderDetailsInfo index={2}>
                <h2>Contact</h2>

                <h3 className="subtitle">Sender</h3>
                <div className="grid">
                  <StyledInfo>
                    <h2>Phone Number</h2>
                    <span>
                      {checkIfHasInformationOnText(
                        currentOrder.customer.phone_number,
                      )}
                    </span>
                  </StyledInfo>

                  <StyledInfo>
                    <h2>Email</h2>
                    <span>
                      {checkIfHasInformationOnText(currentOrder.customer.email)}
                    </span>
                  </StyledInfo>
                </div>

                <h3 className="subtitle">Recipient</h3>
                <div className="grid">
                  <StyledInfo>
                    <h2>Phone Number</h2>
                    <span>
                      {checkIfHasInformationOnText(
                        currentOrder.recipient.phone_number,
                      )}
                    </span>
                  </StyledInfo>
                </div>
              </StyledOrderDetailsInfo>

              {currentOrder?.notes && (
                <StyledOrderDetailsInfo index={3}>
                  <h2>Notes</h2>
                  <p>{currentOrder.notes}</p>
                </StyledOrderDetailsInfo>
              )}

              <StyledOrderDetailsInfo index={4}>
                <h2>{boundersTitle}</h2>
                {bounders && !!bounders.length && (
                  <section className="info_bounders">
                    {bounders.map((bounder, index) => (
                      <Bounder
                        key={bounder.id}
                        {...bounder}
                        statusOrder={currentOrder.status}
                        isOrderDetails
                        driverId={currentOrder.driver_id}
                        index={index}
                        onClickAssign={() => {
                          setShowModal(true);
                          setSelectedBounder(bounder.id.toString());
                        }}
                        onClickReassign={() => {
                          setShowModalReassign(true);
                          setSelectedBounder(bounder.id.toString());
                        }}
                      />
                    ))}
                  </section>
                )}

                {!bounders?.length && <p>No bounder</p>}
              </StyledOrderDetailsInfo>
            </div>
          </StyledOrderDetails>
        )}
      </Layout>
    </>
  );
});
