import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

import {
  BackgroundBlurWrapper,
  ModalWrapper,
  ModalBody,
  ModalButtonsComponent,
  ModalTitle,
} from 'styles/components/promocodeModal';
import { Button } from 'components';
import { PromocodeButton } from 'styles/components/promocodeButton';
import { IDiscountTypeText, IPromocode } from 'models/IPromocode';
import { PromocodeService } from 'services';
import { PromocodeInput } from './PromocodeInput';
import { PromocodeTextarea } from './PromocodeTextarea';

interface IModal {
  size: 'sm' | 'bg';
  modalTitle: string;
  cancelButtonText?: string;
  confirmButtonText: string;
  loading?: boolean;
  onCancel?: () => void;
  onGetPromocodes: () => void;
  editPromocode: IPromocode | undefined;
}

export const PromocodeModal: React.FC<IModal> = ({
  size,
  confirmButtonText,
  cancelButtonText,
  modalTitle,
  loading,
  onCancel,
  onGetPromocodes,
  editPromocode,
}) => {
  const [id, setId] = useState<number>(-1);
  const [code, setCode] = useState<string>('');
  const [typeValue, setTypeValue] = useState<string>('');
  const [type, setType] = useState<string>(IDiscountTypeText.PERCENTAGE);
  const [expirationDate, setExpirationDate] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorType, setErrorType] = useState<string>('');
  const [errorExpirationDate] = useState<string>('');
  const [errorDescription, setErrorDescription] = useState<string>('');
  const [loadingPromocode, setLoadingPromocode] = useState(false);
  const isEdit = !!editPromocode?.id;

  const handleCreatePromocode = useCallback(() => {
    let hasErrors = false;
    console.log(loadingPromocode, id);
    if (!code) {
      setErrorCode('Name should be provided');
      hasErrors = true;
    }
    if (!typeValue) {
      setErrorType('Type/Value should be provided');
      hasErrors = true;
    }

    const promocode = {
      id: editPromocode?.id ? editPromocode.id : null,
      code,
      discount_value: typeValue,
      discount_type: type,
      expiration_datetime: expirationDate,
      description,
    };

    setLoadingPromocode(true);

    if (!hasErrors) {
      if (editPromocode?.id) {
        PromocodeService.putPromocode(editPromocode.id, promocode)
          .then(() => console.log('Promocode created successfully'))
          .catch(() => {
            console.log('Promocode failed to create');
          })
          .finally(() => {
            setLoadingPromocode(false);
            if (onCancel && onGetPromocodes) {
              onCancel();
              onGetPromocodes();
            }
          });
      } else {
        PromocodeService.createPromocode(promocode)
          .then(() => console.log('Promocode created successfully'))
          .catch(() => {
            console.log('Promocode failed to create');
          })
          .finally(() => {
            setLoadingPromocode(false);
            if (onCancel && onGetPromocodes) {
              onCancel();
              onGetPromocodes();
            }
          });
      }
    }
  }, [
    id,
    loadingPromocode,
    code,
    typeValue,
    editPromocode?.id,
    type,
    expirationDate,
    description,
    onCancel,
    onGetPromocodes,
  ]);

  useEffect(() => {
    if (editPromocode) {
      let expirationDate = new Date(editPromocode.expiration_datetime);
      const offset = expirationDate.getTimezoneOffset();
      expirationDate = new Date(expirationDate.getTime() - offset * 60 * 1000);
      const formatDate = expirationDate.toISOString().split('T')[0];

      setId(editPromocode.id);
      setCode(editPromocode.code);
      setTypeValue(editPromocode.discount_value);
      setType(editPromocode.discount_type);
      setExpirationDate(formatDate);
      setDescription(editPromocode.description);
    }
  }, [editPromocode]);

  return (
    <BackgroundBlurWrapper>
      <ModalWrapper size={size}>
        <ModalTitle>{isEdit ? 'Edit' : modalTitle}</ModalTitle>

        <ModalBody>
          <PromocodeInput
            errorText={errorCode}
            onClick={() => setErrorCode('')}
            type="text"
            placeholder="Promocode name*"
            value={code}
            onChange={event => setCode(event.target.value)}
          />
          <div style={{ display: 'flex' }}>
            <PromocodeInput
              errorText={errorType}
              onClick={() => setErrorType('')}
              type="number"
              min={0}
              max={type === IDiscountTypeText.PERCENTAGE ? 100 : 99999}
              placeholder="Number/Type"
              value={typeValue}
              onChange={event => setTypeValue(event.target.value)}
            />
            <PromocodeButton
              className={
                type === IDiscountTypeText.PERCENTAGE ? 'active' : undefined
              }
              onClick={() => setType(IDiscountTypeText.PERCENTAGE)}
            >
              %
            </PromocodeButton>
            <PromocodeButton
              className={
                type === IDiscountTypeText.FIXED ? 'active' : undefined
              }
              onClick={() => setType(IDiscountTypeText.FIXED)}
            >
              $
            </PromocodeButton>
          </div>
          <PromocodeInput
            errorText={errorExpirationDate}
            onClick={() => setExpirationDate('')}
            type="date"
            min="2022-01-01"
            max="2032-12-31"
            placeholder="MM/DD/YYYY"
            value={expirationDate}
            onChange={event => setExpirationDate(event.target.value)}
          />
          <PromocodeTextarea
            errorText={errorDescription}
            onClick={() => setErrorDescription('')}
            placeholder="Description"
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
        </ModalBody>

        <ModalButtonsComponent>
          {!loading ? (
            <>
              <Button
                style={{ height: '55px', fontSize: '1.75rem' }}
                text={confirmButtonText}
                typeButton="primary"
                onClick={handleCreatePromocode}
              />

              {cancelButtonText && (
                <Button
                  style={{ height: '55px', fontSize: '1.75rem' }}
                  text={cancelButtonText}
                  typeButton="secondary"
                  onClick={onCancel}
                />
              )}
            </>
          ) : (
            <AiOutlineLoading size={30} />
          )}
        </ModalButtonsComponent>
      </ModalWrapper>
    </BackgroundBlurWrapper>
  );
};
