export interface IBounder {
  id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  photo_url: string;
  orderId: string;
  date_of_birth: string;
  ssn?: string;
  zip_code?: string;
  email?: string;
  birthday?: string;
  status?: IBounderStatusType;
  vehicles: Array<{
    model: string;
    vehicle_type: string;
    year: number;
    color: string;
    is_primary: boolean;
    make: string;
  }>;
  driver_license: {
    state_issued: string;
    license_number: string;
    expiration_date: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    address: string;
    uploads: Array<string>;
  };
  driver_registration: {
    make: string;
    model: string;
    year: number;
    effective_date: string;
    expiration_date: string;
    registered_owner: string;
    registered_address: string;
    uploads: Array<string>;
  };
  driver_insurance: {
    policy_number: string;
    named_insured: string;
    effective_date: string;
    expiration_date: string;
    vin: string;
    make: string;
    model: string;
    year: number;
    uploads: Array<string>;
  };
}

export enum IBounderCounterEnum {
  TOTAL_COUNT = 'total_count',
  ONLINE_COUNT = 'online_count',
  OFFLINE_COUNT = 'offline_count',
}

export type IBounderCounter = Record<IBounderCounterEnum, number>;

export interface IBounderResponse extends IBounderCounter {
  count: number;
  next: string | null;
  previous: string | null;
  results: IBounder[];
}

export enum IBounderStatusEnum {
  PENDING_APPROVAL = 'Pending approval',
  DECLINED = 'Denied application',
  PENDING_COMPLETION = 'Pending information',
  PENDING_VERIFICATION = 'Pending documents',
  DECLINED_VERIFICATION = 'Denied verification',
  ACTIVE = 'Active',
}

export type IBounderStatusType = keyof typeof IBounderStatusEnum;

export enum IBounderStatusText {
  PENDING = 'Pending documents',
  CHECK = 'Check the documents',
}

export enum IBoundersFilterEnum {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}
