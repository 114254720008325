import axios from 'config/axiosConfig';
import { toast } from 'react-toastify';
import {
  ICustomersResponse,
  StatusTypeCustomers,
  ICustomersService,
} from 'models';

export class CustomersServiceClass implements ICustomersService {
  async getAllActive(
    queryString?: string,
    page?: string,
    status?: StatusTypeCustomers,
  ): Promise<ICustomersResponse> {
    const customers = (
      await axios.get('/dashboard/customers/', {
        params: {
          search: queryString,
          page,
          status,
        },
      })
    ).data;

    if (customers.results.length) {
      return Promise.resolve(customers);
    }

    toast.error("There's no customer data");
    return Promise.reject();
  }

  async getAll(
    queryString?: string,
    page?: string,
    status?: StatusTypeCustomers,
  ): Promise<ICustomersResponse> {
    const customers = (
      await axios.get('/dashboard/customers/applicants/', {
        params: {
          search: queryString,
          page,
          status,
        },
      })
    ).data;

    if (customers.results.length) {
      return Promise.resolve(customers);
    }

    toast.error("There's no customer data");
    return Promise.reject();
  }

  async approveCustomer(id: number, phone: string): Promise<void> {
    try {
      await axios.post(`/dashboard/customers/applicants/${id}/approve/`, {
        phone_number: phone,
      });

      toast.success('Customer approved');
    } catch (err) {
      toast.error('Error approving customer');
    }
  }

  async rejectCustomer(id: number, phone: string): Promise<void> {
    try {
      await axios.post(`/dashboard/customers/applicants/${id}/decline/`, {
        phone_number: phone,
      });

      toast.success('Customer rejected');
    } catch (err) {
      toast.error('Error rejecting customer');
    }
  }
}

export default new CustomersServiceClass();
