import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'components';
import { checkIfValidEmail } from 'helpers';

import { ForgotPasswordContainer } from 'styles/pages/ForgotPassword';
import { DefaultWrapper, SubTitle, Title } from 'styles/components/defaut';

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [formSend, setFormSend] = useState<boolean>(false);

  const handleEmailVerify = useCallback(() => {
    if (!checkIfValidEmail(email)) {
      setEmailError('Invalid Email');
      return;
    }

    setFormSend(true);
  }, [email]);

  const returnToLoginPage = useCallback(() => {
    history.push('/login');
  }, [history]);

  return (
    <DefaultWrapper>
      {!formSend ? (
        <ForgotPasswordContainer>
          <Title className="title-reset">Forgot your password?</Title>
          <SubTitle className="subtitle-reset">
            We’ll send you an email with a link to reset your password.
          </SubTitle>

          <div className="wrapper">
            <Input
              errorText={emailError}
              onClick={() => setEmailError('')}
              type="email"
              placeholder="Email address"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />

            <Button
              className="btn-send"
              buttonSize="bg"
              text="Send"
              typeButton="primary"
              onClick={handleEmailVerify}
            />
          </div>
        </ForgotPasswordContainer>
      ) : (
        <ForgotPasswordContainer>
          <Title className="title-confirm">Check your inbox</Title>
          <SubTitle className="subtitle-confirm">
            We sent a reset password link to <span>{email}</span>.
          </SubTitle>

          <div>
            <Button
              buttonSize="bg"
              text="Done"
              typeButton="primary"
              onClick={returnToLoginPage}
            />
          </div>
        </ForgotPasswordContainer>
      )}
    </DefaultWrapper>
  );
};
