import axios from 'config/axiosConfig';
import { toast } from 'react-toastify';
import { IPromocodeBody, IPromocodeService } from 'models/IPromocodeService';
import { IPromocode, IPromocodeResponse } from 'models/IPromocode';
import { AxiosError } from 'axios';

interface axiosError {
  code: string;
}
export class PromocodesServiceClass implements IPromocodeService {
  async getById(id: number): Promise<IPromocode | undefined> {
    const findPromocode = (await axios.get(`/dashboard/promo-codes/${id}/`))
      .data;

    if (findPromocode) {
      return Promise.resolve(findPromocode);
    }

    toast.error("There's no promocode with this id");
    return Promise.reject();
  }

  async getAll(page?: string): Promise<IPromocodeResponse> {
    const promocodes = (
      await axios.get(`/dashboard/promo-codes/`, {
        params: {
          page,
        },
      })
    ).data;

    if (promocodes) {
      return Promise.resolve(promocodes);
    }

    toast.error('No promocodes found');
    return Promise.resolve(promocodes);
  }

  async createPromocode(body: IPromocodeBody): Promise<IPromocode> {
    const {
      code,
      discount_type,
      discount_value,
      expiration_datetime,
      description,
    } = body;
    try {
      const promocode = await axios.post(`/dashboard/promo-codes/`, {
        code,
        discount_type,
        discount_value,
        expiration_datetime,
        description,
      });

      if (!promocode.data) {
        const data = promocode as unknown as AxiosError<axiosError>;
        throw new Error(data?.response?.data?.code);
      }
      toast.success('Promocode created successfully!');
      return Promise.resolve(promocode.data);
    } catch (error) {
      toast.error(`Error creating promocode: ${error}`);
      return Promise.reject();
    }
  }

  async patchPromocode(id: number, body: IPromocodeBody): Promise<IPromocode> {
    const {
      code,
      discount_type,
      discount_value,
      expiration_datetime,
      description,
    } = body;

    try {
      const promocode = (
        await axios.patch(`/dashboard/promo-codes/${id}`, {
          code,
          discount_type,
          discount_value,
          expiration_datetime,
          description,
        })
      ).data;

      toast.success('Promocode updated successfully!');
      return Promise.resolve(promocode);
    } catch (error) {
      toast.error('Error updating promocode');
      return Promise.reject();
    }
  }

  async putPromocode(id: number, body: IPromocodeBody): Promise<IPromocode> {
    const {
      code,
      discount_type,
      discount_value,
      expiration_datetime,
      description,
    } = body;

    try {
      const promocode = (
        await axios.put(`/dashboard/promo-codes/${id}`, {
          code,
          discount_type,
          discount_value,
          expiration_datetime,
          description,
        })
      ).data;

      toast.success('Promocode updated successfully!');
      return Promise.resolve(promocode);
    } catch (error) {
      toast.error('Error updating promocode');
      return Promise.reject();
    }
  }

  async deletePromocode(id: number): Promise<string> {
    try {
      await axios.delete(`/dashboard/promo-codes/${id}`);

      toast.success('Promocode deleted successfully!');
      return Promise.resolve('Promocode deleted successfully');
    } catch (error) {
      toast.error('Error deleting promocode');
      return Promise.reject();
    }
  }
}

export const factoryPromocodesService = (): PromocodesServiceClass =>
  new PromocodesServiceClass();

export default factoryPromocodesService();
