import React, { useEffect, useState, useMemo } from 'react';
import { Layout, Bounder, Loading, Pagination, SearchInput } from 'components';
import { PageTitle } from 'styles/components/pageTitle';
import { BoundersService } from 'services';
import {
  IBounderResponse,
  IBoundersFilterEnum,
  IBounderCounterEnum,
} from 'models';
import { BoundersWrapper, EmptyMessage } from 'styles/pages/bounders';

export const Bounders: React.FC = () => {
  const [originalBounders, setOriginalBounders] = useState<IBounderResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState('1');
  const [search, setSearch] = useState('');
  const [online, setOnline] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setLoading(true);

    BoundersService.getAll({ page, online, search })
      .then(setOriginalBounders)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [online, page, search]);

  const boundersFilterOptions = useMemo(
    () => ['All', ...Object.values(IBoundersFilterEnum)],
    [],
  );

  function getStatusFilter(item: string) {
    let status: boolean | undefined;

    switch (item) {
      case IBoundersFilterEnum.ONLINE:
        status = true;
        break;

      case IBoundersFilterEnum.OFFLINE:
        status = false;
        break;

      default:
        status = undefined;
    }

    return status;
  }

  function getCounterFilter(item: string): IBounderCounterEnum {
    let counter: IBounderCounterEnum;
    switch (item) {
      case IBoundersFilterEnum.ONLINE:
        counter = IBounderCounterEnum.ONLINE_COUNT;
        break;

      case IBoundersFilterEnum.OFFLINE:
        counter = IBounderCounterEnum.OFFLINE_COUNT;
        break;

      default:
        counter = IBounderCounterEnum.TOTAL_COUNT;
    }

    return counter;
  }

  return (
    <Layout>
      <BoundersWrapper id="scroll-element">
        <div className="title-container">
          <div className="main">
            <PageTitle>Bounders</PageTitle>
          </div>

          <div className="online-filters">
            {boundersFilterOptions.map(item => {
              const status = getStatusFilter(item);

              const counter = getCounterFilter(item);

              return (
                <span
                  onClick={() => {
                    setOnline(status);
                    setPage('1');
                  }}
                  className={`${online === status && 'actived'}`}
                  key={item}
                >
                  {item} ({originalBounders && originalBounders[counter]})
                </span>
              );
            })}
          </div>

          <SearchInput
            onFilterAction={setSearch}
            onClearFilterAction={() => setSearch('')}
          />
        </div>

        <div className="bounder-grid">
          {loading ? (
            <div className="loading">
              <Loading size="85" />
            </div>
          ) : (
            originalBounders &&
            originalBounders.results &&
            originalBounders.results.length > 0 &&
            originalBounders.results.map((bounder, index) => (
              <Bounder
                key={bounder.id}
                index={index}
                isOrderDetails={false}
                {...bounder}
              />
            ))
          )}

          {!originalBounders?.results.length && !loading && (
            <EmptyMessage>No Bounders found</EmptyMessage>
          )}
        </div>

        {originalBounders?.results && originalBounders.results.length ? (
          <Pagination
            elementsPerPage={20}
            totalElements={originalBounders.count}
            onChangePage={setPage}
          />
        ) : null}
      </BoundersWrapper>
    </Layout>
  );
};
