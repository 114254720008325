import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { RiArrowDropLeftLine, RiArrowDropRightLine } from 'react-icons/ri';
import { scrollToTop } from 'helpers';

import {
  PaginationContainer,
  PaginationIndexWrapper,
} from 'styles/components/pagination';

interface IPagination {
  totalElements: number;
  elementsPerPage: number;
  onChangePage: (page: string) => void;
  page?: number;
}

export const Pagination: React.FC<IPagination> = ({
  totalElements,
  onChangePage,
  elementsPerPage,
  page,
}) => {
  const [selectedPage, setSelectedPage] = useState<number>(page || 1);
  const numberOfPages = Math.ceil(totalElements / elementsPerPage);
  const isMobile = window.screen.width <= 560;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    setSelectedPage(page || 1);
  }, [page]);

  const PaginationItem = useCallback(
    ({ index }) => {
      return (
        <div
          key={index}
          className={`index-wrapper ${
            index + 1 === selectedPage ? 'selected-page' : selectedPage
          }`}
          onClick={() => {
            setSelectedPage(index + 1);
            onChangePage((index + 1).toString());
            scrollToTop('scroll-element');
          }}
        >
          {index + 1}
        </div>
      );
    },
    [onChangePage, selectedPage],
  );

  const paginationIndex = useMemo(() => {
    const array = Array.from(Array(numberOfPages).keys()).map((_, i) => i);

    if (array.length > 10) {
      const firstIndex = array[0];
      const lastIndex = array[array.length - 1];

      /*  selectedPage is smaller than firstIndex + 5,
          so it will show ... between indexes and lastIndex
      */
      if (selectedPage < firstIndex + 7) {
        const indexes = array.slice(firstIndex, 9);
        indexes.push(lastIndex);

        return indexes.map(index => {
          return (
            <PaginationIndexWrapper key={index}>
              {index === lastIndex && <span className="dots">...</span>}
              <PaginationItem index={index} />
            </PaginationIndexWrapper>
          );
        });
      }

      /*  selectedPage is greater than lastIndex - 6,
          so it will show ... between firstIndex and indexes
      */
      if (selectedPage > lastIndex - 6) {
        const indexes = array.slice(-9, lastIndex + 1);
        indexes.unshift(firstIndex);

        return indexes.map(index => {
          return (
            <PaginationIndexWrapper key={index}>
              <PaginationItem index={index} />
              {index === firstIndex && <span className="dots">...</span>}
            </PaginationIndexWrapper>
          );
        });
      }

      /*  Index is between the first and last indexes,
          and is greater than firstIndex + 9
          and smaller than lastIndex -7,
          so it will show ... between first and last indexes
      */
      const indexes = array.slice(selectedPage - 4, selectedPage + 3);
      indexes.unshift(firstIndex);
      indexes.push(lastIndex);

      return indexes.map(index => (
        <PaginationIndexWrapper key={index}>
          {index === lastIndex && <span className="dots">...</span>}
          <PaginationItem index={index} />
          {index === firstIndex && <span className="dots">...</span>}
        </PaginationIndexWrapper>
      ));
    }

    return array.map(index => {
      return <PaginationItem index={index} key={index} />;
    });
  }, [PaginationItem, numberOfPages, selectedPage]);

  return (
    <PaginationContainer>
      {isMobile ? (
        <div className="pages-container">{paginationIndex}</div>
      ) : (
        <>
          <RiArrowDropLeftLine
            size={35}
            data-tip="Previous Page"
            data-place="bottom"
            style={{
              cursor: `${selectedPage !== 1 ? 'pointer' : 'not-allowed'}`,
            }}
            onClick={() => {
              if (selectedPage !== 1) {
                setSelectedPage(value => value - 1);
                onChangePage((selectedPage - 1).toString());
                scrollToTop('scroll-element');
              }
            }}
          />

          <div className="pages-container">{paginationIndex}</div>

          <RiArrowDropRightLine
            size={35}
            style={{
              cursor: `${
                selectedPage < numberOfPages ? 'pointer' : 'not-allowed'
              }`,
            }}
            data-tip="Next Page"
            data-place="bottom"
            onClick={() => {
              if (selectedPage < numberOfPages) {
                setSelectedPage(value => value + 1);
                onChangePage((selectedPage + 1).toString());
                scrollToTop('scroll-element');
              }
            }}
          />
        </>
      )}
    </PaginationContainer>
  );
};
