/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Layout } from 'components';
import { observer } from 'mobx-react';
import { INotification } from 'models/INotification';
import { useEffect, useState, useCallback } from 'react';

import { NotificationsService } from 'services';
import { NotificationsStorage } from 'storage';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledNotificationsPage } from 'styles/pages/notifications';

export const Notifications: React.FC = observer(() => {
  const [page, setPage] = useState(1);

  const loadNotifications = useCallback(async (_page?: number) => {
    const data = await NotificationsService.getAll({ page: _page });
    return data;
  }, []);

  useEffect(() => {
    loadNotifications(page).then(response => {
      if (page === 1) {
        NotificationsStorage.setNotifications(response.results);
      } else {
        NotificationsStorage.setNotifications([
          ...NotificationsStorage.notifications,
          ...response.results,
        ]);
      }
      NotificationsStorage.next = !!response?.next;
    });
  }, [page, loadNotifications]);

  const handleClickNotification = useCallback(
    async (notification: INotification) => {
      try {
        await NotificationsService.markAsReadNotification([notification.id]);

        NotificationsStorage.refreshNotifications(notification);
      } catch (err) {
        console.error(err);
      }
    },
    [],
  );

  return (
    <Layout>
      <StyledNotificationsPage
        onScroll={e => {
          const bottom =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
            e.currentTarget.clientHeight;
          if (bottom && NotificationsStorage.next) {
            setPage(page + 1);
          }
        }}
      >
        <header>
          <PageTitle>Notifications</PageTitle>
        </header>
        <div className="container">
          <ul className="list">
            {NotificationsStorage.notifications.map(notification => (
              <li
                key={notification.id}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleClickNotification(notification)}
              >
                <div
                  className={`content ${
                    notification.read_at === null && 'new'
                  }`}
                >
                  <p>{notification.content}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </StyledNotificationsPage>
    </Layout>
  );
});
