import React from 'react';
import { useHistory } from 'react-router-dom';
import { IBounder, IOrderStatus } from 'models';
import { Button } from 'components';
import { Avatar2, DiamondFillIconIMG } from 'assets';
import { phoneMask } from 'helpers';
import {
  BounderContainer,
  StyledAvatar,
  StatusBounder,
} from 'styles/components/bounder';

interface Props {
  statusOrder?: IOrderStatus;
  onClickAssign?: () => void;
  onClickReassign?: () => void;
  isOrderDetails: boolean;
  driverId?: number | null;
  index?: number;
}

const BounderDelivery: React.FC<{
  onClickAssign: () => void;
  onClickReassign: () => void;
  statusOrder: IOrderStatus;
  isAssignToThisBounder: boolean;
}> = ({
  onClickAssign,
  onClickReassign,
  statusOrder,
  isAssignToThisBounder,
}) => {
  const statusWithAssignBtn = [
    'SCHEDULED',
    'READY_FOR_PICKUP',
    'ACCEPTED_BY_DRIVER',
    'ASSIGNED_BY_ADMIN',
    'INBOUND',
  ];

  return (
    <>
      {statusWithAssignBtn.includes(statusOrder) && (
        <>
          {isAssignToThisBounder ? (
            <div className="direction-span">
              <DiamondFillIconIMG />
              <span>Assigned</span>
            </div>
          ) : (
            <div className="direction-span" />
          )}

          {isAssignToThisBounder ? (
            <Button
              text="Reassign"
              typeButton="primary"
              fontSize={16}
              onClick={onClickReassign}
            />
          ) : (
            <Button
              text="Assign"
              typeButton="primary"
              fontSize={16}
              onClick={onClickAssign}
            />
          )}
        </>
      )}
    </>
  );
};

export const Bounder: React.FC<IBounder & Props> = ({
  id,
  first_name,
  last_name,
  phone_number,
  photo_url,
  onClickAssign,
  onClickReassign,
  driverId,
  index = 0,
  status,
  statusOrder,
  isOrderDetails,
}) => {
  const history = useHistory();

  return (
    <BounderContainer
      index={index || 0}
      style={{ cursor: isOrderDetails ? 'auto' : 'pointer' }}
      onClick={() => {
        if (!isOrderDetails) {
          history.push(`/bounders/${id}`);
        }
      }}
    >
      <StyledAvatar src={photo_url || Avatar2} alt="Avatar" />

      <div className="text-container">
        <h2>{`${first_name} ${last_name}`}</h2>
        <span>{phoneMask(phone_number)}</span>
      </div>

      {isOrderDetails && statusOrder && (
        <BounderDelivery
          isAssignToThisBounder={driverId === id}
          statusOrder={statusOrder}
          onClickAssign={() => {
            if (onClickAssign) {
              onClickAssign();
            }
          }}
          onClickReassign={() => {
            if (onClickReassign) {
              onClickReassign();
            }
          }}
        />
      )}

      {status === 'PENDING_COMPLETION' && !isOrderDetails && (
        <StatusBounder status={status}>Pending documents</StatusBounder>
      )}

      {status === 'PENDING_VERIFICATION' && !isOrderDetails && (
        <StatusBounder status={status}>Check the documents</StatusBounder>
      )}
    </BounderContainer>
  );
};
