import styled from 'styled-components';

export const StyledPromocode = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;
  animation: fadeIn 0.5s;

  .promocode-grid {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 50px;
  }

  header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    transition: 0.5s;
    animation: slideIn 0.35s forwards;
    flex-wrap: wrap;

    > a {
      font-size: 2.4rem;
      line-height: 20px;
      margin-left: 1rem;
    }

    div.title {
      display: flex;
      align-items: center;
      font-size: 3rem;
      min-width: 340px;
      margin-bottom: 0.5rem;

      a {
        color: ${({ theme }) => theme.grayDark};
        cursor: pointer;
        font-size: 2.8rem;
      }

      svg {
        margin: 0 1.4rem;
        width: 32px;
        height: 32px;
      }
    }
  }

  .container {
    margin-top: 8vh;
  }

  .grid {
    input {
      min-width: 50%;
      width: 220px;
      line-height: 30px;
      font-size: 1.5rem;
      padding: 2px 4px;
    }
  }

  footer {
    button.save {
      font-size: 1.7rem;
      padding: 0 12px;
      border-radius: 12px;
      line-height: 35px;
      min-width: 120px;
      border: none;
      outline: none;
      background: ${({ theme }) => theme.boundGreen};
      color: ${({ theme }) => theme.boundWhite};

      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: ${({ theme }) => theme.boundBlue};
      }
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: -65px;
  padding: 60px 0 5px 0;
  margin-bottom: 30px;
  z-index: 3;
  background-color: ${({ theme }) => theme.grayBackground};

  button {
    height: 40px;
    font-size: 14px;
  }

  .tabs {
    display: flex;
    width: 100%;
    gap: 24px;
    align-items: center;

    .selected {
      transition: color 0.4s ease-in, border-bottom 0.4s ease-in;
      color: ${({ theme }) => theme.boundGreen};
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.boundGreen};
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.grayMed};
      cursor: pointer;

      &:hover {
        transition: all 0.2s ease-in;
        transform: translateY(-5px);
      }
    }
  }

  .filters {
    margin: 0;
  }

  @media screen and (max-width: 560px) {
    .tabs {
      flex-wrap: wrap;
    }
  }
`;

export const EmptyMessage = styled.h1`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
`;
