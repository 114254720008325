import { useEffect, useState, useCallback } from 'react';
import { BellIconIMG } from 'assets';
import { observer } from 'mobx-react';
import { IUser } from 'models';
import { INotification } from 'models/INotification';
import { IToken } from 'models/IToken';
import { Link, useHistory } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

import { AuthService, NotificationsService } from 'services';
import { AuthStorage, NotificationsStorage } from 'storage';
import { StyledHeader } from 'styles/components/header';

interface Props {
  showBackArrow?: string | null;
}

export const Header = observer(({ showBackArrow = null }: Props) => {
  const [avatarIsOpen, setAvatarIsOpen] = useState(false);
  const [bellIsOpen, setBellIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationId, setNotificationId] = useState<null | number>(null);

  const history = useHistory();

  const getNotifications = useCallback(() => {
    NotificationsService.getAll()
      .then(response => {
        NotificationsStorage.updateNotifications(response.results);
        NotificationsStorage.next = !!response?.next;
        setIsLoading(false);
        setNotificationId(null);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handleSignOut = useCallback(() => {
    AuthService.signOut();
    AuthStorage.setUser({} as IUser);
    AuthStorage.setTokens({} as IToken);
    AuthStorage.ensureAuthenticated = false;
  }, []);

  const changeModals = useCallback(
    ({
      avatar = false,
      bell = false,
    }: {
      avatar?: boolean;
      bell?: boolean;
    }) => {
      setAvatarIsOpen(avatar);
      setBellIsOpen(bell);
    },
    [],
  );

  const notificationClick = useCallback(
    (notification: INotification) => {
      NotificationsService.markAsReadNotification([notification.id])
        .finally(() => {
          setTimeout(() => {
            getNotifications();
          }, 1200);
        })
        .catch(console.error);
    },
    [getNotifications],
  );

  return (
    <StyledHeader avatarIsOpen={avatarIsOpen} bellIsOpen={bellIsOpen}>
      <div className="left-container">
        {showBackArrow && (
          <div className="wrapper" onClick={() => history.push(showBackArrow)}>
            <FaArrowLeft color="black" size={16} />
            <span>back</span>
          </div>
        )}
      </div>
      <div className="right-container">
        <div className="bell">
          <BellIconIMG
            onClick={() => changeModals({ bell: !bellIsOpen })}
            data-tip="Notifications"
            data-place="bottom"
          />
          {/* {!!NotificationsStorage?.notifications?.filter(
          item => item.read_at === null,
        ).length && (
          <span
            className="length"
            onClick={() => changeModals({ bell: !bellIsOpen })}
          >
            {
              NotificationsStorage.notifications.filter(
                item => item.read_at === null,
              ).length
            }
          </span>
        )} */}
          <div className="bell-modal modal">
            <header>
              <span className="hello">Notifications</span>
              <Link to="/notifications">View all</Link>
            </header>
            <div className="list">
              <>
                {NotificationsStorage?.notifications?.length ? (
                  NotificationsStorage.notifications.map(notification => (
                    <div
                      className="notification"
                      key={notification.id}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIsLoading(true);
                        setNotificationId(notification.id);
                        notificationClick(notification);
                      }}
                    >
                      <span
                        className={`description ${
                          notification.read_at === null && 'new'
                        } ${
                          isLoading &&
                          notification.id === notificationId &&
                          'loading'
                        }`}
                      >
                        {notification.content}
                      </span>
                    </div>
                  ))
                ) : (
                  <span>No notifications</span>
                )}
              </>
            </div>
          </div>
        </div>

        <div className="overlay" onClick={() => changeModals({})} />

        <div className="avatarContainer">
          <div
            className="avatar"
            data-tip="Avatar"
            data-place="bottom"
            onClick={() => changeModals({ avatar: !avatarIsOpen })}
          >
            <p>b</p>
          </div>

          <div className="avatar-modal modal">
            <span className="hello">Hello, Bound 👋</span>
            <div className="logout" onClick={handleSignOut}>
              <span>Log out</span>
            </div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
});
