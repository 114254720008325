import { getEnumKey } from 'helpers/getEnumKey';
import {
  IOrder,
  IOrderStatus,
  IOrderStatusEnum,
  IOrderStatusText,
} from 'models';
import { StyledInfo } from 'styles/components/info';
import { ImageGridStyled, ImageStyled } from 'styles/pages/orderDetails';

interface Props {
  currentOrder: IOrder;
  handleSetImage: (img: string) => void;
}

type MessageType = 'pick' | 'drop';

function getOrderStatusKey(value: string) {
  return getEnumKey<IOrderStatus>(IOrderStatusText, value);
}

export const OrderDetailsImageGrid: React.FC<Props> = ({
  currentOrder,
  handleSetImage,
}: Props) => {
  const { pickup_photos = [], dropoff_photos = [], status } = currentOrder;

  const getMessage = (type: MessageType) => {
    if (status === getOrderStatusKey(IOrderStatusEnum.CANCELLED)) {
      return '-';
    }

    if (status === getOrderStatusKey(IOrderStatusEnum.COMPLETED)) {
      return 'The image should already be here';
    }
    return `The image will appear when the bounder ${
      type === 'pick' ? 'picks up' : 'drops off'
    } the order`;
  };

  return pickup_photos?.length <= 1 && dropoff_photos?.length <= 1 ? (
    <>
      <ImageGridStyled>
        <div>
          <h3 className="subtitle">Pick up</h3>
          {pickup_photos?.length > 0 ? (
            <ImageStyled
              src={pickup_photos[0]}
              alt="pickup-image"
              key={pickup_photos[0]}
              onClick={() => {
                handleSetImage(pickup_photos[0]);
              }}
            />
          ) : (
            <StyledInfo>
              <span>{getMessage('pick')}</span>
            </StyledInfo>
          )}
        </div>

        <div>
          <h3 className="subtitle">Drop off</h3>
          {dropoff_photos?.length > 0 ? (
            <ImageStyled
              src={dropoff_photos[0]}
              alt="dropoff-image"
              key={dropoff_photos[0]}
              onClick={() => handleSetImage(dropoff_photos[0])}
            />
          ) : (
            <StyledInfo>
              <span>{getMessage('drop')}</span>
            </StyledInfo>
          )}
        </div>
      </ImageGridStyled>
    </>
  ) : (
    <>
      <h3 className="subtitle">Pick up</h3>
      <ImageGridStyled>
        {pickup_photos ? (
          pickup_photos.map((item, index) => {
            return (
              <ImageStyled
                src={item}
                alt={`pickup-${index}`}
                key={item}
                onClick={() => handleSetImage(item)}
              />
            );
          })
        ) : (
          <StyledInfo>
            <span>{getMessage('pick')}</span>
          </StyledInfo>
        )}
      </ImageGridStyled>

      <h3 className="subtitle">Drop off</h3>
      <ImageGridStyled>
        {dropoff_photos ? (
          dropoff_photos.map((item, index) => {
            return (
              <ImageStyled
                src={item}
                alt={`dropoff-${index}`}
                key={item}
                onClick={() => handleSetImage(item)}
              />
            );
          })
        ) : (
          <StyledInfo>
            <span>{getMessage('drop')}</span>
          </StyledInfo>
        )}
      </ImageGridStyled>
    </>
  );
};
