import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { useParams, useHistory } from 'react-router-dom';
import { Layout, Loading, Modal, BounderDetail } from 'components';
import {
  AiOutlineClose,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import { BoundersService, ApplicantsService } from 'services';
import {
  IBounder,
  IApplicantDetail,
  IApplicantStatusEnum,
  IApplicantStatusType,
} from 'models';
import { getEnumKey } from 'helpers/getEnumKey';

import {
  BounderDetailsContainer,
  ModalImage,
} from 'styles/pages/bounderDetails';

interface IParamsBounder {
  id: string;
}

function getApplicantStatusTypeKey(value: string) {
  return getEnumKey<IApplicantStatusType>(IApplicantStatusEnum, value);
}

export const BounderDetails: React.FC = () => {
  const { id } = useParams() as IParamsBounder;
  const history = useHistory();
  const imgRef = useRef<HTMLDivElement>(null);
  const stateRef = useRef(0);

  const [bounder, setBounder] = useState<IBounder>();
  const [applicant, setApplicant] = useState<IApplicantDetail>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showModalVerify, setShowModalVerify] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDeclined, setShowModalDeclined] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [zoomProportion, setZoomProportion] = useState<number>(1);

  stateRef.current = zoomProportion;

  const isApplicantDriver = useMemo(() => {
    if (window.location.href.includes('/applicants/')) {
      return true;
    }
    return false;
  }, []);

  const getBounderDetail = useCallback(() => {
    if (!isApplicantDriver) {
      BoundersService.getBounderById(Number(id))
        .then(response => {
          setBounder(response);
        })
        .catch(() => {
          toast.error('Bounder information not Found');
          history.goBack();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ApplicantsService.getApplicantDetails(Number(id))
        .then(response => {
          setApplicant(response);
        })
        .catch(() => {
          toast.error('Bounder information not Found');
          history.goBack();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, isApplicantDriver, history]);

  const handleSetImage = (imageURL: string) => {
    setShowModal(true);
    setSelectedImage(imageURL);
  };

  useEffect(() => {
    getBounderDetail();
  }, [getBounderDetail]);

  const onWheelHandler = (event: WheelEvent) => {
    if (event.deltaY < 0 && stateRef.current < 2) {
      setZoomProportion(value => value * 2);
    }

    if (event.deltaY > 0 && stateRef.current !== 0.5) {
      setZoomProportion(value => value / 2);
    }
  };

  const eventHandlerListener = useCallback(() => {
    const node = imgRef.current;
    node?.addEventListener('wheel', onWheelHandler, {
      passive: false,
    });

    return () => {
      node?.removeEventListener('wheel', onWheelHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    eventHandlerListener();
  }, [eventHandlerListener]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const approveVerificationProcessHandler = useCallback(() => {
    if (applicant) {
      if (
        applicant.status ===
          getApplicantStatusTypeKey(
            IApplicantStatusEnum.DECLINED_VERIFICATION,
          ) ||
        applicant.status ===
          getApplicantStatusTypeKey(IApplicantStatusEnum.PENDING_VERIFICATION)
      ) {
        ApplicantsService.verifyApplicant(Number(id), applicant.phone_number)
          .then(() => {
            history.push(`/bounders`);
          })
          .catch(() => {
            toast.error('Error Verifying this Driver');
          });
      } else {
        ApplicantsService.approveApplicant(Number(id), applicant.phone_number)
          .then(() => {
            toast.success('Driver Applicant Accepted and awaiting Information');
            history.push('/applicants');
          })
          .catch(() => {
            toast.error('Error Verifying this Driver');
          });
      }
    }
  }, [history, id, applicant]);

  const rejectVerificationApplicant = useCallback(() => {
    if (applicant) {
      ApplicantsService.declinedVerifyApplicant(
        Number(id),
        applicant.phone_number,
      ).then(() => {
        history.push('/applicants');
      });
    }
  }, [history, applicant, id]);

  return (
    <>
      {showModal && (
        <ModalImage>
          <div className="modal">
            <div id="img-container" ref={imgRef}>
              <img
                src={selectedImage}
                alt="zoom"
                style={{
                  transform: `scale(${zoomProportion}) ${
                    zoomProportion > 1 ? `translate(15.25vw, 15.25vh)` : ``
                  }`,
                  margin:
                    zoomProportion > 1 ? `${zoomProportion * 50}px 0` : '0',
                }}
              />
            </div>

            <div className="download-close-btn">
              <a
                href={selectedImage}
                download
                className="download"
                data-tip="Donwload"
                data-place="bottom"
                target="_blank"
                rel="noreferrer"
              >
                <HiDownload size={30} />
              </a>

              <AiOutlineClose
                size={30}
                onClick={() => {
                  setShowModal(false);
                  setSelectedImage('');
                  setZoomProportion(1);
                }}
                className="close"
                data-tip="Close"
                data-place="bottom"
              />
            </div>

            <div className="slider-zoom">
              <div
                onClick={() => {
                  if (zoomProportion !== 0.5) {
                    setZoomProportion(value => value / 2);
                  }
                }}
                data-tip="Zoom Out"
                data-place="bottom"
              >
                <AiOutlineZoomOut size={30} />
              </div>

              <div
                onClick={() => {
                  if (zoomProportion < 2) {
                    setZoomProportion(value => value * 2);
                  }
                }}
                data-tip="Zoom In"
                data-place="bottom"
              >
                <AiOutlineZoomIn size={30} />
              </div>
            </div>
          </div>
        </ModalImage>
      )}

      {showModalVerify && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          modalTitle="Are you sure?"
          size="sm"
          modalBody="This driver will become a Bounder."
          onCancel={() => setShowModalVerify(false)}
          onConfirm={approveVerificationProcessHandler}
        />
      )}

      {showModalDeclined && (
        <Modal
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          modalTitle="Are you sure?"
          size="sm"
          modalBody="This driver will be declined."
          onCancel={() => setShowModalDeclined(false)}
          onConfirm={rejectVerificationApplicant}
        />
      )}

      <Layout showBackArrow={applicant ? '/applicants' : '/bounders'}>
        <BounderDetailsContainer>
          {loading ? (
            <Loading size="85" />
          ) : (
            <>
              {applicant && (
                <BounderDetail
                  dataInformation={applicant}
                  handleSetImage={(upload: string) => handleSetImage(upload)}
                  isApplicantDriver={isApplicantDriver}
                  loading={loading}
                  openModalDenied={() => setShowModalDeclined(true)}
                  openModalVerify={() => setShowModalVerify(true)}
                />
              )}
              {bounder && (
                <BounderDetail
                  dataInformation={bounder}
                  handleSetImage={(upload: string) => handleSetImage(upload)}
                  isApplicantDriver={isApplicantDriver}
                  loading={loading}
                  openModalDenied={() => setShowModalDeclined(true)}
                  openModalVerify={() => setShowModalVerify(true)}
                />
              )}
            </>
          )}
        </BounderDetailsContainer>
      </Layout>
    </>
  );
};
