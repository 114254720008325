import axios from 'config/axiosConfig';
import { toast } from 'react-toastify';
import { IApplicantResponse, IApplicantDetail } from 'models/IApplicant';
import { IApplicantService } from 'models/IApplicantService';

export class ApplicantsServiceClass implements IApplicantService {
  async getAll(
    queryString?: string,
    page?: string,
    status?: string,
  ): Promise<IApplicantResponse> {
    const applicants = (
      await axios.get(`/dashboard/drivers/applicants/`, {
        params: {
          search: queryString,
          page,
          status__in: status,
        },
      })
    ).data;

    if (applicants.results.length) {
      return Promise.resolve(applicants);
    }

    toast.error("There's no applicant data");
    return Promise.reject();
  }

  async getApplicantDetails(id: number): Promise<IApplicantDetail> {
    const applicantDetail = (
      await axios.get(`/dashboard/drivers/applicants/${id}/`)
    ).data;

    if (applicantDetail) {
      return Promise.resolve(applicantDetail);
    }

    return Promise.reject();
  }

  async approveApplicant(id: number, phone: string): Promise<void> {
    try {
      await axios.post(`/dashboard/drivers/applicants/${id}/approve/`, {
        phone_number: phone,
      });

      toast.success('Applicant approved');
    } catch (err) {
      toast.error('Error approving applicant');
    }
  }

  async rejectApplicant(id: number, phone: string): Promise<void> {
    try {
      await axios.post(`/dashboard/drivers/applicants/${id}/decline/`, {
        phone_number: phone,
      });

      toast.success('Applicant rejected');
    } catch (err) {
      toast.error('Error rejecting applicant');
    }
  }

  async verifyApplicant(id: number, phone: string): Promise<void> {
    try {
      await axios.post(`/dashboard/drivers/applicants/${id}/verify/`, {
        phone_number: phone,
      });

      toast.success('Applicant Verified');
    } catch (err) {
      toast.error('Error verifying applicant');
    }
  }

  async declinedVerifyApplicant(id: number, phone: string): Promise<void> {
    try {
      await axios.post(
        `/dashboard/drivers/applicants/${id}/decline-by-verification/`,
        {
          phone_number: phone,
        },
      );

      toast.success('Applicant Denied');
    } catch (err) {
      toast.error('Error denying applicant');
    }
  }
}

export const factoryApplicantsService = (): ApplicantsServiceClass =>
  new ApplicantsServiceClass();

export default factoryApplicantsService();
