import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import {
  Container,
  ErrorText,
  LabelText,
  PasswordIconEye,
} from 'styles/components/input';

interface InputInterface
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  errorText: string;
}

export const Input: React.FC<InputInterface> = ({ errorText, ...rest }) => {
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [seePasswordIcon, setSeePasswordIcon] = useState<boolean>(false);
  const [type, setType] = useState<string>(rest.type || 'text');

  const handlePasswordChange = () => {
    setSeePasswordIcon(value => !value);

    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <Container
      hasError={!!errorText.length}
      hasValue={!!rest.value?.toString().length}
    >
      {(!!rest.value?.toString().length || inputFocus) && (
        <LabelText hasError={!!errorText.length} htmlFor={rest.id}>
          {rest.placeholder}
        </LabelText>
      )}

      {rest.type === 'password' && (
        <PasswordIconEye>
          {seePasswordIcon ? (
            <AiOutlineEye size={25} onClick={handlePasswordChange} />
          ) : (
            <AiOutlineEyeInvisible size={25} onClick={handlePasswordChange} />
          )}
        </PasswordIconEye>
      )}

      <input
        {...rest}
        type={type}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        onChange={event => {
          const text = event.target.value;

          if (!text.length) {
            setInputFocus(false);
          }

          if (rest.onChange) rest?.onChange(event);
        }}
      />

      <ErrorText>{errorText}</ErrorText>
    </Container>
  );
};
