import { BrowserRouter } from 'react-router-dom';
import { AuthenticatedRoutes } from 'navigation/authenticated.routes';
import { UnauthenticatedRoutes } from 'navigation/unauthenticated.routes';
import { observer } from 'mobx-react';
import { AuthStorage } from 'storage';
import { useEffect } from 'react';

export const Navigation: React.FC = observer(() => {
  useEffect(() => {
    AuthStorage.firstLoad();
  }, []);

  return (
    <BrowserRouter>
      {AuthStorage.ensureAuthenticated ? (
        <AuthenticatedRoutes />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </BrowserRouter>
  );
});
