import styled from 'styled-components';

export const StyledLayout = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.grayBackground};
  display: flex;
  flex-direction: row;

  main {
    flex: 1;
    transition: 0.2s;
    z-index: 1;
  }
`;
