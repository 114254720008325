import styled from 'styled-components';

export const StyledOrders = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  padding: 6vh 6vw 0;
  overflow-y: auto;
  animation: fadeIn 0.5s;

  h1 {
    margin-bottom: 4rem;
  }

  > header {
    display: flex;
    position: sticky;
    top: -65px;
    background-color: ${({ theme }) => theme.grayBackground};
    padding-top: 28px;
    justify-content: space-between;
    width: 100%;
    z-index: 3;
    transform: scale(1.03);

    div.main-filters {
      flex-grow: 1;
      padding-left: 20px;

      div.filters {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-top: 8px;

        button {
          background: none;
          min-width: 60px;
          margin-bottom: 1rem;
          border: none;
          outline: none;
          font-size: 1.5rem;
          margin-right: 2rem;
          color: ${({ theme }) => theme.grayDark};
          opacity: 0.6;
          border-bottom: 1px solid transparent;
          padding: 4px 4px 6px;
          cursor: pointer;
          transition: 0.2s;

          &.actived,
          &:hover {
            color: ${({ theme }) => theme.boundGreen};
            border-bottom: 1px solid ${({ theme }) => theme.boundGreen};
            opacity: 1;
          }

          &:hover {
            transition: all 0.2s ease-in;
            transform: translateY(-5px);
          }
        }
      }
    }
  }

  div.orders {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .empty {
      display: flex;
      margin: 40px auto;
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 560px) {
    > header {
      flex-direction: column;
      gap: 20px;
      transform: scale(1.04);

      div.main-filters {
        div.filters {
          align-items: center;
          justify-content: center;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 20px 10px;

          > button {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
`;
