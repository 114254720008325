import React from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  IApplicant,
  IApplicantStatusEnum,
  IApplicantStatusType,
  IApplicantStatusBarType,
} from 'models/IApplicant';
import { Button } from 'components';

import {
  ApplicantContainer,
  TitleContainer,
  InfoGrid,
  VehicleInfoCard,
  StatusBar,
  VehicleInfoGrid,
} from 'styles/components/applicant';
import { StyledInfo } from 'styles/components/info';
import { getDateWithoutTzOffset } from 'helpers/convertDateWithoutTimezoneOffset';
import { getEnumKey } from 'helpers/getEnumKey';

interface IApplicantComponent extends IApplicant {
  onDenyButton: () => void;
  onApproveButton: () => void;
}

function getApplicantStatusKey(value: string) {
  return getEnumKey<IApplicantStatusType>(IApplicantStatusEnum, value);
}

function getApplicantStatusBarKey(value: string) {
  return getEnumKey<IApplicantStatusBarType>(IApplicantStatusEnum, value);
}

export const Applicant: React.FC<IApplicantComponent> = ({
  id,
  phone_number,
  email,
  date_of_birth,
  first_name,
  last_name,
  vehicles,
  zip_code,
  status,
  onApproveButton,
  onDenyButton,
}) => {
  const history = useHistory();

  const returnStatusField = () => {
    switch (status) {
      case getApplicantStatusKey(IApplicantStatusEnum.PENDING_APPROVAL):
        return (
          <StatusBar
            type={getApplicantStatusBarKey(
              IApplicantStatusEnum.PENDING_APPROVAL,
            )}
          >
            {IApplicantStatusEnum.PENDING_APPROVAL}
          </StatusBar>
        );

      case getApplicantStatusKey(IApplicantStatusEnum.PENDING_COMPLETION):
        return (
          <StatusBar
            type={getApplicantStatusBarKey(
              IApplicantStatusEnum.PENDING_COMPLETION,
            )}
          >
            {IApplicantStatusEnum.PENDING_COMPLETION}
          </StatusBar>
        );

      case getApplicantStatusKey(IApplicantStatusEnum.DECLINED):
        return (
          <StatusBar
            type={getApplicantStatusBarKey(IApplicantStatusEnum.DECLINED)}
          >
            {IApplicantStatusEnum.DECLINED}
          </StatusBar>
        );

      case getApplicantStatusKey(IApplicantStatusEnum.DECLINED_VERIFICATION):
        return (
          <StatusBar
            type={getApplicantStatusBarKey(
              IApplicantStatusEnum.DECLINED_VERIFICATION,
            )}
          >
            {IApplicantStatusEnum.DECLINED_VERIFICATION}
          </StatusBar>
        );

      default:
        return <></>;
    }
  };

  const viewDetailsHandler = () => {
    history.push({
      pathname: `/applicants/${id}/`,
      state: {
        id,
        phone_number,
        email,
      },
    });
  };

  return (
    <ApplicantContainer>
      <TitleContainer>
        <div className="title-applicant-container">
          <h1>{`${first_name} ${last_name}`}</h1>

          {returnStatusField()}
        </div>

        {status ===
          getApplicantStatusKey(IApplicantStatusEnum.PENDING_APPROVAL) && (
          <div className="buttons-container">
            <Button
              buttonSize="sm"
              text="Approve"
              typeButton="primary"
              onClick={onApproveButton}
            />

            <Button
              buttonSize="sm"
              text="Deny"
              typeButton="secondary"
              onClick={onDenyButton}
            />
          </div>
        )}

        {status ===
          getApplicantStatusKey(IApplicantStatusEnum.PENDING_VERIFICATION) && (
          <div className="buttons-container">
            <Button
              buttonSize="sm"
              text="View details"
              typeButton="secondary"
              onClick={viewDetailsHandler}
            />
          </div>
        )}

        {(status === getApplicantStatusKey(IApplicantStatusEnum.DECLINED) ||
          status ===
            getApplicantStatusKey(
              IApplicantStatusEnum.DECLINED_VERIFICATION,
            )) && (
          <div className="buttons-container">
            <Button
              buttonSize="sm"
              text="Review"
              typeButton="secondary"
              onClick={viewDetailsHandler}
            />
          </div>
        )}
      </TitleContainer>

      <InfoGrid>
        <StyledInfo>
          <h2>Birthday</h2>
          <span>
            {date_of_birth &&
              format(getDateWithoutTzOffset(date_of_birth), 'MM/dd/yyyy')}
          </span>
        </StyledInfo>

        <StyledInfo>
          <h2>Email</h2>
          <span>{email}</span>
        </StyledInfo>

        <StyledInfo>
          <h2>Phone number</h2>
          <span>{phone_number}</span>
        </StyledInfo>

        <StyledInfo>
          <h2>zip code</h2>
          <span>{zip_code}</span>
        </StyledInfo>
      </InfoGrid>

      <VehicleInfoCard>
        <VehicleInfoGrid>
          {vehicles && vehicles.length
            ? vehicles
                .sort((a, b) => {
                  if (a.is_primary === b.is_primary) {
                    return 0;
                  }

                  if (a.is_primary) {
                    return -1;
                  }

                  return 1;
                })
                .slice(0, 1)
                .map(vehicle => (
                  <React.Fragment key={vehicle.model}>
                    <StyledInfo>
                      <h2>Vehicle Color</h2>
                      <span>{vehicle.color}</span>
                    </StyledInfo>

                    <StyledInfo>
                      <h2>Vehicle Make</h2>
                      <span>{vehicle.make}</span>
                    </StyledInfo>

                    <StyledInfo key={vehicle.model}>
                      <h2>Vehicle Model</h2>
                      <span>{vehicle.model}</span>
                    </StyledInfo>

                    <StyledInfo>
                      <h2>Vehicle Year</h2>
                      <span>{vehicle.year}</span>
                    </StyledInfo>

                    <StyledInfo>
                      <h2>Vehicle Type</h2>
                      <span>{vehicle.vehicle_type}</span>
                    </StyledInfo>
                  </React.Fragment>
                ))
            : null}
        </VehicleInfoGrid>
      </VehicleInfoCard>
    </ApplicantContainer>
  );
};
