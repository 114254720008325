import React from 'react';
import { StyledLayout } from 'styles/components/layout';
import { Header, Sidebar } from 'components';

interface Props {
  children: React.ReactNode;
  showBackArrow?: string | null;
}

export const Layout: React.FC<Props> = ({
  children,
  showBackArrow = null,
}: Props) => {
  return (
    <StyledLayout>
      <Sidebar />
      <main>
        <Header showBackArrow={showBackArrow} />
        <section className="content">{children}</section>
      </main>
    </StyledLayout>
  );
};
