/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { AiOutlineHourglass, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiTaskX } from 'react-icons/bi';
import { Button } from 'components';
import {
  BoundersCardDetails,
  BoundersButtons,
  BounderProfileImage,
} from 'styles/pages/bounderDetails';
import {
  ChevronRightIconIMG,
  AutoInsurance,
  AutoRegistration,
  DriverLicense,
  Avatar2,
} from 'assets';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledInfo } from 'styles/components/info';
import {
  IBounder,
  IBounderStatusEnum,
  IBounderStatusType,
  IApplicantDetail,
} from 'models';
import { getDateWithoutTzOffset } from 'helpers/convertDateWithoutTimezoneOffset';
import { checkIfHasInformationOnText } from 'helpers';
import { getEnumKey } from 'helpers/getEnumKey';

interface IBounderDetails {
  isApplicantDriver: boolean;
  dataInformation: IBounder | IApplicantDetail;
  handleSetImage: (upload: string) => void;
  loading: boolean;
  openModalVerify: () => void;
  openModalDenied: () => void;
}

function getStatusTypeKey(value: string) {
  return getEnumKey<IBounderStatusType>(IBounderStatusEnum, value);
}

export const BounderDetail: React.FC<IBounderDetails> = ({
  dataInformation,
  isApplicantDriver,
  handleSetImage,
  loading,
  openModalDenied,
  openModalVerify,
}) => {
  const formattedSSN = useMemo(() => {
    const { ssn } = dataInformation;

    if (!ssn) return '-';

    return `${ssn.toString().slice(0, 3)}-${ssn.toString().slice(3, 5)}-${ssn
      .toString()
      .slice(5)}`;
  }, [dataInformation]);

  return (
    <>
      <div className="title">
        <div className="user-info">
          <Link to={isApplicantDriver ? '/applicants' : '/bounders'}>
            {isApplicantDriver ? 'Drivers' : 'Bounders'}
          </Link>
          <ChevronRightIconIMG />
          <PageTitle>
            {`${dataInformation?.first_name} ${dataInformation?.last_name}`}
          </PageTitle>
        </div>

        <div className="user-profile">
          <BounderProfileImage
            src={dataInformation?.photo_url || Avatar2}
            alt={dataInformation.first_name}
            clickable={dataInformation?.photo_url !== null}
            onClick={() =>
              dataInformation?.photo_url &&
              handleSetImage(dataInformation.photo_url)
            }
          />
        </div>
      </div>

      <BoundersCardDetails>
        {isApplicantDriver && (
          <>
            {dataInformation?.status ===
              getStatusTypeKey(IBounderStatusEnum.PENDING_VERIFICATION) && (
              <div className="status">
                <AiOutlineHourglass size={20} />
                <p>{IBounderStatusEnum.PENDING_VERIFICATION}</p>
              </div>
            )}

            {dataInformation?.status ===
              getStatusTypeKey(IBounderStatusEnum.DECLINED_VERIFICATION) && (
              <div className="status verify">
                <BiTaskX size={20} />
                <p>{IBounderStatusEnum.DECLINED_VERIFICATION}</p>
              </div>
            )}

            {dataInformation?.status ===
              getStatusTypeKey(IBounderStatusEnum.DECLINED) && (
              <div className="status denied">
                <AiOutlineCloseCircle size={20} />
                <p>{IBounderStatusEnum.DECLINED}</p>
              </div>
            )}
          </>
        )}

        <h1 className="title">Background check</h1>

        <StyledInfo className="social-security">
          <h2>Social Security Number</h2>
          <span>{formattedSSN}</span>
        </StyledInfo>

        <div className="divisor" />

        <h1 className="title">Contact information</h1>

        <div className="grid">
          <StyledInfo>
            <h2>Birthday</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation.date_of_birth &&
                  format(
                    getDateWithoutTzOffset(dataInformation.date_of_birth),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Email</h2>
            <span>{checkIfHasInformationOnText(dataInformation.email)}</span>
          </StyledInfo>

          <StyledInfo>
            <h2>Phone number</h2>
            <span>
              {checkIfHasInformationOnText(dataInformation.phone_number)}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Zip Code</h2>
            <span>{checkIfHasInformationOnText(dataInformation.zip_code)}</span>
          </StyledInfo>

          <StyledInfo>
            <h2>Vehicle Color</h2>
            <span>
              {checkIfHasInformationOnText(dataInformation.vehicles[0].color)}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Vehicle Make</h2>
            <span>
              {checkIfHasInformationOnText(dataInformation.vehicles[0].make)}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Vehicle Model</h2>
            <span>
              {checkIfHasInformationOnText(dataInformation.vehicles[0].model)}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Vehicle Year</h2>
            <span>
              {checkIfHasInformationOnText(dataInformation.vehicles[0].year)}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Vehicle Type</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation.vehicles[0].vehicle_type,
              )}
            </span>
          </StyledInfo>
        </div>

        <div className="divisor" />

        <h1 className="title">Driver’s license</h1>

        <div className="img-container">
          {dataInformation.driver_license &&
          dataInformation.driver_license.uploads.length ? (
            dataInformation.driver_license.uploads.map(upload => (
              <img
                src={upload}
                onClick={() => handleSetImage(upload)}
                alt="driver license"
                className="img-1"
                key={JSON.stringify({ upload })}
              />
            ))
          ) : (
            <img
              src={DriverLicense}
              onClick={() => handleSetImage(DriverLicense)}
              alt="driver license"
              className="img-1"
            />
          )}
        </div>

        <div className="grid">
          <StyledInfo>
            <h2>First name</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.first_name || '-',
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Last name</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.last_name,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Date of birth</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.date_of_birth &&
                  format(
                    getDateWithoutTzOffset(
                      dataInformation?.driver_license?.date_of_birth,
                    ),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Address</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.address,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>State issued</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.state_issued,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>License number</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.license_number,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Expiration date</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_license?.expiration_date &&
                  format(
                    getDateWithoutTzOffset(
                      dataInformation?.driver_license?.expiration_date,
                    ),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>
        </div>

        <div className="divisor" />

        <h1 className="title">Auto registration</h1>

        <div className="img-container">
          {dataInformation.driver_registration &&
          dataInformation.driver_registration.uploads.length ? (
            dataInformation.driver_registration.uploads.map(upload => (
              <img
                src={upload}
                alt="auto registration"
                onClick={() => handleSetImage(upload)}
                key={JSON.stringify({ upload })}
                className="img-1"
              />
            ))
          ) : (
            <img
              src={AutoRegistration}
              onClick={() => handleSetImage(AutoRegistration)}
              alt="driver license"
              className="img-1"
            />
          )}
        </div>

        <div className="grid">
          <StyledInfo>
            <h2>Registered owner</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.registered_owner,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Registered address</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.registered_address,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Effective date</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.effective_date &&
                  format(
                    getDateWithoutTzOffset(
                      dataInformation?.driver_registration?.effective_date,
                    ),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Expiration date</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.expiration_date &&
                  format(
                    getDateWithoutTzOffset(
                      dataInformation?.driver_registration?.expiration_date,
                    ),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Make</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.make,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Model</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.model,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Year</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_registration?.year,
              )}
            </span>
          </StyledInfo>
        </div>

        <div className="divisor" />

        <h1 className="title">Auto insurance</h1>

        <div className="img-container">
          {dataInformation.driver_insurance &&
          dataInformation.driver_insurance.uploads.length ? (
            dataInformation.driver_insurance.uploads.map(upload => (
              <img
                src={upload}
                alt="auto insurance"
                onClick={() => handleSetImage(upload)}
                key={JSON.stringify({ upload })}
                className="img-1"
              />
            ))
          ) : (
            <img
              src={AutoInsurance}
              onClick={() => handleSetImage(AutoInsurance)}
              alt="driver license"
              className="img-1"
            />
          )}
        </div>

        <div className="grid">
          <StyledInfo>
            <h2>Insured named</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.named_insured,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Policy number</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.policy_number,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Effective date</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.effective_date &&
                  format(
                    getDateWithoutTzOffset(
                      dataInformation?.driver_insurance?.effective_date,
                    ),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Expiration date</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.expiration_date &&
                  format(
                    getDateWithoutTzOffset(
                      dataInformation?.driver_insurance?.expiration_date,
                    ),
                    'MM/dd/yyyy',
                  ),
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Make</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.make,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Model</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.model,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>Year</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.year,
              )}
            </span>
          </StyledInfo>

          <StyledInfo>
            <h2>VIN</h2>
            <span>
              {checkIfHasInformationOnText(
                dataInformation?.driver_insurance?.vin,
              )}
            </span>
          </StyledInfo>
        </div>

        <div className="divisor" />

        {isApplicantDriver && (
          <BoundersButtons>
            <Button
              buttonSize="md"
              disabled={loading}
              text="Approve"
              typeButton="primary"
              onClick={openModalVerify}
            />

            {dataInformation?.status ===
              getStatusTypeKey(IBounderStatusEnum.PENDING_VERIFICATION) && (
              <Button
                buttonSize="md"
                text="Deny"
                typeButton="secondary"
                onClick={openModalDenied}
              />
            )}
          </BoundersButtons>
        )}
      </BoundersCardDetails>
    </>
  );
};
