import { useEffect, useCallback } from 'react';
import { AuthService } from 'services';
import {
  Orders,
  OrderDetails,
  DriverApplicants,
  ClientsApplicants,
  Bounders,
  BounderDetails,
  Account,
  EditAccount,
  Notifications,
  BatchOrders,
  BatchOrderDetails,
  BatchOrdersList,
} from 'pages';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Promocodes } from 'pages/Promocodes';

export const AuthenticatedRoutes: React.FC = () => {
  const checkIfUserIsLoggedIn = useCallback(() => {
    const tokensFromStorage = JSON.parse(
      localStorage.getItem('@Bound-Tokens') as string,
    );

    if (tokensFromStorage.tokens.refresh) {
      AuthService.refreshToken(tokensFromStorage.tokens.refresh);
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      checkIfUserIsLoggedIn();
    }, 1.2e6);
  }, [checkIfUserIsLoggedIn]);

  return (
    <Switch>
      <Route path="/orders" exact component={Orders} />
      <Route path="/orders/:id" exact component={OrderDetails} />
      <Route path="/batch-orders" exact component={BatchOrders} />
      <Route path="/batch-orders/:id" exact component={BatchOrdersList} />
      <Route
        path="/batch-orders/:batchOrderId/order/:id"
        exact
        component={BatchOrderDetails}
      />
      <Route path="/bounders" exact component={Bounders} />
      <Route path="/bounders/:id" exact component={BounderDetails} />
      <Route path="/applicants" exact component={DriverApplicants} />
      <Route path="/applicants/:id" exact component={BounderDetails} />
      <Route path="/clients" exact component={ClientsApplicants} />
      <Route path="/account" exact component={Account} />
      <Route path="/edit-account" exact component={EditAccount} />
      <Route path="/notifications" exact component={Notifications} />
      <Route path="/promocodes" exact component={Promocodes} />
      <Redirect to="/orders" />
    </Switch>
  );
};
