import React, { useState, useEffect, useCallback } from 'react';
import { BsFilter } from 'react-icons/bs';
import { Button } from 'components';
import { CheckboxSelectIMG, CheckboxUnselectIMG } from 'assets';

import { FiltersContainer } from 'styles/components/filters';

interface IFilters {
  onChange: (key: string) => void;
  filters: Array<Array<string | boolean>>;
}

export const Filters: React.FC<IFilters> = ({ filters, onChange }) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const handleOutsideClick = useCallback(() => {
    window.addEventListener('click', e => {
      if (e.target !== document.getElementById('filter-container')) {
        setShowFilter(false);
      }
    });

    return () => window.removeEventListener('click', () => {});
  }, []);

  useEffect(() => {
    handleOutsideClick();
  }, [handleOutsideClick]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setShowFilter(value => !value);
  };

  return (
    <FiltersContainer
      isOpen={showFilter}
      className="filters"
      onClick={handleClick}
    >
      <Button
        id="filter-container"
        buttonSize="sm"
        text="Filters"
        typeButton="primary"
      >
        <BsFilter size={18} />
      </Button>

      {showFilter && (
        <div className="filter-dropdown">
          {filters.map(key => (
            <div className="checkbox" key={key[0].toString()}>
              <div>
                {key[1] ? (
                  <CheckboxSelectIMG
                    onClick={() => onChange(key[0].toString())}
                  />
                ) : (
                  <CheckboxUnselectIMG
                    onClick={() => onChange(key[0].toString())}
                  />
                )}
              </div>

              <span onClick={() => onChange(key[0].toString())}>{key[0]}</span>
            </div>
          ))}
        </div>
      )}
    </FiltersContainer>
  );
};
