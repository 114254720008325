import styled from 'styled-components';

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.boundBlack};
  font-size: 40px;
  font-weight: 400;
  display: inline-flex;

  @media screen and (max-width: 560px) {
    font-size: 26px;
  }
`;
