import React, { useState } from 'react';

import {
  Container,
  ErrorText,
  LabelText,
} from 'styles/components/promocodeTextarea';

interface TextAreaInterface
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  errorText: string;
}

export const PromocodeTextarea: React.FC<TextAreaInterface> = ({
  errorText,
  ...rest
}) => {
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  return (
    <Container
      hasError={!!errorText.length}
      hasValue={!!rest.value?.toString().length}
    >
      {(!!rest.value?.toString().length || inputFocus) && (
        <LabelText hasError={!!errorText.length} htmlFor={rest.id}>
          {rest.placeholder}
        </LabelText>
      )}

      <textarea
        {...rest}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        onChange={event => {
          const text = event.target.value;

          if (!text.length) {
            setInputFocus(false);
          }

          if (rest.onChange) rest?.onChange(event);
        }}
      />

      <ErrorText>{errorText}</ErrorText>
    </Container>
  );
};
