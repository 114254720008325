export interface IApplicant {
  id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  date_of_birth: string;
  zip_code: string;
  status: IApplicantStatusType;
  vehicles: Array<{
    model: string;
    vehicle_type: string;
    year: number;
    color: string;
    is_primary: boolean;
    make: string;
  }>;
}

export interface IApplicantDetail {
  id: number;
  status: IApplicantStatusType;
  zip_code: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  phone_number: string;
  photo_url?: string;
  ssn?: string;
  email: string;
  vehicles: Array<{
    model: string;
    vehicle_type: string;
    year: number;
    color: string;
    is_primary: boolean;
    make: string;
  }>;
  driver_license: {
    state_issued: string;
    license_number: string;
    expiration_date: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    address: string;
    uploads: Array<string>;
  };
  driver_registration: {
    make: string;
    model: string;
    year: number;
    effective_date: string;
    expiration_date: string;
    registered_owner: string;
    registered_address: string;
    uploads: Array<string>;
  };
  driver_insurance: {
    policy_number: string;
    named_insured: string;
    effective_date: string;
    expiration_date: string;
    vin: string;
    make: string;
    model: string;
    year: number;
    uploads: Array<string>;
  };
}

export enum IApplicantStatusEnum {
  PENDING_APPROVAL = 'Pending approval',
  DECLINED = 'Denied application',
  PENDING_COMPLETION = 'Pending information',
  PENDING_VERIFICATION = 'Pending verification',
  DECLINED_VERIFICATION = 'Denied verification',
  ACTIVE = 'Active',
}

export type IApplicantStatusType = keyof typeof IApplicantStatusEnum;

export type IApplicantStatusBarType = Exclude<
  IApplicantStatusType,
  'PENDING_VERIFICATION' | 'ACTIVE'
>;

export interface IApplicantResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IApplicant[];
}
