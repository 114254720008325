import axios from 'axios';
import axiosConfig from 'config/axiosConfig';
import { toast } from 'react-toastify';
import { IAuthService, ISignInResponse } from 'models';

// const API_URL = 'https://api.stg.bounddelivery.com/auth/login';
// const API_URL = 'https://api.bounddelivery.com/auth/login';
const API_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export class AuthServiceClass implements IAuthService {
  async signIn(email: string, password: string): Promise<ISignInResponse> {
    const user = (
      await axios.post(API_URL, {
        email,
        password,
      })
    ).data;

    if (user) {
      return Promise.resolve({
        user: {
          // remove once backend return user data
          id: '1',
          email: 'john@doe.com',
          name: 'John Doe',
          last_name: 'Doe',
          phone_number: '111111111',
        },
        tokens: {
          access: user.access,
          refresh: user.refresh,
        },
      });
    }

    toast.error('User not found');
    throw new Error('User not found');
  }

  async refreshToken(refreshToken: string): Promise<void> {
    const tokens = (
      await axiosConfig.post('/auth/refresh', {
        refresh: refreshToken,
      })
    ).data;

    if (tokens) {
      localStorage.setItem('@Bound-Tokens', JSON.stringify({ tokens }));
    }

    return tokens ? Promise.resolve() : Promise.reject();
  }

  async signOut(): Promise<void> {
    return Promise.resolve();
  }
}

export const factoryAuthService = (): AuthServiceClass =>
  new AuthServiceClass();

export default factoryAuthService();
