import React, { useEffect, useState, useCallback } from 'react';
import { Layout, BatchOrder, Pagination, Loading } from 'components';
import { observer } from 'mobx-react';
import { IBatchOrderResponse } from 'models';
import { OrdersService } from 'services';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledOrders } from 'styles/pages/orders';

export const BatchOrders: React.FC = observer(() => {
  const [page, setPage] = useState('1');
  const [loading, setLoading] = useState<boolean>(false);
  const [batchOrders, setBatchOrders] = useState<IBatchOrderResponse>();

  const getOrdersService = useCallback((page?: string) => {
    setLoading(true);

    OrdersService.getAllBatches(page)
      .then(orders => {
        setBatchOrders(orders);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getOrdersService('1');
    setPage('1');
  }, [getOrdersService]);

  return (
    <Layout>
      <StyledOrders id="scroll-element">
        <PageTitle>Batches</PageTitle>

        <div className="orders">
          {loading && !batchOrders ? <Loading size="85" /> : null}

          {batchOrders &&
          batchOrders?.results &&
          batchOrders?.results.length ? (
            <>
              {batchOrders?.results.map((order, index) => (
                <BatchOrder key={order.id} order={order} index={index} />
              ))}

              <Pagination
                elementsPerPage={20}
                onChangePage={page => {
                  getOrdersService(page);
                  setPage(page);
                }}
                page={Number(page) || 1}
                totalElements={batchOrders?.count}
              />
            </>
          ) : null}

          {(!batchOrders ||
            batchOrders?.results.length === 0 ||
            batchOrders?.count === 0) &&
            !loading && <div className="empty">No orders found</div>}
        </div>
      </StyledOrders>
    </Layout>
  );
});
