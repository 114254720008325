import styled from 'styled-components';

interface IButton {
  typeButton: 'primary' | 'secondary' | 'outline' | 'danger';
  buttonSize?: 'sm' | 'md' | 'bg';
  fontSize?: number;
}

const isMacOs = navigator.appVersion.toLowerCase().includes('mac os');

export const ButtonContainer = styled.div<IButton>`
  width: 100%;

  button {
    padding-top: ${isMacOs ? 4 : 0}px;
    background-color: ${({ theme, typeButton }) => {
      switch (typeButton) {
        case 'primary':
          return theme.boundGreen;
        case 'secondary':
          return theme.boundWhite;
        default:
          return 'transparent';
      }
    }};
    transition: 0.2s;
    font-size: ${({ fontSize }) => fontSize || 20}px;
    color: ${({ theme, typeButton }) => {
      switch (typeButton) {
        case 'primary':
          return theme.boundWhite;
        case 'danger':
          return theme.boundRed;
        default:
          return theme.boundGreen;
      }
    }};
    width: ${({ buttonSize }) => {
      switch (buttonSize) {
        case 'sm':
          return '156px';

        case 'md':
          return '272px';

        case 'bg':
          return '343px';

        default:
          return '100%';
      }
    }};

    border: 1px solid
      ${({ theme, typeButton }) =>
        typeButton === 'danger' ? theme.boundRed : theme.boundGreen};
    height: 50px;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 0px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04);

    &:hover {
      filter: ${({ typeButton }) =>
        typeButton === 'primary' ? 'brightness(1.1)' : 'none'};
      border: ${({ typeButton, theme }) =>
        typeButton === 'secondary' ? `2px solid ${theme.boundGreen}` : ''};
      background-color: ${({ typeButton, theme }) => {
        switch (typeButton) {
          case 'outline':
            return theme.boundGreen;
          case 'danger':
            return theme.boundRed;
          default:
            return '';
        }
      }};
      color: ${({ typeButton, theme }) => {
        switch (typeButton) {
          case 'outline':
          case 'danger':
            return theme.boundWhite;
          default:
            return '';
        }
      }};
    }
    &:disabled {
      background-color: #ccc;
      border-color: #ccc;
    }
  }
`;
