import styled, { css } from 'styled-components';

interface IModal {
  size: 'sm' | 'bg';
}

export const BackgroundBlurWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeOut 0.55s;
`;

export const ModalWrapper = styled.div<IModal>`
  ${({ size, theme }) => css`
    background-color: ${theme.boundWhite};
    width: ${size === 'bg' ? '600px' : '400px'};
    height: ${size === 'bg' ? '350px' : '224px'};
  `}

  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 32px;
  animation: fadeIn 0.55s;
`;

export const ModalTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.boundBlue};
  margin-bottom: 8px;
`;

export const ModalBody = styled.p`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export const ModalButtonsComponent = styled.div`
  display: flex;
  margin-top: auto;
  align-items: center;

  svg {
    animation: spin 0.8s linear infinite;
    color: ${({ theme }) => theme.boundGreen};
    margin: auto;
  }

  button {
    font-size: 14px;
    height: 40px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
