import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Layout, Order, Pagination, Loading, Filters } from 'components';
import { observer } from 'mobx-react';
import {
  IOrderStatus,
  IOrderStatusEnum,
  IOrderResponse,
  IServiceType,
  IServiceTypeEnum,
  IOrderServiceType,
} from 'models';
import { OrdersService } from 'services';
import { PageTitle } from 'styles/components/pageTitle';
import { StyledOrders } from 'styles/pages/orders';
import { scrollToTop } from 'helpers';
import { getEnumKey } from 'helpers/getEnumKey';

function getOrderStatusKey(value: string) {
  return getEnumKey<IOrderStatus>(IOrderStatusEnum, value);
}

function getServiceStatusKey(value: string) {
  return getEnumKey<IOrderServiceType>(IServiceTypeEnum, value);
}

export const Orders: React.FC = observer(() => {
  const [currentType, setCurrentType] = useState<IOrderStatus | undefined>(
    undefined,
  );
  const [currentServiceType, setCurrentServiceType] = useState<
    string | undefined
  >(undefined);
  const [page, setPage] = useState('1');
  const [orders, setOrders] = useState<IOrderResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);

  const serviceTypeValues = useMemo(
    () => [
      'All',
      ...Object.values(IOrderStatusEnum).filter(item => {
        return (
          // item !== IOrderStatusEnum.ASSIGNED_BY_ADMIN &&
          item !== IOrderStatusEnum.UNASSIGNED
        );
      }),
    ],
    [],
  );

  const serviceTypeFilters: { text: string; value?: IOrderStatus }[] = useMemo(
    () =>
      serviceTypeValues.map(item => {
        return {
          value: getOrderStatusKey(item),
          text: item,
        };
      }),
    [serviceTypeValues],
  );

  const handleCorrectServiceType = useCallback(
    (orders: IOrderResponse): IOrderResponse => {
      const response: IOrderResponse = {
        results: orders?.results.filter(
          order => order.service_type !== IServiceTypeEnum.NEED,
        ),
        count: orders?.count,
        next: orders?.next,
        previous: orders?.previous,
      };

      return response;
    },
    [],
  );

  const getOrdersService = useCallback(
    (status?: IOrderStatus, type?: string, page?: string) => {
      setLoading(true);

      // Creates a string with all items from IOrderStatusEnum
      const orderStatusStr = Object.keys(IOrderStatusEnum).join(', ');

      if (status === undefined) {
        OrdersService.getAll(orderStatusStr, type, page)
          .then(orders => {
            setOrders(handleCorrectServiceType(orders));
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      if (status === getOrderStatusKey(IOrderStatusEnum.ACCEPTED_BY_DRIVER)) {
        // Creates a string with Assigned and Assigned by Admin items from IOrderStatusEnum
        const orderStatusStr = `${getOrderStatusKey(
          IOrderStatusEnum.ACCEPTED_BY_DRIVER,
        )}`;
        // const orderStatusStr = `${getOrderStatusKey(
        //   IOrderStatusEnum.ACCEPTED_BY_DRIVER,
        // )},${getOrderStatusKey(IOrderStatusEnum.COMPLETED)}`;

        OrdersService.getAll(orderStatusStr, type, page)
          .then(orders => {
            setOrders(handleCorrectServiceType(orders));
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      if (status === getOrderStatusKey(IOrderStatusEnum.ASSIGNED_BY_ADMIN)) {
        // Creates a string with Assigned and Assigned by Admin items from IOrderStatusEnum
        const orderStatusStr = `${getOrderStatusKey(
          IOrderStatusEnum.ASSIGNED_BY_ADMIN,
        )}`;
        // const orderStatusStr = `${getOrderStatusKey(
        //   IOrderStatusEnum.CANCELLED,
        // )},${getOrderStatusKey(IOrderStatusEnum.ASSIGNED_BY_ADMIN)}`;

        OrdersService.getAll(orderStatusStr, type, page)
          .then(orders => {
            setOrders(handleCorrectServiceType(orders));
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      if (status === getOrderStatusKey(IOrderStatusEnum.READY_FOR_PICKUP)) {
        // Creates a string with Unassigned and Unassigned by Admin items from IOrderStatusEnum
        const orderStatusStr = `${getOrderStatusKey(
          IOrderStatusEnum.READY_FOR_PICKUP,
        )}`;

        OrdersService.getAll(orderStatusStr, type, page)
          .then(orders => {
            setOrders(handleCorrectServiceType(orders));
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      OrdersService.getAll(status, type, page)
        .then(orders => {
          setOrders(handleCorrectServiceType(orders));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [handleCorrectServiceType],
  );

  useEffect(() => {
    getOrdersService(currentType, currentServiceType, '1');
    setPage('1');
  }, [getOrdersService, currentServiceType, currentType]);

  useEffect(() => {
    OrdersService.getServiceTypes()
      .then(({ results }) => setServiceTypes(results))
      .catch(console.error);
  }, []);

  const returnServiceTypeArray = useMemo((): (string | boolean)[][] => {
    const array: (string | boolean)[][] = [];

    serviceTypes.forEach(service => {
      const key = service.name.toUpperCase();
      const name = IServiceTypeEnum[key as IOrderServiceType];

      const newElement = [name, service.id === currentServiceType];
      array.push(newElement);
    });

    return array;
  }, [serviceTypes, currentServiceType]);

  const handleFilterChange = useCallback(
    (name: string) => {
      const key = getServiceStatusKey(name);

      const type = serviceTypes.find(
        service => service.name.toUpperCase() === key,
      );

      return type?.id === currentServiceType
        ? setCurrentServiceType(undefined)
        : type && setCurrentServiceType(type.id);
    },
    [serviceTypes, currentServiceType],
  );

  return (
    <Layout>
      <StyledOrders id="scroll-element">
        <PageTitle>Orders</PageTitle>

        <header>
          <div className="main-filters">
            <div className="filters">
              {serviceTypeFilters.map(item => (
                <button
                  type="button"
                  className={currentType === item.value ? 'actived' : ''}
                  onClick={() => {
                    setCurrentType(item.value);
                    scrollToTop('scroll-element');
                  }}
                  data-testid="filter"
                  key={item.text}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
          <div>
            <Filters
              filters={returnServiceTypeArray}
              onChange={handleFilterChange}
            />
          </div>
        </header>

        <div className="orders">
          {loading && !orders ? <Loading size="85" /> : null}

          {orders && orders?.results && orders?.results.length ? (
            <>
              {orders?.results.map((order, index) => (
                <Order key={order.id} order={order} index={index} />
              ))}

              <Pagination
                elementsPerPage={20}
                onChangePage={page => {
                  getOrdersService(currentType, currentServiceType, page);
                  setPage(page);
                }}
                page={Number(page) || 1}
                totalElements={orders?.count}
              />
            </>
          ) : null}

          {(!orders || orders?.results.length === 0 || orders?.count === 0) &&
            !loading && <div className="empty">No orders found</div>}
        </div>
      </StyledOrders>
    </Layout>
  );
});
