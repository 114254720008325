import styled from 'styled-components';

export const DefaultWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.boundBlack};
`;

export const SubTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.boundBlack};
`;
