import axios from 'config/axiosConfig';

import { INotificationsService } from 'models/INotificationsService';
import { INotificationResponse } from 'models/INotification';

export class NotificationsServiceClass implements INotificationsService {
  async getAll(data?: { page?: number }): Promise<INotificationResponse> {
    const notifications = (
      await axios.get('/dashboard/notifications/', {
        params: {
          page: data?.page,
        },
      })
    ).data;

    return Promise.resolve(notifications);
  }

  async getById(notificationId: string): Promise<INotificationResponse> {
    const notification = (
      await axios.get(`/dashboard/notifications/${notificationId}/`)
    ).data;

    return Promise.resolve(notification);
  }

  async markAsReadNotification(ids: Array<number>): Promise<void> {
    try {
      axios.post<void>('/dashboard/notifications/mark_as_read/', {
        ids,
      });
    } catch (err) {
      console.error(err);
    }
  }
}

export const factoryNotificationsService = (): NotificationsServiceClass =>
  new NotificationsServiceClass();

export default factoryNotificationsService();
