import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;

    font-family: 'ITC Avant Garde Gothic Std', sans-serif;
    z-index: 1;
  }

  body, html, #root {
    min-height: 100vh;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .Toastify__toast-container {
    font-size: 45px;
}

  @keyframes fadeIn {
    from{
      opacity: 0.1;
    }
    to{
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      position: relative;
      left: -50px;
      opacity: 0;
    }
    to {
      position: relative;
      left: 0;
    }
  }

  @keyframes popUp {
    from {
      opacity: 0;
      transform: scale(0.2);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.7;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0.95);
      opacity: 0.7;
    }
  }

  @keyframes slideToLeft {
    from {
      opacity: 0;
      margin-left: -5000px;
    }

    to {
      opacity: 1;
      margin-left: 0;
    }
  }
`;
